/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useMemo, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// @mui material components
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import RuleIcon from '@mui/icons-material/Rule';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// redux
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setActionView,
  setPagination,
  setSelectPerPage,
  setSearch,
  setStatus,
  setShowDelete,
  setSelectRow,
  getListPage,
  getPagination,
  getSelectPerPage,
  getListPerPage,
  getSearch,
  getStatus,
  getShowDelete,
  setContentMessageBox,
  setShowMessageBox,
  getOpenDialog,
  setOpenDialog,
  setRoleListPage,
  getRoleListPage,
  setRolePagination,
  getRolePagination,
  getRoleSelectPerPage,
  getRoleSearch,
  setRoleSearch,
  getRoleStatus,
  setRoleStatus,
  getRoleShowDelete,
  setRoleShowDelete,
  setUserListPage,
  setUserPagination,
  getUserPagination,
  getUserSelectPerPage,
  getUserSearch,
  getUserStatus,
  getUserShowDelete,
  getRoleListPerPage,
  setRoleSelectPerPage,
  getRoleSelectRow,
} from "states/features/privileges/privilegesSlice";
import {
  useGetByIdQuery,
  useGetQuery,
  useGetPageQuery,
  useCreateMutation,
  useUpdateMutation,
  useEditMutation,
  useDestroyMutation,
  useRestoreMutation,
} from "states/services/privileges";

// MY 
import PrivilegesListRole from "pages/security/authorization/privileges/components/PrivilegesList/components/RoleTabManager/PrivilegesListRole";
import PrivilegesManagementUser from "pages/security/authorization/privileges/components/PrivilegesList/components/RoleTabManager/PrivilegesManagementUser";

function RoleTabManager({ queryPrivilegesPageList, queryPrivilegesRolePageList, queryPrivilegesUserPageList, queryPrivilegesByRoleList, queryPrivilegesByUserList }) {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Grid container spacing={3} >
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
        <MDBox py={3}>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="success"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  <RuleIcon />
                </Icon>
              </MDBox>
              <MDBox>
                <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                  {t('pages.role.list_title')}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox p={2} >
              <PrivilegesListRole
                queryPrivilegesPageList={queryPrivilegesPageList}
                queryPrivilegesRolePageList={queryPrivilegesRolePageList}
                queryPrivilegesUserPageList={queryPrivilegesUserPageList}
                queryPrivilegesByRoleList={queryPrivilegesByRoleList}
                queryPrivilegesByUserList={queryPrivilegesByUserList}
              />

            </MDBox>
          </Card>
        </MDBox>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
        <MDBox py={3}>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="primary"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  <MilitaryTechIcon />
                </Icon>
              </MDBox>
              <MDBox>
                <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                  {t('pages.privileges.list_title')}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox p={2} >
              <PrivilegesManagementUser
                queryPrivilegesPageList={queryPrivilegesPageList}
                queryPrivilegesRolePageList={queryPrivilegesRolePageList}
                queryPrivilegesUserPageList={queryPrivilegesUserPageList}
                queryPrivilegesByRoleList={queryPrivilegesByRoleList}
                queryPrivilegesByUserList={queryPrivilegesByUserList}
              />
            </MDBox>
          </Card>
        </MDBox>
      </Grid>
    </Grid>
  )
}

export default RoleTabManager;
