
import { useState, useMemo, useEffect, useRef } from "react";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useTranslation } from "react-i18next";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setShowMessageBox,
  setListPage,
  setActionView,
  setPagination,
  getOpenDialogInfo,
  setOpenDialogInfo,
  getOpenDialogCreate,
  setOpenDialogCreate,
  getOpenDialogEdit,
  setOpenDialogEdit,
  getActionView,
  getPagination,
  getSelectPerPage,
  getCode,
  getSearch,
  getStatus,
  getShowDelete,
  getShowMessageBox,
  getContentMessageBox,
} from "states/features/system/systemSlice";

import {
  useGetQuery,
  useGetPageQuery,
} from "states/services/system";

// MY 
import MessageNotification from "components/HRComponents/MessageNotification";

import SystemList from "pages/security/resource/system/components/SystemList";
import SystemInfo from "pages/security/resource/system/components/SystemInfo";
import SystemCreate from "pages/security/resource/system/components/SystemCreate";
import SystemEdit from "pages/security/resource/system/components/SystemEdit";
import SystemEditModal from "pages/security/resource/system/components/SystemEditModal";
import SystemCreateModal from "pages/security/resource/system/components/SystemCreateModal";

function System() {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const actionView = useSelector(getActionView);
  const showMessageBox = useSelector(getShowMessageBox);
  const contentMessageBox = useSelector(getContentMessageBox);
  const search = useSelector(getSearch);
  const code = useSelector(getCode);
  const status = useSelector(getStatus);
  const showDelete = useSelector(getShowDelete);
  const pagination = useSelector(getPagination);
  const selectPerPage = useSelector(getSelectPerPage);

  const createModalElementRef = useRef(null);
  const editModalElementRef = useRef(null);

  const querySystemPageList = useGetPageQuery({
    isActive: status === null ? undefined : status,
    hiddenDelete: showDelete ? false : undefined,
    textSearch: search,
    code: code === '' ? undefined : code,
    page: pagination?.currentPage || 1,
    size: selectPerPage || 50,
  }
    // , {
    //   refetchOnMountOrArgChange: true,
    //   //pollingInterval: 3000
    // }
  );

  useEffect(() => {
    if (querySystemPageList.isSuccess) {
      reduxDispatch(setListPage(querySystemPageList?.data?.result))
      reduxDispatch(setPagination({
        totalItems: querySystemPageList?.data?.result?.totalItems,
        totalPages: querySystemPageList?.data?.result?.totalPages
      }))
    }
  }, [querySystemPageList.data?.result, querySystemPageList.isSuccess, reduxDispatch]);


  useEffect(() => {
    return () => {
      reduxDispatch(setOpenDialogInfo(false));
      reduxDispatch(setOpenDialogCreate(false));
      reduxDispatch(setOpenDialogEdit(false));
      reduxDispatch(setShowMessageBox(false))
      reduxDispatch(setActionView("LIST"))
    };
  }, [reduxDispatch]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {(actionView === 'LIST') && <SystemList querySystemPageList={querySystemPageList}  />}
      {(actionView === 'CREATE') && <SystemCreate querySystemPageList={querySystemPageList}  />}
      {(actionView === 'EDIT') && <SystemEdit querySystemPageList={querySystemPageList} />}
      <Footer />
      <MessageNotification
        content={contentMessageBox}
        showMessageBox={showMessageBox}
        setShowMessageBox={setShowMessageBox}
      />
      <SystemInfo />
      <SystemCreateModal dialogRef={createModalElementRef} querySystemPageList={querySystemPageList} />
      <SystemEditModal dialogRef={editModalElementRef} querySystemPageList={querySystemPageList} />
    </DashboardLayout>
  );
}

export default System;
