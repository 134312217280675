
import FormInputField from "components/HRComponents/FormField/FormInputField";
import FormSelectField from "components/HRComponents/FormField/FormSelectField";
import FormCheckBoxField from "components/HRComponents/FormField/FormCheckBoxField";
import FormSwitchBoxField from "components/HRComponents/FormField/FormSwitchBoxField";
import FormDateTimePickerField from "components/HRComponents/FormField/FormDateTimePickerField";

export {
    FormInputField,
    FormSelectField,
    FormCheckBoxField,
    FormSwitchBoxField,
    FormDateTimePickerField
}