/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useMemo, useEffect, useRef, useCallback } from "react";

import { useTranslation } from "react-i18next";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';

// @mui material components
import { IconButton } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  ChangeCircle as ChangeCircleIcon,
  RestoreFromTrash as RestoreFromTrashIcon,
  Sync as SyncIcon,
  Info as InfoIcon,
} from '@mui/icons-material';


// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setActionView,
  setPagination,
  setSelectPerPage,
  setCode,
  setSearch,
  setStatus,
  setShowDelete,
  setSelectRow,
  getListPage,
  getPagination,
  getSelectPerPage,
  getListPerPage,
  getCode,
  getSearch,
  getStatus,
  getShowDelete,
  setContentMessageBox,
  setShowMessageBox,
  getOpenDialogInfo,
  setOpenDialogInfo,
  getOpenDialogCreate,
  setOpenDialogCreate,
  getOpenDialogEdit,
  setOpenDialogEdit,
} from "states/features/system/systemSlice";
import { getDisplayModalForm } from "states/features/configuration/configurationSlice";
import {
  useGetByIdQuery,
  useGetQuery,
  useGetPageQuery,
  useCreateMutation,
  useUpdateMutation,
  useEditMutation,
  useDestroyMutation,
  useRestoreMutation,
} from "states/services/system";

// MY 
//import DataGrid from "components/HRComponents/DataGrid";
import { DataGrid } from "components/HRComponents/DataGrid.v2";
import { DateTime } from "luxon";
import MDButton from "components/MDButton";

function SystemList({ querySystemPageList }) {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const [textSearch, setTextSearch] = useState('');
  const [textCode, setTextCode] = useState('');

  // 👇 API Login Mutation

  const search = useSelector(getSearch);
  const code = useSelector(getCode);
  const status = useSelector(getStatus);
  const showDelete = useSelector(getShowDelete);
  const displayModalForm = useSelector(getDisplayModalForm);

  const ListPageSystem = useSelector(getListPage);


  // 👇 API Login Mutation
  const [edit, resultEdit] = useEditMutation();
  const [destroy, resultDestroy] = useDestroyMutation();
  const [restore, resultRestore] = useRestoreMutation();

  const actionCommand = useCallback(async (res) => {
    if (res.error) {
      await reduxDispatch(setContentMessageBox({
        //icon: 'notifications',
        openAt: DateTime.now(),
        color: 'warning',
        title: t('pages.system.list_title'),
        content: res.error.data.message,
        contentEx: res.error.data.messageEx
      }))
      await reduxDispatch(setShowMessageBox(true))
      return;
    }
    await reduxDispatch(setContentMessageBox({
      //icon: 'notifications',
      openAt: DateTime.now(),
      color: 'success',
      title: t('pages.system.list_title'),
      content: res.data.message,
      contentEx: null
    }))
    reduxDispatch(setPagination({ currentPage: 1 }))
    reduxDispatch(setShowMessageBox(true))
    querySystemPageList.refetch()
  }, [querySystemPageList, reduxDispatch, t])

  // const actionCommand = async (res) => {
  //   if (res.error) {
  //     await reduxDispatch(setContentMessageBox({
  //       //icon: 'notifications',
  //       openAt: DateTime.now(),
  //       color: 'warning',
  //       title: t('pages.system.list_title'),
  //       content: res.error.data.message
  //     }))
  //     await reduxDispatch(setShowMessageBox(true))
  //     return;
  //   }
  //   await reduxDispatch(setContentMessageBox({
  //     //icon: 'notifications',
  //     openAt: DateTime.now(),
  //     color: 'success',
  //     title: t('pages.system.list_title'),
  //     content: res.data.message
  //   }))
  //   reduxDispatch(setPagination({ currentPage: 1 }))
  //   reduxDispatch(setShowMessageBox(true))
  //   querySystemPageList.refetch()
  // }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id', //access nested data with dot notation
        header: t('pages.system.data_grid.header.id'),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase"
            })}>
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}>
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: 'code',
        header: t('pages.system.data_grid.header.code'),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase"
            })}>
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}>
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: 'name_th',
        header: t('pages.system.data_grid.header.name_th'),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase"
            })}>
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}>
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: 'name_en',
        header: t('pages.system.data_grid.header.name_en'),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase"
            })}>
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}>
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: 'isActive',
        id: 'isActive',
        //accessorFn: (originalRow) => (originalRow.isActive ? 'true' : 'false'), //must be strings
        header: t('pages.system.data_grid.header.isActive'),
        //filterVariant: 'checkbox',
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase"
            })}>
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.lg,
            })}>
            {cell.getValue() ? <Tooltip title={t('pages.system.data_grid.tooltip.label_active')} placement="top"><CheckCircleIcon /> </Tooltip> : <Tooltip title={t('pages.system.data_grid.tooltip.label_inactive')} placement="top"><CancelIcon /> </Tooltip>}
          </MDTypography>
        ),
      },
      {
        accessorKey: 'version',
        header: t('pages.system.data_grid.header.version'),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase"
            })}>
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}>
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: 'systemOfCreatedBy',
        header: t('pages.system.data_grid.header.createdBy'),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase"
            })}>
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}>
            {cell.getValue() ? '[ ' + cell.getValue().username + ' ] : ' + cell.getValue()["name_" + i18n.resolvedLanguage] : ''}
          </MDTypography>
        ),
      },
      {
        accessorKey: 'systemOfUpdatedBy',
        header: t('pages.system.data_grid.header.updatedBy'),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase"
            })}>
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}>
            {cell.getValue() ? '[ ' + cell.getValue().username + ' ] : ' + cell.getValue()["name_" + i18n.resolvedLanguage] : ''}
          </MDTypography>
        ),
      },
      {
        accessorKey: 'createdAt',
        header: t('pages.system.data_grid.header.createdAt'),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase"
            })}>
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}>
            {cell.getValue() ? t('pages.system.data_grid.detail.createdAt', { date: new Date(cell.getValue()), lng: i18n.resolvedLanguage }) : ''}
          </MDTypography>
        ),
      },
      {
        accessorKey: 'updatedAt',
        header: t('pages.system.data_grid.header.updatedAt'),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase"
            })}>
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}>
            {cell.getValue() ? t('pages.system.data_grid.detail.updatedAt', { date: new Date(cell.getValue()), lng: i18n.resolvedLanguage }) : ''}
          </MDTypography>
        ),
      },
      {
        accessorKey: 'deletedAt',
        header: t('pages.system.data_grid.header.deletedAt'),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase"
            })}>
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}>
            {cell.getValue() ? t('pages.system.data_grid.detail.deletedAt', { date: new Date(cell.getValue()), lng: i18n.resolvedLanguage }) : ''}
          </MDTypography>
        ),
      },
      {
        id: 'action',
        header: t('pages.system.data_grid.header.action'),
        columnDefType: 'display', //turns off data column features like sorting, filtering, etc.
        enableColumnOrdering: true, //but you can turn back any of those features on if you want like this
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              justifyContent: "center",
              alignItems: "center",
              color: "text",
              textTransform: "uppercase",
              pt: 0.3
            })}>
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ row }) => (
          <MDBox
            justifyContent="center"
            alignItems="center"
            color="text"
          >
            <Tooltip title={t('pages.system.data_grid.action.label_info')} placement="top">
              <IconButton
                color="secondary"
                fontSize="inherit"
                onClick={(event) => {
                  event.preventDefault()
                  reduxDispatch(setSelectRow(row.original))
                  reduxDispatch(setOpenDialogInfo(true));
                }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title={t('pages.system.data_grid.action.label_edit')} placement="top">
              <IconButton
                disabled={row.original.deletedAt}
                color="warning"
                fontSize="inherit"
                onClick={(event) => {
                  event.preventDefault()
                  reduxDispatch(setSelectRow(row.original))
                  //reduxDispatch(setActionView("EDIT"))
                  if (displayModalForm) {
                    reduxDispatch(setOpenDialogEdit(true))
                  } else {
                    reduxDispatch(setActionView("EDIT"))
                  }
                }}>
                <EditIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title={row.original.isActive ? t('pages.system.data_grid.action.label_toggle_inactive') : t('pages.system.data_grid.action.label_toggle_active')} placement="top">
              <IconButton
                disabled={row.original.deletedAt}
                color="primary"
                fontSize="inherit"
                onClick={async (event) => {
                  event.preventDefault()
                  let res = await edit({ id: row.original.id, version: row.original.version, isActive: !row.original.isActive })
                  actionCommand(res)
                }}>
                <ChangeCircleIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title={t('pages.system.data_grid.action.label_delete')} placement="top">
              <IconButton
                disabled={row.original.deletedAt}
                color="error"
                fontSize="inherit"
                onClick={async (event) => {
                  event.preventDefault()
                  let res = await destroy({ id: row.original.id, version: row.original.version })
                  actionCommand(res)
                }}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('pages.system.data_grid.action.label_retore')} placement="top">
              <IconButton
                disabled={!row.original.deletedAt}
                color="success"
                fontSize="inherit"
                onClick={async () => {
                  let res = await restore({ id: row.original.id, version: row.original.version })
                  actionCommand(res)
                }}>
                <RestoreFromTrashIcon />
              </IconButton>
            </Tooltip>
          </MDBox>
        ),
      },
    ],
    [actionCommand, destroy, displayModalForm, edit, i18n.resolvedLanguage, reduxDispatch, restore, t],
  );

  useEffect(() => {
    setTextCode(code)
    setTextSearch(search)
  }, [code, search]);

  return (
    <MDBox py={3}>
      <Card sx={{ width: "100%" }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="success"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              <AppsIcon />
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            {t('pages.system.list_title')}
          </MDTypography>
        </MDBox>
        <MDBox p={2} >
          <DataGrid
            data={ListPageSystem?.result || []}
            columns={columns}
            onNewEvent={(event) => {
              event.preventDefault()
              //reduxDispatch(setActionView("CREATE"))
              if (displayModalForm) {
                reduxDispatch(setOpenDialogCreate(true))
              } else {
                reduxDispatch(setActionView("CREATE"))
              }
            }}
            //onGlobalFilterChange={setGlobalFilter}
            getSelectPerPage={getSelectPerPage}
            getListPerPage={getListPerPage}
            getPagination={getPagination}
            setPagination={setPagination}
            setSelectPerPage={setSelectPerPage}
            queryPageList={querySystemPageList}
            initialState={{
              density: 'compact',  
              columnVisibility: {
                //id: false,
                name_th: i18n.resolvedLanguage === "th",
                name_en: i18n.resolvedLanguage === "en",
                version: false,
                systemOfCreatedBy: false,
                systemOfUpdatedBy: false,
                createdAt: false,
                updatedAt: false,
                deletedAt: false,
              },
            }}
            state={{
              showProgressBars: querySystemPageList.isFetching,
              isLoading: querySystemPageList.isLoading,
            }}
            renderTopToolbarCustomActions={
              <>
                <Grid item
                  xs={12} sm={5} md={5} lg={5} xl={3} xxl={3}
                  ml={{ xl: '-4%', xxl: '-5%' }}
                  mt={.5} >
                  <MDInput
                    sx={{ width: '100%' }}
                    size="small"
                    id="search_text"
                    type="search"
                    InputLabelProps={{ shrink: true }}
                    value={textCode}
                    onChange={(event) => {
                      event.preventDefault()
                      setTextCode(event.target.value)
                    }}
                    onKeyPress={event => {
                      //event.preventDefault()
                      if (event.key === 'Enter') {
                        reduxDispatch(setPagination({ currentPage: 1 }))
                        reduxDispatch(setCode(textCode))
                      }
                    }}
                    onBlur={() => {
                      reduxDispatch(setPagination({ currentPage: 1 }))
                      reduxDispatch(setCode(textCode))
                    }}
                    label={t('pages.system.label_code')}
                  />
                </Grid>
                <Grid item
                  xs={12} sm={5} md={5} lg={5} xl={3} xxl={3}
                  mt={.5} >
                  <MDInput
                    sx={{ width: '100%' }}
                    size="small"
                    id="search_text"
                    type="search"
                    InputLabelProps={{ shrink: true }}
                    value={textSearch}
                    onChange={(event) => {
                      event.preventDefault()
                      setTextSearch(event.target.value)

                    }}
                    onKeyPress={event => {
                      //event.preventDefault()
                      if (event.key === 'Enter') {
                        reduxDispatch(setPagination({ currentPage: 1 }))
                        reduxDispatch(setSearch(textSearch))
                      }
                    }}
                    onBlur={() => {
                      reduxDispatch(setPagination({ currentPage: 1 }))
                      reduxDispatch(setSearch(textSearch))
                    }}
                    label={t('pages.system.label_search')}
                  />
                </Grid>
                <Grid item
                  xs={12} sm={2} md={2} lg={2} xl={1} xxl={1}
                  mt={.5} >
                  <MDButton
                    size="small"
                    variant="standart"
                    iconOnly={true}
                    onClick={(event) => {
                      event.preventDefault()
                      reduxDispatch(setPagination({ currentPage: 1 }))
                      querySystemPageList.refetch()
                    }}>
                    <Icon variant="button" fontSize='small' color="info" >
                      <SyncIcon />
                    </Icon>
                  </MDButton>
                </Grid>
                <Grid item
                  xs={12} sm={6} md={6} lg={6} xl={2} xxl={2}
                  ml={{ sm: 0, md: 0, xl: '-4.5%', xxl: '-4.5%' }}
                  mr={{ xl: '2%' ,xxl: '2%' }}
                  mt={.3} >
                  <ToggleButtonGroup
                    width={{ xs: "auto", sm: "auto", md: "auto", lg: "auto", xl: "auto", xxl: "auto" }}
                    color="info"
                    size="small"
                    value={status}
                    exclusive
                    onChange={(event, newAlignment) => {
                      event.preventDefault()
                      reduxDispatch(setPagination({ currentPage: 1 }))
                      reduxDispatch(setStatus(newAlignment))
                    }}
                    aria-label={t('pages.system.data_grid.header.isActive')}
                  >
                    <ToggleButton value={null}><MDBox color="text">{t('pages.system.label_all')}</MDBox></ToggleButton>
                    <ToggleButton value={true}><MDBox color="text">{t('pages.system.label_active')}</MDBox></ToggleButton>
                    <ToggleButton value={false}><MDBox color="text">{t('pages.system.label_inactive')}</MDBox></ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item
                  xs={12} sm={6} md={6} lg={6} xl={2} xxl={2}
                  ml={{ xs: -1, sm: '-5%', md: '-20%', lg: '-10%', xl: '3%', xxl:'-1%'}}
                  mt={.3}>
                  <MDBox
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    width={{ xs: "auto", sm: "auto", md: "auto", lg: "auto", xl: "auto", xxl: "auto" }}>
                    <MDBox>
                      <Switch
                        variant="standard"
                        checked={showDelete}
                        onChange={(event) => {
                          event.preventDefault()
                          reduxDispatch(setPagination({ currentPage: 1 }))
                          reduxDispatch(setShowDelete(!showDelete))
                        }}
                      />
                    </MDBox>
                    <MDBox lineHeight={0} >
                      <MDTypography variant="button" color="text">
                        {showDelete ? t('pages.system.label_show_deleted') : t('pages.system.label_hide_deleted')}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              </>
            } />
        </MDBox>
      </Card>
    </MDBox>
  );
}

export default SystemList;
