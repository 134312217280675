/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useMemo } from "react";

import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_Table,
  MRT_TablePagination,
} from "material-react-table";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import ProfilesList from "components/Lists/ProfilesList";
import DefaultProjectCard from "components/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "pages/profile/components/Header";
import PlatformSettings from "pages/profile/profile-overview/components/PlatformSettings";
import ChangePassword from "pages/profile/components/ChangePassword";
import TwoFactorAuthentication from "pages/profile/components/TwoFactorAuthentication";
import ActivityHistory from "pages/profile/components/ActivityHistory";
import ProfileInfoCard from "pages/profile/components/ProfileInfoCard";
import EditProfileInfoCard from "pages/profile/components/EditProfileInfoCard";

import { useTranslation } from "react-i18next";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setGeoLocation,
  getGeoLocation,
} from "../../../states/features/configuration/configurationSlice";

import { useGetClientInfoQuery } from "../../../states/services/clientInfo";
import { useHistoryQuery } from "../../../states/services/authentication";
// MY
import MessageNotification from "components/HRComponents/MessageNotification";

import { ReactComponent as LineLogo } from "assets/images/logos/logo-line.svg";

import {
  selectAuth,
  logout,
  login,
  setHistory,
  setPagination,
  setSelectPerPage,
  setListPerPage,
  setSearch,
  setActiveOnly,
  setIsFail,
  setStatus,
  setShowDelete,
  getHistory,
  getPagination,
  getSelectPerPage,
  getListPerPage,
  getSearch,
  getActiveOnly,
  getIsFail,
  getStatus,
  getShowDelete,
  setShowMessageBox,
  getShowMessageBox,
  setContentMessageBox,
  getContentMessageBox,
} from "states/features/auth/authSlice";

function Overview() {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const geoLocation = useSelector(getGeoLocation);

  const [showEditPage, setShowEditPage] = useState(false);

  const showMessageBox = useSelector(getShowMessageBox);
  const contentMessageBox = useSelector(getContentMessageBox);

  const queryuseClientInfo = useGetClientInfoQuery();

  const userAuth = useSelector(selectAuth);
  const search = useSelector(getSearch);
  const isFail = useSelector(getIsFail);
  const status = useSelector(getStatus);
  const showDelete = useSelector(getShowDelete);
  const pagination = useSelector(getPagination);
  const selectPerPage = useSelector(getSelectPerPage);

  const queryHistoryPageList = useHistoryQuery(
    {
      isActive: status === null ? undefined : status,
      isFails: isFail === null ? undefined : isFail,
      hiddenDelete: showDelete ? false : undefined,
      textSearch: search,
      page: pagination?.currentPage || 1,
      size: selectPerPage || 50,
    }
    // , {
    //   refetchOnMountOrArgChange: true,
    //   //pollingInterval: 3000
    // }
  );

  useEffect(() => {
    if (queryHistoryPageList.isSuccess) {
      reduxDispatch(setHistory(queryHistoryPageList?.data?.result));
      reduxDispatch(
        setPagination({
          totalItems: queryHistoryPageList?.data?.result?.totalItems,
          totalPages: queryHistoryPageList?.data?.result?.totalPages,
        })
      );
    }
  }, [
    queryHistoryPageList.data?.result,
    queryHistoryPageList.isSuccess,
    reduxDispatch,
  ]);

  // Setting the dir attribute for the body element
  useEffect(() => {
    if (queryuseClientInfo.isSuccess) {
      reduxDispatch(setGeoLocation(queryuseClientInfo.data));
    }
  }, [queryuseClientInfo.data, queryuseClientInfo.isSuccess, reduxDispatch]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              {showEditPage ? (
                <EditProfileInfoCard
                  title={t("pages.profile.information")}
                  action={{
                    handleClick: () => {
                      setShowEditPage(false);
                    },
                    tooltip: t("pages.profile.tooltip.label_edit_profile"),
                  }}
                  shadow={false}
                  setShowEditPage={setShowEditPage}
                />
              ) : (
                <ProfileInfoCard
                  title={t("pages.profile.information")}
                  description={
                    userAuth?.user
                      ? userAuth.user["description_" + i18n.resolvedLanguage]
                      : ""
                  }
                  info={[
                    {
                      label: t("pages.profile.fullname"),
                      data: userAuth?.user
                        ? userAuth.user["name_" + i18n.resolvedLanguage]
                        : "",
                    },
                    {
                      label: t("pages.profile.company"),
                      data: userAuth?.user
                        ? userAuth.user?.userOfCompanyBy ? userAuth.user?.userOfCompanyBy["name_" + i18n.resolvedLanguage] : ""
                        : "",
                    },
                    {
                      label: t("pages.profile.mobile"),
                      data: userAuth?.user ? userAuth.user.phone || "-" : "",
                    },
                    {
                      label: t("pages.profile.email"),
                      data: userAuth?.user ? userAuth.user.email || "" : "",
                    },
                    {
                      label: t("pages.profile.location"),
                      data:
                        geoLocation?.country_code +
                        " : [" +
                        geoLocation?.country_name +
                        "]",
                    },
                  ]}
                  social={[
                    {
                      link:
                        "https://www.facebook.com/" +
                        (userAuth?.user.facebook ? userAuth.user.facebook : ""), //"https://www.facebook.com/CreativeTim/",
                      icon: <FacebookIcon />,
                      color: "facebook",
                    },
                    {
                      link:
                        "https://twitter.com/" +
                        (userAuth?.user.twitter ? userAuth.user.twitter : ""),
                      icon: <TwitterIcon />,
                      color: "twitter",
                    },
                    {
                      link:
                        "https://www.instagram.com/" +
                        (userAuth?.user.instagram
                          ? userAuth.user.instagram
                          : ""),
                      icon: <InstagramIcon />,
                      color: "instagram",
                    },
                    {
                      link:
                        "https://line.me/ti/p/" +
                        (userAuth?.user.line ? userAuth.user.line : ""),
                      icon: <LineLogo height={22} width={22} />,
                      color: "line",
                    },
                  ]}
                  action={{
                    handleClick: () => {
                      setShowEditPage(true);
                    },
                    tooltip: t("pages.profile.tooltip.label_edit_profile"),
                  }}
                  shadow={false}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ChangePassword />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} xl={4}>
              <TwoFactorAuthentication />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDBox mb={1}>
            <ActivityHistory queryHistoryPageList={queryHistoryPageList} />
          </MDBox>
        </MDBox>
      </Header>
      <Footer />
      <MessageNotification
        content={contentMessageBox}
        showMessageBox={showMessageBox}
        setShowMessageBox={setShowMessageBox}
      />
    </DashboardLayout>
  );
}

export default Overview;
