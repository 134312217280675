import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import { setupListeners } from "@reduxjs/toolkit/query"
import counterReducer from "./features/counter/counterSlice"
import authReducer from "./features/auth/authSlice"
import companyReducer from "./features/company/companySlice"
import roleReducer from "./features/role/roleSlice"
import systemReducer from "./features/system/systemSlice"
import functionReducer from "./features/function/functionSlice"
import moduleReducer from "./features/module/moduleSlice"
import logReducer from "./features/log/logSlice"
import refreshTokenReducer from "./features/refreshToken/refreshTokenSlice"
import userReducer from "./features/user/userSlice"
import privilegesReducer from "./features/privileges/privilegesSlice"
import permissionReducer from "./features/permission/permissionSlice"
import configurationReducer from "./features/configuration/configurationSlice"

import { pokemonApi } from "./services/pokemon"
import { authenticationApi } from "./services/authentication"
import { companyApi } from "./services/company"
import { roleApi } from "./services/role"
import { systemApi } from "./services/system"
import { functionApi } from "./services/function"
import { moduleApi } from "./services/module"
import { logApi } from "./services/log"
import { refreshTokenApi } from "./services/refreshToken"
import { userApi } from "./services/user"
import { privilegesApi } from "./services/privileges"
import { permissionApi } from "./services/permission"
import { clientInfoApi } from "./services/clientInfo"

import { REDUX_DEV_TOOLS } from 'constraints'

const persistConfig = {
  key: "any-z.security",
  version: 1,
  storage,
  blacklist: [
    // pokemonApi.reducerPath, 
    // authenticationApi.reducerPath,
    // companyApi.reducerPath,
    // roleApi.reducerPath,
    // systemApi.reducerPath,
    // functionApi.reducerPath,
    // moduleApi.reducerPath,
    // logApi.reducerPath,
    // refreshTokenApi.reducerPath,
    // userApi.reducerPath,
    // privilegesApi.reducerPath,
    // permissionApi.reducerPath,
    // clientInfoApi.reducerPath,
  ],
  //whitelist: ['language']
};
const appReducer = combineReducers({
  counter: counterReducer,
  auth: authReducer,
  company: companyReducer,
  role: roleReducer,
  system: systemReducer,
  function: functionReducer,
  module: moduleReducer,
  log: logReducer,
  refreshToken: refreshTokenReducer,
  user: userReducer,
  privileges: privilegesReducer,
  permission: permissionReducer,
  configuration: configurationReducer,
  [pokemonApi.reducerPath]: pokemonApi.reducer,
  [authenticationApi.reducerPath]: authenticationApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [systemApi.reducerPath]: systemApi.reducer,
  [functionApi.reducerPath]: functionApi.reducer,
  [moduleApi.reducerPath]: moduleApi.reducer,
  [logApi.reducerPath]: logApi.reducer,
  [refreshTokenApi.reducerPath]: refreshTokenApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [privilegesApi.reducerPath]: privilegesApi.reducer,
  [permissionApi.reducerPath]: permissionApi.reducer,
  [clientInfoApi.reducerPath]: clientInfoApi.reducer,
  //  if we need to use more reducers
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    storage.removeItem('persist:any-z.security')
    state = {};
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer)


const store = configureStore({
  reducer: persistedReducer,
  devTools: REDUX_DEV_TOOLS,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat([
    pokemonApi.middleware,
    authenticationApi.middleware,
    companyApi.middleware,
    roleApi.middleware,
    systemApi.middleware,
    functionApi.middleware,
    moduleApi.middleware,
    logApi.middleware,
    refreshTokenApi.middleware,
    userApi.middleware,
    privilegesApi.middleware,
    permissionApi.middleware,
    clientInfoApi.middleware,
  ]),
});


// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export default store
export const persistor = persistStore(store)
export const useAppDispatch = () => useDispatch()
export const useAppSelector = useSelector
