/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";


// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import { darken } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from "@mui/material/Tooltip";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import Transaction from "components/HRComponents/Transaction";
import ModalDisplayDetail from "components/HRComponents/ModalDisplayDetail";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  useCreateMutation,
} from "states/services/company";
import {
  getSelectRow,
  getOpenDialogInfo,
  setOpenDialogInfo,
} from "states/features/company/companySlice";
import { DateTime } from "luxon";




function CompanyInfo() {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const openDialog = useSelector(getOpenDialogInfo);
  const selectRow = useSelector(getSelectRow);

  const handleClose = () => {
    reduxDispatch(setOpenDialogInfo(false));
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (openDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDialog]);

  const contentDisplayDetail = () => (
    <MDBox>
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.id")}
        description={selectRow?.id}
      />
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.code")}
        description={selectRow?.code}
      />
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.name_th")}
        description={selectRow?.name_th}
      />
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.name_en")}
        description={selectRow?.name_en}
      />
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.description_th")}
        description={selectRow?.description_th}
      />
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.description_en")}
        description={selectRow?.description_en}
      />
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.address_th")}
        description={selectRow?.address_th}
      />
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.address_en")}
        description={selectRow?.address_en}
      />
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.phone")}
        description={selectRow?.phone}
      />
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.email")}
        description={selectRow?.email}
      />
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.headBy")}
        description={selectRow?.head ? "[" + selectRow.head?.code + "] " + selectRow?.head["name_" + i18n.resolvedLanguage] : ""}
      />
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.isActive")}
        description={selectRow?.isActive ? <Tooltip title={t('pages.company.data_grid.tooltip.label_active')} placement="top"><CheckCircleIcon /> </Tooltip> : <Tooltip title={t('pages.company.data_grid.tooltip.label_inactive')} placement="top"><CancelIcon /> </Tooltip>}
      />
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.version")}
        description={selectRow?.version}
      />
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.createdBy")}
        description={selectRow?.companyOfCreatedBy ? '[ ' + selectRow.companyOfCreatedBy.username + ' ] : ' + selectRow.companyOfCreatedBy["name_" + i18n.resolvedLanguage] : ''}
      />
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.createdAt")}
        description={selectRow?.createdAt ? t('pages.company.data_grid.detail.createdAt', { date: new Date(selectRow.createdAt), lng: i18n.resolvedLanguage }) : ''}
      />
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.updatedBy")}
        description={selectRow?.companyOfUpdatedBy ? '[ ' + selectRow.companyOfUpdatedBy.username + ' ] : ' + selectRow.companyOfUpdatedBy["name_" + i18n.resolvedLanguage] : ''}
      />
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.updatedAt")}
        description={selectRow?.updatedAt ? t('pages.company.data_grid.detail.updatedAt', { date: new Date(selectRow.updatedAt), lng: i18n.resolvedLanguage }) : ''}
      />
      <Transaction
        color="success"
        name={t("pages.company.data_grid.header.deletedAt")}
        description={selectRow?.deletedAt ? t('pages.company.data_grid.detail.deletedAt', { date: new Date(selectRow.deletedAt), lng: i18n.resolvedLanguage }) : ''}
      />
    </MDBox>
  )

  return (
    <ModalDisplayDetail
      title={t("pages.company.list_info")}
      closeLabel={t("pages.company.action.label_close")}
      content={contentDisplayDetail()}
      openDialog={openDialog}
      handleClose={handleClose}
      dialogRef={descriptionElementRef}
    />
  );
}

export default CompanyInfo;
