import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    privilegesRoleList: null,
    privilegesRoleSearch: '',
    privilegesRoleActiveOnly: false,
    privilegesRoleStatus: null,
    privilegesRoleShowDelete: false,
    privilegesRoleListPage: null,
    privilegesRoleSelectRow: null,
    privilegesRolePagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1
    },
    privilegesRoleSelectPerPage: 20,
    privilegesRoleListPerPage: [10, 20, 30, 40, 50, 100, 150, 200],
    privilegesUserList: null,
    privilegesUserSearch: '',
    privilegesUserActiveOnly: false,
    privilegesUserStatus: null,
    privilegesUserShowDelete: false,
    privilegesUserListPage: null,
    privilegesUserSelectRow: null,
    privilegesUserPagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1
    },
    privilegesUserSelectPerPage: 20,
    privilegesUserListPerPage: [10, 20, 30, 40, 50, 100, 150, 200],
    privilegesListPage: null,
    privilegesSelectRow: null,
    privilegesActionView: 'LIST', //'LIST', 'NEW','EDIT',DELETE, DETAIL
    privilegesOpenDialogInfo: false, 
    privilegesOpenDialogCreate: false, 
    privilegesOpenDialogEdit: false, 
    privilegesPagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1
    },
    privilegesSelectPerPage: 20,
    privilegesListPerPage: [10, 20, 30, 40, 50, 100, 150, 200],
    privilegesSearch: '',
    privilegesActiveOnly: false,
    privilegesStatus: null,
    privilegesShowDelete: false,
    privilegesShowMessageBox: false,
    privilegesContentMessageBox: {
        icon: 'notifications',
        color: 'info',
        openAt: null,
        content: '',
        contentEx: null,
        title: ''
    },
};

export const privilegesSlice = createSlice({
    name: 'privileges',
    initialState: initialState,
    reducers: {
        setRoleList: (state, action) => {
            state.privilegesRoleList = action.payload;
        },
        setRoleSearch: (state, action) => {
            state.privilegesRoleSearch = action.payload;
        },
        setRoleActiveOnly: (state, action) => {
            state.privilegesRoleActiveOnly = action.payload;
        },
        setRoleStatus: (state, action) => {
            state.privilegesRoleStatus = action.payload;
        },
        setRoleShowDelete: (state, action) => {
            state.privilegesRoleShowDelete = action.payload;
        },
        setRoleListPage: (state, action) => {
            state.privilegesRoleListPage = action.payload;
        },
        setRoleSelectRow: (state, action) => {
            state.privilegesRoleSelectRow = action.payload;
        },
        setRolePagination: (state, action) => {
            state.privilegesRolePagination = { ...state.privilegesRolePagination, ...action.payload };
        },
        setRoleSelectPerPage: (state, action) => {
            state.privilegesRoleSelectPerPage = action.payload;
        },
        setRoleListPerPage: (state, action) => {
            state.privilegesRoleListPerPage = action.payload;
        },
        setUserSearch: (state, action) => {
            state.privilegesUserSearch = action.payload;
        },
        setUserActiveOnly: (state, action) => {
            state.privilegesUserActiveOnly = action.payload;
        },
        setUserStatus: (state, action) => {
            state.privilegesUserStatus = action.payload;
        },
        setUserShowDelete: (state, action) => {
            state.privilegesUserShowDelete = action.payload;
        },
        setUserList: (state, action) => {
            state.privilegesUserList = action.payload;
        },
        setUserListPage: (state, action) => {
            state.privilegesUserListPage = action.payload;
        },
        setUserSelectRow: (state, action) => {
            state.privilegesUserSelectRow = action.payload;
        },
        setUserPagination: (state, action) => {
            state.privilegesUserPagination = { ...state.privilegesUserPagination, ...action.payload };
        },
        setUserSelectPerPage: (state, action) => {
            state.privilegesUserSelectPerPage = action.payload;
        },
        setUserListPerPage: (state, action) => {
            state.privilegesUserListPerPage = action.payload;
        },
        setListPage: (state, action) => {
            state.privilegesListPage = action.payload;
        },
        setSelectRow: (state, action) => {
            state.privilegesSelectRow = action.payload;
        },
        setActionView: (state, action) => {
            state.privilegesActionView = action.payload;
        },
        setOpenDialogInfo: (state, action) => {
            state.privilegesOpenDialogInfo = action.payload;
        },
        setOpenDialogCreate: (state, action) => {
            state.privilegesOpenDialogCreate = action.payload;
        },
        setOpenDialogEdit: (state, action) => {
            state.privilegesOpenDialogEdit = action.payload;
        },
        setPagination: (state, action) => {
            state.privilegesPagination = { ...state.privilegesPagination, ...action.payload };
        },
        setSelectPerPage: (state, action) => {
            state.privilegesSelectPerPage = action.payload;
        },
        setListPerPage: (state, action) => {
            state.privilegesListPerPage = action.payload;
        },
        setSearch: (state, action) => {
            state.privilegesSearch = action.payload;
        },
        setActiveOnly: (state, action) => {
            state.privilegesActiveOnly = action.payload;
        },
        setStatus: (state, action) => {
            state.privilegesStatus = action.payload;
        },
        setShowDelete: (state, action) => {
            state.privilegesShowDelete = action.payload;
        },
        setShowMessageBox: (state, action) => {
            state.privilegesShowMessageBox = action.payload;
        },
        setContentMessageBox: (state, action) => {
            state.privilegesContentMessageBox = { ...state.privilegesContentMessageBox, ...action.payload };
        },
    },
})

export const {
    setRoleList,
    setRoleSearch,
    setRoleActiveOnly,
    setRoleStatus,
    setRoleShowDelete,
    setRoleListPage,
    setRoleSelectRow,
    setRolePagination,
    setRoleSelectPerPage,
    setRoleListPerPage,
    setUserList,
    setUserSearch,
    setUserActiveOnly,
    setUserStatus,
    setUserShowDelete,
    setUserListPage,
    setUserSelectRow,
    setUserPagination,
    setUserSelectPerPage,
    setUserListPerPage,
    setListPage,
    setSelectRow,
    setActionView,
    setOpenDialogInfo,
    setOpenDialogCreate,
    setOpenDialogEdit,
    setPagination,
    setSelectPerPage,
    setListPerPage,
    setSearch,
    setActiveOnly,
    setStatus,
    setShowDelete,
    setShowMessageBox,
    setContentMessageBox
} = privilegesSlice.actions

export const getRoleList = (state) => state.privileges.privilegesRoleList
export const getRoleSearch = (state) => state.privileges.privilegesRoleSearch
export const getRoleActiveOnly = (state) => state.privileges.privilegesRoleActiveOnly
export const getRoleStatus = (state) => state.privileges.privilegesRoleStatus
export const getRoleShowDelete = (state) => state.privileges.privilegesRoleShowDelete
export const getRoleListPage = (state) => state.privileges.privilegesRoleListPage
export const getRoleSelectRow = (state) => state.privileges.privilegesRoleSelectRow
export const getRolePagination = (state) => state.privileges.privilegesRolePagination
export const getRoleSelectPerPage = (state) => state.privileges.privilegesRoleSelectPerPage
export const getRoleListPerPage = (state) => state.privileges.privilegesRoleListPerPage
export const getUserList = (state) => state.privileges.privilegesUserList
export const getUserSearch = (state) => state.privileges.privilegesUserSearch
export const getUserActiveOnly = (state) => state.privileges.privilegesUserActiveOnly
export const getUserStatus = (state) => state.privileges.privilegesUserStatus
export const getUserShowDelete = (state) => state.privileges.privilegesUserShowDelete
export const getUserListPage = (state) => state.privileges.privilegesUserListPage
export const getUserSelectRow = (state) => state.privileges.privilegesUserSelectRow
export const getUserPagination = (state) => state.privileges.privilegesUserPagination
export const getUserSelectPerPage = (state) => state.privileges.privilegesUserSelectPerPage
export const getUserListPerPage = (state) => state.privileges.privilegesUserListPerPage
export const getListPage = (state) => state.privileges.privilegesListPage
export const getSelectRow = (state) => state.privileges.privilegesSelectRow
export const getActionView = (state) => state.privileges.privilegesActionView
export const getOpenDialogInfo = (state) => state.privileges.privilegesOpenDialogInfo
export const getOpenDialogCreate = (state) => state.privileges.privilegesOpenDialogCreate
export const getOpenDialogEdit = (state) => state.privileges.privilegesOpenDialogEdit
export const getPagination = (state) => state.privileges.privilegesPagination
export const getSelectPerPage = (state) => state.privileges.privilegesSelectPerPage
export const getListPerPage = (state) => state.privileges.privilegesListPerPage
export const getSearch = (state) => state.privileges.privilegesSearch
export const getActiveOnly = (state) => state.privileges.privilegesActiveOnly
export const getStatus = (state) => state.privileges.privilegesStatus
export const getShowDelete = (state) => state.privileges.privilegesShowDelete
export const getShowMessageBox = (state) => state.privileges.privilegesShowMessageBox
export const getContentMessageBox = (state) => state.privileges.privilegesContentMessageBox
export default privilegesSlice.reducer