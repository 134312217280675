import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    companyListPage: null,
    companySelectRow: null,
    companyActionView: 'LIST', //'LIST', 'NEW','EDIT',DELETE, DETAIL
    companyOpenDialogInfo: false, 
    companyOpenDialogCreate: false, 
    companyOpenDialogEdit: false, 
    companyPagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1
    },
    companySelectPerPage: 20,
    companyListPerPage: [10, 20, 30, 40, 50, 100, 150, 200],
    companyHeadOfficeList: null,
    companyHeadOfficeSelect: null,
    companyCode: '',
    companySearch: '',
    companyActiveOnly: false,
    companyStatus: null,
    companyShowDelete: false,
    companyShowMessageBox: false,
    companyContentMessageBox: {
        icon: 'notifications',
        color: 'info',
        openAt: null,
        content: '',
        contentEx: null,
        title: ''
    },
};

export const companySlice = createSlice({
    name: 'company',
    initialState: initialState,
    reducers: {
        setListPage: (state, action) => {
            state.companyListPage = action.payload;
        },
        setSelectRow: (state, action) => {
            state.companySelectRow = action.payload;
        },
        setActionView: (state, action) => {
            state.companyActionView = action.payload;
        },
        setOpenDialogInfo: (state, action) => {
            state.companyOpenDialogInfo = action.payload;
        },
        setOpenDialogCreate: (state, action) => {
            state.companyOpenDialogCreate = action.payload;
        },
        setOpenDialogEdit: (state, action) => {
            state.companyOpenDialogEdit = action.payload;
        },
        setHeadOfficeList: (state, action) => {
            state.companyHeadOfficeList = action.payload;
        },
        setHeadOfficeSelect: (state, action) => {
            state.companyHeadOfficeSelect = action.payload;
        },
        setPagination: (state, action) => {
            state.companyPagination = { ...state.companyPagination, ...action.payload };
        },
        setSelectPerPage: (state, action) => {
            state.companySelectPerPage = action.payload;
        },
        setListPerPage: (state, action) => {
            state.companyListPerPage = action.payload;
        },
        setCode: (state, action) => {
            state.companyCode = action.payload;
        },
        setSearch: (state, action) => {
            state.companySearch = action.payload;
        },
        setActiveOnly: (state, action) => {
            state.companyActiveOnly = action.payload;
        },
        setStatus: (state, action) => {
            state.companyStatus = action.payload;
        },
        setShowDelete: (state, action) => {
            state.companyShowDelete = action.payload;
        },
        setShowMessageBox: (state, action) => {
            state.companyShowMessageBox = action.payload;
        },
        setContentMessageBox: (state, action) => {
            state.companyContentMessageBox = { ...state.companyContentMessageBox, ...action.payload };
        },
    },
})

export const {
    setListPage,
    setSelectRow,
    setActionView,
    setOpenDialogInfo,
    setOpenDialogCreate,
    setOpenDialogEdit,
    setHeadOfficeList,
    setHeadOfficeSelect,
    setPagination,
    setSelectPerPage,
    setListPerPage,
    setCode,
    setSearch,
    setActiveOnly,
    setStatus,
    setShowDelete,
    setShowMessageBox,
    setContentMessageBox
} = companySlice.actions

export const getListPage = (state) => state.company.companyListPage
export const getSelectRow = (state) => state.company.companySelectRow
export const getActionView = (state) => state.company.companyActionView
export const getOpenDialogInfo = (state) => state.company.companyOpenDialogInfo
export const getOpenDialogCreate = (state) => state.company.companyOpenDialogCreate
export const getOpenDialogEdit = (state) => state.company.companyOpenDialogEdit
export const getPagination = (state) => state.company.companyPagination
export const getSelectPerPage = (state) => state.company.companySelectPerPage
export const getListPerPage = (state) => state.company.companyListPerPage
export const getHeadOfficeList = (state) => state.company.companyHeadOfficeList
export const getHeadOfficeSelect = (state) => state.company.companyHeadOfficeSelect
export const getCode = (state) => state.company.companyCode
export const getSearch = (state) => state.company.companySearch
export const getActiveOnly = (state) => state.company.companyActiveOnly
export const getStatus = (state) => state.company.companyStatus
export const getShowDelete = (state) => state.company.companyShowDelete
export const getShowMessageBox = (state) => state.company.companyShowMessageBox
export const getContentMessageBox = (state) => state.company.companyContentMessageBox
export default companySlice.reducer