// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

// @mui material components
import { MenuItem, FormControl, Select, TextField, FormHelperText } from "@mui/material";
import Icon from "@mui/material/Icon";
import { makeStyles } from '@mui/styles';
import { createStyles } from '@mui/material';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDPagination from "components/MDPagination";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";

// redux
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    textDarkColor: {
        color: 'white'
    },
    textColor: {
        color: 'text'
    }
}));

function Pagination({ getSelectPerPage, getListPerPage, getPagination, setPagination, setSelectPerPage, queryPageList }) {
    const { t, i18n } = useTranslation();
    const reduxDispatch = useDispatch();
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const classes = useStyles();
    const [currentPage, setCurrentPage] = useState(1);
    const selectPerPage = useSelector(getSelectPerPage);
    const listPerPage = useSelector(getListPerPage);
    const pagination = useSelector(getPagination);

    useEffect(() => {
        setCurrentPage(pagination.currentPage)
    }, [pagination]);

    const generateSelectPerPage = () => (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }} size="small">
            <Select
                id="pageLimit"
                //label="PageLimit"
                className={darkMode ? classes.textDarkColor : classes.textColor}
                sx={(theme) => ({ color: theme.palette.text, fontSize: theme.typography.size.sm, textAlign: "center" })}
                value={selectPerPage}
                renderValue={(value) => <MDBox color="text" alignItems="center">{value}</MDBox>}
                onChange={async (event) => {
                    event.preventDefault()
                    await reduxDispatch(setPagination({
                        currentPage: 1
                    }))
                    await reduxDispatch(setSelectPerPage(event.target.value))
                }}
            >
                {
                    listPerPage?.map((data => <MenuItem key={data} value={data}>{data}</MenuItem>))
                }
            </Select >
            <FormHelperText>
                <MDTypography
                    variant="caption">
                    {t('pagination.size_page')}
                </MDTypography>
            </FormHelperText>
        </FormControl>
    );

    return (
        <MDPagination variant="contained" size="small" >
            {generateSelectPerPage()}
            <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }} size="small">
                <MDTypography
                    sx={(theme) => ({ fontSize: theme.typography.size.sm })}
                    color="text"
                    variant="caption">
                    {t('pagination.page')} {pagination.currentPage} {t('pagination.of')} {pagination.totalPages} ({((pagination.currentPage * selectPerPage) - selectPerPage) + 1} - {((pagination.currentPage * selectPerPage) < pagination.totalItems) ? (pagination.currentPage * selectPerPage) : (pagination.totalItems)} {t('pagination.of')} {pagination.totalItems}  {t('pagination.items')})
                </MDTypography>
            </FormControl>
            <MDPagination
                alignItems="center"
                disabled={currentPage === 1}
                item
                onClick={async (event) => {
                    event.preventDefault()
                    await reduxDispatch(setPagination({
                        currentPage: 1
                    }))
                    queryPageList.refetch()
                }}>
                <Icon alignItems="center">
                    <MDBox color="text" alignItems="center">
                        first_page
                    </MDBox>
                </Icon>
            </MDPagination>
            <MDPagination
                alignItems="center"
                disabled={currentPage === 1}
                item
                onClick={async (event) => {
                    event.preventDefault()
                    await reduxDispatch(setPagination({
                        currentPage: (currentPage - 1)
                    }))
                    queryPageList.refetch()
                }}>
                <Icon alignItems="center">
                    <MDBox color="text" alignItems="center">
                        keyboard_arrow_left
                    </MDBox>
                </Icon>
            </MDPagination>
            <MDPagination
                alignItems="center"
                disabled={currentPage === pagination.totalPages}
                item
                onClick={async (event) => {
                    event.preventDefault()
                    await reduxDispatch(setPagination({
                        currentPage: (currentPage + 1)
                    }))
                    queryPageList.refetch()
                }}>
                <Icon alignItems="center">
                    <MDBox color="text" alignItems="center">
                        keyboard_arrow_right
                    </MDBox>
                </Icon>
            </MDPagination>
            <MDPagination
                alignItems="center"
                disabled={currentPage === pagination.totalPages}
                item
                onClick={async (event) => {
                    event.preventDefault()
                    await reduxDispatch(setPagination({
                        currentPage: pagination.totalPages
                    }))
                    queryPageList.refetch()
                }}>
                <Icon alignItems="center">
                    <MDBox color="text" alignItems="center">
                        last_page
                    </MDBox>
                </Icon>
            </MDPagination>
        </MDPagination>
    )
}

// Typechecking props for the Footer
Pagination.propTypes = {
    // data: PropTypes.arrayOf(PropTypes.object),
    // columns: PropTypes.arrayOf(PropTypes.object),
};

export default Pagination;