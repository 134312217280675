import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { DateTime } from 'luxon'
import 'intl-pluralrules'
import { I18N_DEBUG } from 'constraints'

import translationEN from 'locales/translation/en.json'
import translationTH from 'locales/translation/th.json'


// the translations
const resources = {
    en: {
        translation: translationEN
    },
    th: {
        translation: translationTH
    }
};

i18n
    // i18next-http-backend
    // loads translations from your server
    // https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: I18N_DEBUG,
        fallbackLng: 'th',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: true
        },
        resources
    });

// new usage
// footer: {
//     date: 'Heute ist {{date, DATE_HUGE}}',
//     date_morning: 'Guten Morgen! Heute ist {{date, DATE_HUGE}} | Wünsche einen schönen Tag!',
//     date_afternoon: 'Guten Tag! Es ist {{date, DATE_HUGE}}',
//     date_evening: 'Guten Abend! Heute war {{date, DATE_HUGE}}'
//   }
i18n.services.formatter.add('CUSTOM_DATETIME_FULL', (value, lng, options) => {
    return DateTime.fromJSDate(value).setLocale(lng).toFormat('yyyy-LL-dd HH:mm:ss')//.toLocaleString(DateTime.DATETIME_SHORT)
});

export default i18n;