/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

import { useTranslation } from "react-i18next";


// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';

import AccountBoxIcon from '@mui/icons-material/AccountBox';

import { useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod'
import {
  ArrowCircleLeft as ArrowCircleLeftIcon,
  Save as SaveIcon,
} from '@mui/icons-material';


// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import {
  FormInputField,
  FormSelectField,
  FormSwitchBoxField
} from "components/HRComponents/FormField";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  useCreateMutation,
} from "states/services/user";
import {
  setShowMessageBox,
  setActionView,
  setPagination,
  getCompanyList,
  setContentMessageBox,
  setOpenDialogCreate,
  setOpenDialogEdit,
} from "states/features/user/userSlice";
import {
  getDisplayModalForm,
} from "states/features/configuration/configurationSlice";
import { DateTime } from "luxon";

function UserCreate({ queryUserPageList, queryCompanyAllList }) {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const listCompany = useSelector(getCompanyList);
  const displayModalForm = useSelector(getDisplayModalForm);


  // 👇 API Login Mutation
  const [create, { isLoading, isError, error, isSuccess }] = useCreateMutation();

  const schema = z.object({
    username: z.string()
      .min(5, { message: t('pages.user.data_grid.header.username') + t('pages.user.message.minLength_5') }),
    password: z.string()
      .min(8, { message: t('pages.user.data_grid.header.password') + t('pages.user.message.minLength_8') })
      .regex(new RegExp('.*[A-Z].*'), { message: "Required one uppercase" })
      .regex(new RegExp('.*[a-z].*'), { message: "Required one lowercase" })
      .regex(new RegExp('.*[0-9].*'), { message: "Required one number" })
      .regex(new RegExp('.*[`~<>?,./!@#$%^&*()\\-_+="\'|{}\\[\\];:\\\\].*'), { message: "Required one speceial charactor" }),
    confirmPassword: z.string()
      .min(5, { message: t('pages.user.data_grid.header.confirmPassword') + t('pages.user.message.minLength_5') }),
    name_th: z.string()
      .min(1, { message: t('pages.user.data_grid.header.name_th') + t('pages.user.message.is_required') }),
    name_en: z.string()
      .min(1, { message: t('pages.user.data_grid.header.name_en') + t('pages.user.message.is_required') }),
    phone: z.string()
      .min(1, { message: t('pages.user.data_grid.header.phone') + t('pages.user.message.is_required') }),
    email: z.string()
      .min(1, { message: t('pages.user.data_grid.header.email') + t('pages.user.message.is_required') })
      .email({ message: t('pages.user.data_grid.header.email') + t('pages.user.message.email') }),
    company: z.any(),
    isAdmin: z.boolean(),
    status: z.boolean()
  }).refine((data) => data.password === data.confirmPassword, {
    message: "Password doesn't match",
    path: ["confirmPassword"]
  });

  const { control, handleSubmit, watch, formState: { errors }, getValues, setValue, reset } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      username: "",
      password: "",
      confirmPassword: "",
      name_th: "",
      name_en: "",
      phone: "",
      email: "",
      company: null,
      isAdmin: false,
      status: true
    },
  })

  const onSubmit = async (data) => {
    const resCreate = await create({ ...data, companyBy: data.company?.id || null, isActive: data.status })
    if (resCreate.error) {
      await reduxDispatch(setContentMessageBox({
        //icon: 'notifications',
        openAt: DateTime.now(),
        color: 'warning',
        title: t('pages.user.create_title'),
        content: resCreate.error.data.message,
        contentEx: resCreate.error.data.messageEx
      }))
      await reduxDispatch(setShowMessageBox(true))
      return;
    }
    await reduxDispatch(setContentMessageBox({
      //icon: 'notifications',
      openAt: DateTime.now(),
      color: 'success',
      title: t('pages.user.create_title'),
      content: resCreate.data.message,
      contentEx: null
    }))
    reduxDispatch(setShowMessageBox(true))
    reset()
    //reduxDispatch(setActionView("LIST"))
    if (displayModalForm) {
      await reduxDispatch(setOpenDialogCreate(false));
    } else {
      await reduxDispatch(setActionView("LIST"))
    }
    reduxDispatch(setPagination({ currentPage: 1 }))
    queryUserPageList.refetch()
    queryCompanyAllList.refetch()
  }

  //console.log(watch("example")); // you can watch individual input by pass the name of the input
  //console.log("errors=============>", errors);


  useEffect(() => {
  }, []);

  // EVENT END

  return (
    <MDBox py={3}>
      <Card sx={{ width: "100%" }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="info"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              <AccountBoxIcon />
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            {t('pages.user.create_title')}
          </MDTypography>
        </MDBox>
        <MDBox p={2} >
          <Card id="basic-info" >
            <MDBox p={2}>
              {/* <MDTypography variant="h5">Create new user</MDTypography> */}
            </MDBox>
            <MDBox pb={3} px={3}
            >
              <form onSubmit={handleSubmit(onSubmit)} fullWidth>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={6} >
                    <FormInputField
                      InputLabelProps={{ shrink: true }}
                      label={t('pages.user.data_grid.header.username')}
                      name="username"
                      control={control}
                      errors={errors}
                      placeholder="99999"
                      type="search"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <FormSelectField
                      InputLabelProps={{ shrink: true }}
                      label={t('pages.user.data_grid.header.companyBy')}
                      name="company"
                      getValues={getValues}
                      setValue={setValue}
                      control={control}
                      options={listCompany}
                      errors={errors}
                      placeholder="Company" />
                  </Grid>
                </Grid>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={6} >
                    <FormInputField
                      InputLabelProps={{ shrink: true }}
                      label={t('pages.user.data_grid.header.name_th')}
                      name="name_th"
                      control={control}
                      errors={errors}
                      placeholder="บริษัท ตัวอย่าง จํากัด (มหาชน)"
                      type="search"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <FormInputField
                      InputLabelProps={{ shrink: true }}
                      label={t('pages.user.data_grid.header.name_en')}
                      name="name_en"
                      control={control}
                      errors={errors}
                      placeholder="Sample Public User Limited"
                      type="search"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={6} >
                    <FormInputField
                      InputLabelProps={{ shrink: true }}
                      label={t('pages.user.data_grid.header.phone')}
                      name="phone"
                      control={control}
                      errors={errors}
                      placeholder="99-9999-9999"
                      type="search"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <FormInputField
                      InputLabelProps={{ shrink: true }}
                      label={t('pages.user.data_grid.header.email')}
                      name="email"
                      control={control}
                      errors={errors}
                      placeholder="xxxxxxx@xxxxx.com"
                      type="search"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} >
                  <Grid item xs={6} sm={6} >
                    <FormInputField
                      InputLabelProps={{ shrink: true }}
                      label={t('pages.user.data_grid.header.password')}
                      name="password"
                      control={control}
                      errors={errors}
                      placeholder="**********"
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} >
                    <FormInputField
                      InputLabelProps={{ shrink: true }}
                      label={t('pages.user.data_grid.header.confirmPassword')}
                      name="confirmPassword"
                      control={control}
                      errors={errors}
                      placeholder="**********"
                      type="password"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} >
                  <Grid item xs={2} sm={2} >
                    <FormSwitchBoxField
                      label={t('pages.user.data_grid.header.isAdmin')}
                      activeLable={t('pages.user.label_admin')}
                      inactiveLable={t('pages.user.label_user')}
                      name="isAdmin"
                      getValues={getValues}
                      control={control}
                      errors={errors}
                      message="Status err"
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} >
                    <FormSwitchBoxField
                      label={t('pages.user.data_grid.header.isActive')}
                      activeLable={t('pages.user.label_active')}
                      inactiveLable={t('pages.user.label_inactive')}
                      name="status"
                      getValues={getValues}
                      control={control}
                      errors={errors}
                      message="Status err"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={12} >
                    <MDBox
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      width={{ xs: "100%", sm: "auto" }}>
                      <MDBox sx={{ position: 'relative' }}>
                        <MDButton
                          sx={{ width: { xl: "130px", lg: "130px", md: "130px", sm: "auto", xs: "auto" } }}
                          type="submit"
                          disabled={isLoading}
                          variant="gradient"
                          color="info">
                          <SaveIcon />&nbsp; {t('pages.user.action.label_save')}
                        </MDButton>
                        {isLoading && (
                          <CircularProgress
                            size={28}
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        )}
                      </MDBox>
                      <MDBox ml={1}>
                        <MDButton
                          sx={{ width: { xl: "130px", lg: "130px", md: "130px", sm: "auto", xs: "auto" } }}
                          variant="outlined"
                          color="info"
                          onClick={async (event) => {
                            event.preventDefault()
                            reset()
                            //await reduxDispatch(setActionView("LIST"))
                            if (displayModalForm) {
                              await reduxDispatch(setOpenDialogCreate(false));
                            } else {
                              await reduxDispatch(setActionView("LIST"))
                            }
                          }}>
                          <ArrowCircleLeftIcon />&nbsp; {t('pages.user.action.label_cancel')}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </form>
            </MDBox>
          </Card>
        </MDBox>
      </Card>
    </MDBox>
  );
}

export default UserCreate;
