/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

import { useTranslation } from "react-i18next";


// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import TokenIcon from '@mui/icons-material/Token';

import { useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod'
import {
  ArrowCircleLeft as ArrowCircleLeftIcon,
  Save as SaveIcon,
} from '@mui/icons-material';


// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDDatePicker from "components/MDDatePicker";

import {
  FormInputField,
  FormSelectField,
  FormSwitchBoxField,
  FormDateTimePickerField,
} from "components/HRComponents/FormField";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  useCreateMutation,
} from "states/services/refreshToken";
import {
  setShowMessageBox,
  setActionView,
  setPagination,
  setContentMessageBox,
  getUserList,
  setOpenDialogCreate,
  setOpenDialogEdit,
} from "states/features/refreshToken/refreshTokenSlice";
import {
  getDisplayModalForm,
} from "states/features/configuration/configurationSlice";
import { DateTime } from "luxon";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

function RefreshTokenCreate({ queryRefreshTokenPageList, queryUserLis }) {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const displayModalForm = useSelector(getDisplayModalForm);


  const listUser = useSelector(getUserList);

  // 👇 API Login Mutation
  const [create, { isLoading, isError, error, isSuccess }] = useCreateMutation();


  const schema = z.object({
    token: z.string()
      .min(1, { message: t('pages.refreshToken.data_grid.header.token') + t('pages.refreshToken.message.is_required') }),
    expireAt: z.any(),
    userAccount: z.object({ id: z.string(), label: z.string() }),
    status: z.boolean()
  });

  const { control, handleSubmit, watch, formState: { errors }, getValues, setValue, reset } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      token: "",
      userAccount: null,
      expireAt: DateTime.utc(),
      status: true
    },
  })

  const onSubmit = async (data) => {
    const resCreate = await create({ ...data, userAccountBy: data.userAccount?.id || null, isActive: data.status })
    if (resCreate.error) {
      await reduxDispatch(setContentMessageBox({
        //icon: 'notifications',
        openAt: DateTime.now(),
        color: 'warning',
        title: t('pages.refreshToken.create_title'),
        content: resCreate.error.data.message,
        contentEx: resCreate.error.data.messageEx
      }))
      await reduxDispatch(setShowMessageBox(true))
      return;
    }
    await reduxDispatch(setContentMessageBox({
      //icon: 'notifications',
      openAt: DateTime.now(),
      color: 'success',
      title: t('pages.refreshToken.create_title'),
      content: resCreate.data.message,
      contentEx: null
    }))
    reduxDispatch(setShowMessageBox(true))
    reset()
    //reduxDispatch(setActionView("LIST"))
    if (displayModalForm) {
      await reduxDispatch(setOpenDialogCreate(false));
    } else {
      await reduxDispatch(setActionView("LIST"))
    }
    reduxDispatch(setPagination({ currentPage: 1 }))
    queryRefreshTokenPageList.refetch()
    queryUserLis.refetch()
  }

  //console.log(watch("expireAt")); // you can watch individual input by pass the name of the input
  //console.log("errors=============>", errors);


  useEffect(() => {
  }, []);

  // EVENT END

  return (
    <MDBox py={3}>
      <Card sx={{ width: "100%" }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="info"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              <TokenIcon />
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            {t('pages.refreshToken.create_title')}
          </MDTypography>
        </MDBox>
        <MDBox p={2} >
          <Card id="basic-info" >
            <MDBox p={2}>
              {/* <MDTypography variant="h5">Create new refreshToken</MDTypography> */}
            </MDBox>
            <MDBox pb={3} px={3}
            >
              <form onSubmit={handleSubmit(onSubmit)} fullWidth>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={6} >
                    <FormInputField
                      InputLabelProps={{ shrink: true }}
                      label={t('pages.refreshToken.data_grid.header.token')}
                      name="token"
                      control={control}
                      errors={errors}
                      placeholder="99999"
                      type="search"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <FormDateTimePickerField
                      name="expireAt"
                      label="Basic date time picker"
                      control={control}
                      errors={errors}
                    //getValues={getValues}
                    //setValue={setValue}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={12} >
                    <FormSelectField
                      InputLabelProps={{ shrink: true }}
                      label={t('pages.refreshToken.data_grid.header.userAccountBy')}
                      name="userAccount"
                      getValues={getValues}
                      setValue={setValue}
                      control={control}
                      options={listUser}
                      errors={errors}
                      placeholder="User Account" />
                  </Grid>
                </Grid>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={12} >
                    <FormSwitchBoxField
                      label={t('pages.refreshToken.data_grid.header.isActive')}
                      activeLable={t('pages.refreshToken.label_active')}
                      inactiveLable={t('pages.refreshToken.label_inactive')}
                      name="status"
                      getValues={getValues}
                      control={control}
                      errors={errors}
                      message="Status err"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={12} >
                    <MDBox
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      width={{ xs: "100%", sm: "auto" }}>
                      <MDBox sx={{ position: 'relative' }}>
                        <MDButton
                          sx={{ width: { xl: "130px", lg: "130px", md: "130px", sm: "auto", xs: "auto" } }}
                          type="submit"
                          disabled={isLoading}
                          variant="gradient"
                          color="info">
                          <SaveIcon />&nbsp; {t('pages.refreshToken.action.label_save')}
                        </MDButton>
                        {isLoading && (
                          <CircularProgress
                            size={28}
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        )}
                      </MDBox>
                      <MDBox ml={1}>
                        <MDButton
                          sx={{ width: { xl: "130px", lg: "130px", md: "130px", sm: "auto", xs: "auto" } }}
                          variant="outlined"
                          color="info"
                          onClick={async (event) => {
                            event.preventDefault()
                            reset()
                            //reduxDispatch(setActionView("LIST"))
                            if (displayModalForm) {
                              await reduxDispatch(setOpenDialogCreate(false));
                            } else {
                              await reduxDispatch(setActionView("LIST"))
                            }
                          }}>
                          <ArrowCircleLeftIcon />&nbsp; {t('pages.refreshToken.action.label_cancel')}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </form>
            </MDBox>
          </Card>
        </MDBox>
      </Card>
    </MDBox>
  );
}

export default RefreshTokenCreate;
