import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    configurationDisplayModalForm: true,
    geoLocation: null,
};

export const configurationSlice = createSlice({
    name: 'configuration',
    initialState: initialState,
    reducers: {
        setDisplayModalForm: (state, action) => {
            state.configurationDisplayModalForm = action.payload;
        },
        setGeoLocation: (state, action) => {
            state.geoLocation = action.payload;
        },
    },
})

export const { setDisplayModalForm } = configurationSlice.actions
export const { setGeoLocation } = configurationSlice.actions

export const getDisplayModalForm = (state) => state.configuration.configurationDisplayModalForm
export const getGeoLocation = (state) => state.configuration.geoLocation

export default configurationSlice.reducer