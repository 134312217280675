/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

import { Controller } from "react-hook-form";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import { darken } from '@mui/material';


function FormSelectField({ name, control, rules, errors, getValues, setValue, groupBy, ...rest }) {
  return (
    <MDBox mb={1.5} mt={.6}>
      <Controller
        name={name}
        control={control}
        render={({ field }) =>
          <Autocomplete
            renderGroup={(params) => (
              <MDBox key={params.key}>
                <MDTypography fontSize={16} fontStyle="normal" p={1}>
                  {params.group}
                </MDTypography>
                {params.children}
              </MDBox>
            )}
            size="small"
            options={rest.options}
            onChange={(event, item) => {
              setValue(name, item);
            }}
            groupBy={groupBy}
            value={getValues(name)}
            renderInput={params => (
              <MDInput
                {...params}
                InputLabelProps={rest.InputLabelProps}
                label={rest.label}
                fullWidth
              />
            )}
          />}
      />
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          {errors[name] && <span>{errors[name].message || 'This is error!'}</span>}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for FormField
FormSelectField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired
};

export default FormSelectField;
