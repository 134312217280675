import { useState, useMemo, useEffect, useRef, useCallback } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import { createTheme, ThemeProvider, useTheme } from '@mui/material';
// import CssBaseline from "@mui/material/CssBaseline";
// import theme from "assets/theme";
// import themedark from "assets/theme-dark";

import { Paper, Grid } from "@mui/material";
import { darken, lighten } from "@mui/material";
import { IconButton } from "@mui/material";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";

// MaterialReactTable
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// MY
import fontIcons from "components/HRComponents/DataGrid.v2/FontIcons";
import Pagination from "components/HRComponents/DataGrid.v2/Pagination";

export const DataGrid = ({
  columns,
  data,
  initialState,
  state,
  onNewEvent,
  renderTopToolbarCustomActions,
  getSelectPerPage,
  getListPerPage,
  getPagination,
  setPagination,
  setSelectPerPage,
  queryPageList,
}) => {
  const table = useMaterialReactTable({
    columns,
    data,
    icons: fontIcons,
    layoutMode: "grid",
    enableFullScreenToggle: false,
    enableSorting: false, //disable sorting for all columns
    //columnFilterDisplayMode: 'popover',
    //enableHiding={true}
    enablePagination: false,
    manualPagination: true,
    enableFilters: false,
    enableColumnFilters: false,
    initialState: initialState,
    state: state,
    muiTableContainerProps: {
      sx: (theme) => ({
        elevation: 10, //change the mui box shadow
        borderRadius: "0",
        backgroundColor: darken(theme.palette.background.default, 0.05),
      }),
    },
    muiTableHeadProps: {
      sx: (theme) => ({
        // backgroundColor: darken(theme.palette.background.default, 0.2),
        // fontSize: theme.typography.size.xs,
        // fontWeight: theme.typography.fontWeightBold,
        // textTransform: "uppercase",
        // color: "text",
      }),
    },
    muiTablePaperProps: {
      elevation: 20, //change the mui box shadow
      //customize paper styles
      sx: (theme) => ({
        borderRadius: "0",
        backgroundColor: darken(theme.palette.background.default, 0.01),
        color: "text",
      }),
    },

    muiTableHeadCellProps: {
      sx: (theme) => ({
        backgroundColor: darken(theme.palette.background.default, 0.3),
        fontSize: theme.typography.size.xs,
        fontWeight: theme.typography.fontWeightBold,
        color: "text",
        textTransform: "uppercase",
      }),
    },
    muiTableBodyProps: {
      //elevation: 10, //change the mui box shadow
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: darken(theme.palette.background.default, 0.1),
          },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: darken(theme.palette.background.default, 0.2),
          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: lighten(theme.palette.background.default, 0.1),
          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: darken(theme.palette.background.default, 0.2),
          },
        mx: 2,
        mb: 2,
      }),
    },
    muiToolbarAlertBannerProps: {
      sx: (theme) => ({
        fontSize: theme.typography.size.sm,
      }),
    },
    renderTopToolbarCustomActions: ({ table }) => {
      return (
        <Grid container spacing={1}>
          {onNewEvent && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={1} xxl={1} ml={-1}>
              <IconButton color="info" fontSize="inherit" onClick={onNewEvent}>
                <AddCircleIcon />
              </IconButton>
            </Grid>
          )}
          {renderTopToolbarCustomActions}
        </Grid>
      );
    },
    renderBottomToolbar: () => (
      <MDBox justifyContent="center" alignItems="center" color="text" p={2}>
        <Pagination
          getSelectPerPage={getSelectPerPage}
          getListPerPage={getListPerPage}
          getPagination={getPagination}
          setPagination={setPagination}
          setSelectPerPage={setSelectPerPage}
          queryPageList={queryPageList}
        />
      </MDBox>
    ),
  });

  return <MaterialReactTable table={table} component={Paper} />;
};
