/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useMemo, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";

// @mui material components
import { IconButton } from "@mui/material";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";

import {
  Edit as EditIcon,
  EditOff as EditOffIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  ChangeCircle as ChangeCircleIcon,
  RestoreFromTrash as RestoreFromTrashIcon,
  Sync as SyncIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import DoneIcon from "@mui/icons-material/Done";
import PersonIcon from "@mui/icons-material/Person";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setActionView,
  setPagination,
  setSelectPerPage,
  setSearch,
  setStatus,
  setShowDelete,
  setSelectRow,
  getListPage,
  getPagination,
  getSelectPerPage,
  getListPerPage,
  getSearch,
  getStatus,
  getShowDelete,
  setContentMessageBox,
  setShowMessageBox,
  getOpenDialog,
  setOpenDialog,
  setRoleListPage,
  setUserSelectRow,
  getRoleSelectRow,
  getRoleListPage,
  setRolePagination,
  getRolePagination,
  getRoleSelectPerPage,
  getRoleSearch,
  setRoleSearch,
  getRoleStatus,
  setRoleStatus,
  getRoleShowDelete,
  setRoleShowDelete,
  setUserListPage,
  setUserPagination,
  getUserPagination,
  getUserSelectPerPage,
  getUserSearch,
  getUserStatus,
  getUserShowDelete,
  getRoleListPerPage,
  setRoleSelectPerPage,
  getUserListPage,
  getUserSelectRow,
  setUserShowDelete,
  setUserStatus,
  setUserSearch,
  setUserSelectPerPage,
  getUserListPerPage,
} from "states/features/privileges/privilegesSlice";
import {
  useGetByIdQuery,
  useGetQuery,
  useGetPageQuery,
  useCreateMutation,
  useUpdateMutation,
  useEditMutation,
  useDestroyMutation,
  useRestoreMutation,
} from "states/services/privileges";

// MY
//import DataGrid from "components/HRComponents/DataGrid";
import { DataGrid } from "components/HRComponents/DataGrid.v2";
import { DateTime } from "luxon";
import MDButton from "components/MDButton";

function PrivilegesListUser({
  queryPrivilegesPageList,
  queryPrivilegesRolePageList,
  queryPrivilegesUserPageList,
  queryPrivilegesByRoleList,
  queryPrivilegesByUserList,
}) {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const [textSearch, setTextSearch] = useState("");

  // 👇 API Login Mutation

  const search = useSelector(getUserSearch);
  const status = useSelector(getUserStatus);
  const showDelete = useSelector(getUserShowDelete);

  const userListPagePrivileges = useSelector(getUserListPage);
  const userSelectRow = useSelector(getUserSelectRow);

  const columnsUser = useMemo(
    () => [
      {
        accessorKey: "id", //access nested data with dot notation
        header: t("pages.user.data_grid.header.id"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: "username",
        header: t("pages.user.data_grid.header.username"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: "name_th",
        header: t("pages.user.data_grid.header.name_th"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: "name_en",
        header: t("pages.user.data_grid.header.name_en"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: "phone",
        header: t("pages.user.data_grid.header.phone"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: "email",
        header: t("pages.user.data_grid.header.email"),
        isVisibility: false,
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: "userOfCompanyBy",
        header: t("pages.user.data_grid.header.companyBy"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()
              ? "[ " +
                cell.getValue().code +
                " ] : " +
                cell.getValue()["name_" + i18n.resolvedLanguage]
              : ""}
          </MDTypography>
        ),
      },
      {
        accessorKey: "lastChangePassword",
        header: t("pages.user.data_grid.header.lastChangePassword"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()
              ? t("pages.user.data_grid.detail.lastChangePassword", {
                  date: new Date(cell.getValue()),
                  lng: i18n.resolvedLanguage,
                })
              : ""}
          </MDTypography>
        ),
      },
      {
        accessorKey: "isAdmin",
        id: "isAdmin",
        //accessorFn: (originalRow) => (originalRow.isActive ? 'true' : 'false'), //must be strings
        header: t("pages.user.data_grid.header.isAdmin"),
        //filterVariant: 'checkbox',
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.lg,
            })}
          >
            {cell.getValue() ? (
              <Tooltip
                title={t("pages.user.data_grid.tooltip.label_admin")}
                placement="top"
              >
                <AdminPanelSettingsIcon color="success" />{" "}
              </Tooltip>
            ) : (
              <Tooltip
                title={t("pages.user.data_grid.tooltip.label_user")}
                placement="top"
              >
                <PersonIcon color="info" />{" "}
              </Tooltip>
            )}
          </MDTypography>
        ),
      },
      {
        accessorKey: "isActive",
        id: "isActive",
        //accessorFn: (originalRow) => (originalRow.isActive ? 'true' : 'false'), //must be strings
        header: t("pages.user.data_grid.header.isActive"),
        //filterVariant: 'checkbox',
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.lg,
            })}
          >
            {cell.getValue() ? (
              <Tooltip
                title={t("pages.user.data_grid.tooltip.label_active")}
                placement="top"
              >
                <CheckCircleIcon />{" "}
              </Tooltip>
            ) : (
              <Tooltip
                title={t("pages.user.data_grid.tooltip.label_inactive")}
                placement="top"
              >
                <CancelIcon />{" "}
              </Tooltip>
            )}
          </MDTypography>
        ),
      },
      {
        accessorKey: "version",
        header: t("pages.user.data_grid.header.version"),
        size: 20,
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: "userOfCreatedBy",
        header: t("pages.user.data_grid.header.createdBy"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()
              ? "[ " +
                cell.getValue().username +
                " ] : " +
                cell.getValue()["name_" + i18n.resolvedLanguage]
              : ""}
          </MDTypography>
        ),
      },
      {
        accessorKey: "userOfUpdatedBy",
        header: t("pages.user.data_grid.header.updatedBy"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()
              ? "[ " +
                cell.getValue().username +
                " ] : " +
                cell.getValue()["name_" + i18n.resolvedLanguage]
              : ""}
          </MDTypography>
        ),
      },
      {
        accessorKey: "createdAt",
        header: t("pages.user.data_grid.header.createdAt"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()
              ? t("pages.user.data_grid.detail.createdAt", {
                  date: new Date(cell.getValue()),
                  lng: i18n.resolvedLanguage,
                })
              : ""}
          </MDTypography>
        ),
      },
      {
        accessorKey: "updatedAt",
        header: t("pages.user.data_grid.header.updatedAt"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()
              ? t("pages.user.data_grid.detail.updatedAt", {
                  date: new Date(cell.getValue()),
                  lng: i18n.resolvedLanguage,
                })
              : ""}
          </MDTypography>
        ),
      },
      {
        accessorKey: "deletedAt",
        header: t("pages.user.data_grid.header.deletedAt"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()
              ? t("pages.user.data_grid.detail.deletedAt", {
                  date: new Date(cell.getValue()),
                  lng: i18n.resolvedLanguage,
                })
              : ""}
          </MDTypography>
        ),
      },
      {
        id: "action",
        header: t("pages.user.data_grid.header.action"),
        columnDefType: "display", //turns off data column features like sorting, filtering, etc.
        enableColumnOrdering: true, //but you can turn back any of those features on if you want like this
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              justifyContent: "center",
              alignItems: "center",
              color: "text",
              textTransform: "uppercase",
              pt: 0.3,
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ row }) => (
          <MDBox justifyContent="center" alignItems="center" color="text">
            <Tooltip
              title={t("pages.user.data_grid.action.label_edit")}
              placement="top"
            >
              <IconButton
                disabled={row.original.deletedAt}
                fontSize="inherit"
                onClick={(event) => {
                  event.preventDefault();
                  reduxDispatch(setUserSelectRow(row.original));
                }}
              >
                {userSelectRow?.id !== row.original.id && (
                  <RemoveDoneIcon color="text" />
                )}
                {userSelectRow?.id === row.original.id && (
                  <DoneIcon color="info" />
                )}
              </IconButton>
            </Tooltip>
          </MDBox>
        ),
      },
    ],
    [i18n.resolvedLanguage, reduxDispatch, userSelectRow, t]
  );

  useEffect(() => {
    setTextSearch(search);
  }, [search]);

  return (
    <DataGrid
      data={userListPagePrivileges?.result || []}
      columns={columnsUser}
      // onNewEvent={(event) => {
      //   event.preventDefault()
      //   reduxDispatch(setActionView("CREATE"))
      // }}
      //onGlobalFilterChange={setGlobalFilter}
      getSelectPerPage={getUserSelectPerPage}
      getListPerPage={getUserListPerPage}
      getPagination={getUserPagination}
      setPagination={setUserPagination}
      setSelectPerPage={setUserSelectPerPage}
      queryPageList={queryPrivilegesUserPageList}
      initialState={{
        density: "compact",
        columnVisibility: {
          id: false,
          name_th: i18n.resolvedLanguage === "th",
          name_en: i18n.resolvedLanguage === "en",
          email: false,
          phone: false,
          lastChangePassword: false,
          isAdmin: false,
          companyBy: false,
          userOfCompanyBy: false,
          privilegeInToUserAccountBy: false,
          isActive: false,
          version: false,
          createdAt: false,
          updatedAt: false,
          deletedAt: false,
          createdBy: false,
          userOfCreatedBy: false,
          updatedBy: false,
          userOfUpdatedBy: false,
        },
      }}
      state={{
        showProgressBars: queryPrivilegesUserPageList.isFetching,
        isLoading: queryPrivilegesUserPageList.isLoading,
      }}
      renderTopToolbarCustomActions={
        <>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10} xxl={10} mt={0.5}>
            <MDInput
              sx={{ width: "100%" }}
              size="small"
              id="search_text"
              type="search"
              InputLabelProps={{ shrink: true }}
              value={textSearch}
              onChange={(event) => {
                event.preventDefault();
                setTextSearch(event.target.value);
              }}
              onKeyPress={(event) => {
                //event.preventDefault()
                if (event.key === "Enter") {
                  reduxDispatch(setUserPagination({ currentPage: 1 }));
                  reduxDispatch(setUserSearch(textSearch));
                }
              }}
              onBlur={() => {
                reduxDispatch(setUserPagination({ currentPage: 1 }));
                reduxDispatch(setUserSearch(textSearch));
              }}
              label={t("pages.user.label_search")}
            />
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            xxl={2}
            ml={{ xxl: "-1.5%" }}
            mt={0.5}
          >
            <MDButton
              size="small"
              variant="standart"
              iconOnly={true}
              onClick={(event) => {
                event.preventDefault();
                reduxDispatch(setUserPagination({ currentPage: 1 }));
                queryPrivilegesPageList.refetch();
                queryPrivilegesRolePageList.refetch();
                queryPrivilegesUserPageList.refetch();
              }}
            >
              <Icon variant="button" fontSize="small" color="info">
                <SyncIcon />
              </Icon>
            </MDButton>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            mr={{ md: -1, lg: -1, xl: -1, xxl: -1 }}
            mt={0.3}
          >
            <ToggleButtonGroup
              width={{
                xs: "auto",
                sm: "auto",
                md: "auto",
                lg: "auto",
                xl: "auto",
                xxl: "auto",
              }}
              color="info"
              size="small"
              value={status}
              exclusive
              onChange={(event, newAlignment) => {
                event.preventDefault();
                reduxDispatch(setUserPagination({ currentPage: 1 }));
                reduxDispatch(setUserStatus(newAlignment));
              }}
              aria-label={t("pages.user.data_grid.header.isActive")}
            >
              <ToggleButton value={null}>
                <MDBox color="text">{t("pages.user.label_all")}</MDBox>
              </ToggleButton>
              <ToggleButton value={true}>
                <MDBox color="text">{t("pages.user.label_active")}</MDBox>
              </ToggleButton>
              <ToggleButton value={false}>
                <MDBox color="text">{t("pages.user.label_inactive")}</MDBox>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            ml={{ xs: -1, sm: -1 }}
            mt={0.3}
          >
            <MDBox
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              width={{
                xs: "auto",
                sm: "auto",
                md: "auto",
                lg: "auto",
                xl: "auto",
                xxl: "auto",
              }}
            >
              <MDBox>
                <Switch
                  variant="standard"
                  checked={showDelete}
                  onChange={(event) => {
                    event.preventDefault();
                    reduxDispatch(setUserPagination({ currentPage: 1 }));
                    reduxDispatch(setUserShowDelete(!showDelete));
                  }}
                />
              </MDBox>
              <MDBox lineHeight={0}>
                <MDTypography variant="button" color="text">
                  {showDelete
                    ? t("pages.user.label_show_deleted")
                    : t("pages.user.label_hide_deleted")}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
        </>
      }
    />
  );
}

export default PrivilegesListUser;
