/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import { darken } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from "@mui/material/Tooltip";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
function ModalDisplayDetail({ title, closeLabel, content, openDialog, handleClose, dialogRef }) {
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={openDialog}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      {
        <DialogTitle
          id="scroll-dialog-title"
          sx={(theme) => ({
            borderRadius: '0',
            backgroundColor: darken(theme.palette.background.default, 0),
            color: 'text',
            pb: 0
          })} >
          {title}
        </DialogTitle>
      }
      <DialogContent
        dividers={(title)}
        sx={(theme) => ({
          borderRadius: '0',
          backgroundColor: darken(theme.palette.background.default, 0),
          color: 'text',
          pb: 0,
        })}
      >
        <DialogContentText
          sx={(theme) => ({
            borderRadius: '0',
            backgroundColor: darken(theme.palette.background.default, 0),
            color: 'text',
            pb: 0,
            width: '100%',
          })}
          id="scroll-dialog-description"
          ref={dialogRef}
          tabIndex={-1}
        >
          <MDBox pt={0} pb={0} px={0}>
            <MDBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              m={0}
              sx={{ listStyle: "none" }}
            >
              {content}
            </MDBox>
          </MDBox>
        </DialogContentText>
      </DialogContent>

      {
        (handleClose) &&
        <DialogActions
          sx={(theme) => ({
            borderRadius: '0',
            backgroundColor: darken(theme.palette.background.default, 0),
            color: 'text',
            pb: 2
          })}
        >
          <Button onClick={handleClose}>{closeLabel}</Button>
        </DialogActions>
      }
    </Dialog>
  );
}

// Typechecking props of the Transaction
ModalDisplayDetail.propTypes = {
  //title: PropTypes.string.isRequired,
  //closeLabel: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
};

export default ModalDisplayDetail;
