/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useMemo, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";

// @mui material components
import { IconButton } from "@mui/material";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";

import {
  Edit as EditIcon,
  EditOff as EditOffIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  ChangeCircle as ChangeCircleIcon,
  RestoreFromTrash as RestoreFromTrashIcon,
  Sync as SyncIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import DoneIcon from "@mui/icons-material/Done";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setModuleList,
  setRoleList,
  setRoleSearch,
  setRoleActiveOnly,
  setRoleStatus,
  setRoleShowDelete,
  setRoleListPage,
  setRoleSelectRow,
  setRolePagination,
  setRoleSelectPerPage,
  setRoleListPerPage,
  setListPage,
  setSelectRow,
  setActionView,
  setOpenDialogInfo,
  setOpenDialogCreate,
  setOpenDialogEdit,
  setPagination,
  setSelectPerPage,
  setListPerPage,
  setSearch,
  setActiveOnly,
  setStatus,
  setShowDelete,
  setShowMessageBox,
  setContentMessageBox,
  getModuleList,
  getRoleList,
  getRoleSearch,
  getRoleActiveOnly,
  getRoleStatus,
  getRoleShowDelete,
  getRoleListPage,
  getRoleSelectRow,
  getRolePagination,
  getRoleSelectPerPage,
  getRoleListPerPage,
  getListPage,
  getSelectRow,
  getActionView,
  getOpenDialogInfo,
  getOpenDialogCreate,
  getOpenDialogEdit,
  getPagination,
  getSelectPerPage,
  getListPerPage,
  getSearch,
  getActiveOnly,
  getStatus,
  getShowDelete,
  getShowMessageBox,
  getContentMessageBox,
  getListByRole,
} from "states/features/permission/permissionSlice";
import {
  useGetByIdQuery,
  useGetQuery,
  useGetPageQuery,
  useCreateMutation,
  useUpdateMutation,
  useEditMutation,
  useDestroyMutation,
  useRestoreMutation,
  useAssignMutation,
} from "states/services/permission";

// MY
import { DateTime } from "luxon";
import MDButton from "components/MDButton";
import GrantList from "pages/security/authorization/permission/components/PermissionListGrant/GrantList";

function PermissionListGrant({
  queryPermissionPageList,
  queryPermissionRolePageList,
  queryPermissionByRoleList,
}) {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const [textSearch, setTextSearch] = useState("");

  // 👇 API Login Mutation
  const [assign, { isLoading, isError, error, isSuccess }] =
    useAssignMutation();

  const listByRole = useSelector(getListByRole);
  const search = useSelector(getRoleSearch);
  const status = useSelector(getRoleStatus);
  const showDelete = useSelector(getRoleShowDelete);

  const roleSelectRow = useSelector(getRoleSelectRow);

  useEffect(() => {
    //console.log(listByRole);
  }, [queryPermissionByRoleList?.result, search]);

  const onSavePermission = async (data, newLevel) => {
    const resAssign = await assign({ data: data, dataAssign: newLevel});
    //console.log("onSavePermission resAssign ==========>", resAssign);
    await queryPermissionPageList.refetch()
    await queryPermissionRolePageList.refetch()
    await queryPermissionByRoleList.refetch()

    //queryPermissionRolePageList.refetch()
    //queryPermissionByRoleList.refetch()
    //console.log("onSavePermission data ==========>", data);
    //console.log("onSavePermission newLevel ==========>", newLevel);
    //console.log("onSavePermission grant ==========>", grantList.at(newLevel.level === -1 ? 0 : newLevel.level));
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} mt={0.5}>
        <MDInput
          id="role_name_th"
          type="text"
          size="small"
          InputProps={{
            readOnly: true,
          }}
          fullWidth
          value={roleSelectRow?.name_th}
          InputLabelProps={{ shrink: true }}
          label={t("pages.permission.label_role_name_th")}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} mt={0.5}>
        <MDInput
          id="role_name_en"
          type="text"
          size="small"
          InputProps={{
            readOnly: true,
          }}
          fullWidth
          value={roleSelectRow?.name_en}
          InputLabelProps={{ shrink: true }}
          label={t("pages.permission.label_role_name_en")}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} mt={0.5}>
        <GrantList
          listByRole={listByRole}
          onSavePermission={onSavePermission}
        />
      </Grid>
    </Grid>
  );
}

export default PermissionListGrant;

// id
//     roleBy
//     moduleBy
//     grant
//     level
//     system_code
//     system_name_th
//     system_name_en
//     function_name_th
//     function_name_en
//     module_name_th
//     module_name_en
//     role_name_th
//     role_name_en
//     isActive
//     version
//     createdAt
//     updatedAt
//     deletedAt
//     createdBy
//     updatedBy
