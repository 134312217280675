
import { useState, useMemo, useEffect, useRef } from "react";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useTranslation } from "react-i18next";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setShowMessageBox,
  setListPage,
  setActionView,
  setCompanyList,
  setPagination,
  getOpenDialogInfo,
  setOpenDialogInfo,
  getOpenDialogCreate,
  setOpenDialogCreate,
  getOpenDialogEdit,
  setOpenDialogEdit,
  getActionView,
  getPagination,
  getSelectPerPage,
  getCompanySelect,
  getUsername,
  getSearch,
  getStatus,
  getShowDelete,
  getShowMessageBox,
  getContentMessageBox,
  setPermissionList,
  getSelectRow,
} from "states/features/user/userSlice";

import {
  useGetCompanyQuery,
  useGetPageQuery,
  useGetPermissionQuery,
} from "states/services/user";

// MY 
import MessageNotification from "components/HRComponents/MessageNotification";

import UserList from "pages/security/account/user/components/UserList";
import UserInfo from "pages/security/account/user/components/UserInfo";
import UserRight from "pages/security/account/user/components/UserRight";
import UserCreate from "pages/security/account/user/components/UserCreate";
import UserEdit from "pages/security/account/user/components/UserEdit";
import UserEditModal from "pages/security/account/user/components/UserEditModal";
import UserCreateModal from "pages/security/account/user/components/UserCreateModal";

function User() {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const actionView = useSelector(getActionView);
  const showMessageBox = useSelector(getShowMessageBox);
  const contentMessageBox = useSelector(getContentMessageBox);
  const search = useSelector(getSearch);
  const companyBy = useSelector(getCompanySelect);
  const username = useSelector(getUsername);
  const status = useSelector(getStatus);
  const showDelete = useSelector(getShowDelete);
  const pagination = useSelector(getPagination);
  const selectPerPage = useSelector(getSelectPerPage);
  const selectRow = useSelector(getSelectRow);

  const queryuGetPermission = useGetPermissionQuery({
    username: selectRow?.username
  })

  useEffect(() => {
    if (queryuGetPermission.isSuccess) {
      reduxDispatch(setPermissionList(queryuGetPermission?.data?.result))
     
    }
  }, [queryuGetPermission?.data?.result, queryuGetPermission.isSuccess, reduxDispatch]);
  

  const queryCompanyAllList = useGetCompanyQuery(
    // {
    //   refetchOnMountOrArgChange: true,
    //   //pollingInterval: 3000
    // }
  );

  const createModalElementRef = useRef(null);
  const editModalElementRef = useRef(null);
  
  const queryUserPageList = useGetPageQuery({
    isActive: status === null ? undefined : status,
    hiddenDelete: showDelete ? false : undefined,
    textSearch: search,
    companyBy: companyBy?.id,
    username: username === '' ? undefined : username,
    page: pagination?.currentPage || 1,
    size: selectPerPage || 50,
  }
    // , {
    //   refetchOnMountOrArgChange: true,
    //   //pollingInterval: 3000
    // }
  );

 
  useEffect(() => {
    if (queryUserPageList.isSuccess) {
      reduxDispatch(setListPage(queryUserPageList?.data?.result))
      reduxDispatch(setPagination({
        totalItems: queryUserPageList?.data?.result?.totalItems,
        totalPages: queryUserPageList?.data?.result?.totalPages
      }))
    }
  }, [queryUserPageList.data?.result, queryUserPageList.isSuccess, reduxDispatch]);

  useEffect(() => {
    if (queryCompanyAllList.isSuccess) {
      let comList = [];
      queryCompanyAllList.data.result.forEach(com => {
        comList.push({ label: ' [' + com["code"] + '] ' + com["name_" + i18n.resolvedLanguage], id: com.id })
      });
      reduxDispatch(setCompanyList(comList))
    }
  }, [queryCompanyAllList.data, queryCompanyAllList.isSuccess, i18n.resolvedLanguage, reduxDispatch]);


  useEffect(() => {
    return () => {
      reduxDispatch(setOpenDialogInfo(false));
      reduxDispatch(setOpenDialogCreate(false));
      reduxDispatch(setOpenDialogEdit(false));
      reduxDispatch(setShowMessageBox(false))
      reduxDispatch(setActionView("LIST"))
    };
  }, [reduxDispatch]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {(actionView === 'LIST') && <UserList queryUserPageList={queryUserPageList} queryCompanyAllList={queryCompanyAllList} queryuGetPermission={queryuGetPermission} />}
      {(actionView === 'CREATE') && <UserCreate queryUserPageList={queryUserPageList} queryCompanyAllList={queryCompanyAllList} />}
      {(actionView === 'EDIT') && <UserEdit queryUserPageList={queryUserPageList} queryCompanyAllList={queryCompanyAllList} />}
      <Footer />
      <MessageNotification
        content={contentMessageBox}
        showMessageBox={showMessageBox}
        setShowMessageBox={setShowMessageBox}
      />
      <UserInfo />
      <UserRight />
      <UserCreateModal dialogRef={createModalElementRef} queryUserPageList={queryUserPageList} queryCompanyAllList={queryCompanyAllList}/>
      <UserEditModal dialogRef={editModalElementRef} queryUserPageList={queryUserPageList} queryCompanyAllList={queryCompanyAllList}/>
    </DashboardLayout>
  );
}

export default User;
