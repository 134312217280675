import { z } from "zod";

const ProfileModifyModel = z.object({
  name_th: z.string().nullable().optional(),
  name_en: z.string().nullable().optional(),
  description_th: z.string().nullable().optional(),
  description_en: z.string().nullable().optional(),
  phone: z.string().nullable().optional(),
  twetter: z.string().nullable().optional(),
  line: z.string().nullable().optional(),
  instragram: z.string().nullable().optional(),
  facebook: z.string().nullable().optional(),
  version: z.number().nullable().optional(),
});

export { ProfileModifyModel };
