// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import moment from 'moment';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import th from 'javascript-time-ago/locale/th.json'
import ReactTimeAgo from 'react-time-ago';

// @mui material components
import { MenuItem, FormControl, Select, TextField, FormHelperText } from "@mui/material";
import Icon from "@mui/material/Icon";
import { makeStyles } from '@mui/styles';
import { createStyles } from '@mui/material';

// Material Dashboard 2 PRO React components
import MDSnackbar from "components/MDSnackbar";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";

// redux
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
// import {
//     useGetByIdQuery,
//     useGetQuery,
//     useGetPageQuery,
//     useCreateMutation,
//     useUpdateMutation,
//     useEditMutation,
//     useDeleteMutation,
//     useRestoreMutation,
// } from "redux/services/company";
// import {
//     setListPage,
//     setSelectRow,
//     setActionView,
//     setHeadOfficeList,
//     setHeadOfficeSelect,
//     setPagination,
//     setSelectPerPage,
//     setListPerPage,
//     setCode,
//     setSearch,
//     setActiveOnly,
//     setShowDelete,
//     getListPage,
//     getSelectRow,
//     getActionView,
//     getPagination,
//     getSelectPerPage,
//     getListPerPage,
//     getHeadOfficeList,
//     getHeadOfficeSelect,
//     getCode,
//     getSearch,
//     getActiveOnly,
//     getShowDelete
// } from "redux/features/company/companySlice";

function MessageNotification({ content, showMessageBox, setShowMessageBox, ...rest }) {
    const { t, i18n } = useTranslation();
    const reduxDispatch = useDispatch();
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    //TimeAgo.addDefaultLocale(en)
    TimeAgo.addLocale(th)
    TimeAgo.addLocale(en)

    return (
        <MDSnackbar
            {...rest}
            color={content?.color || 'info'}
            icon={content?.icon || 'notifications'}
            title={content?.title || ''}
            content={content?.content || ''}
            contentEx={content?.contentEx || ''}
            dateTime={<ReactTimeAgo date={content?.openAt ? content?.openAt : DateTime.now()} locale={i18n.language} />}
            open={showMessageBox}
            close={() => reduxDispatch(setShowMessageBox(!showMessageBox))}
        />
    )
}

// Typechecking props for the Footer
MessageNotification.propTypes = {
    // data: PropTypes.arrayOf(PropTypes.object),
    // columns: PropTypes.arrayOf(PropTypes.object),
};

export default MessageNotification;