/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// redux

import { useDispatch, useSelector } from "react-redux";
import * as auth from "states/features/auth/authSlice";
import { useLoginMutation } from "states/services/authentication";

import { setGeoLocation } from "states/features/configuration/configurationSlice";

import { useGetClientInfoQuery } from "states/services/clientInfo";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDAlert from "components/MDAlert";

import {
  FormInputField,
  FormSelectField,
  FormSwitchBoxField,
} from "components/HRComponents/FormField";

// Authentication layout components
import CoverLayout from "layouts/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";

import { LoginModel } from "models";

// MY
import MessageNotification from "components/HRComponents/MessageNotification";
//import profilePicture from "assets/images/team-1.jpg";
import { DateTime } from "luxon";

function Cover() {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  // const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  // const [mfaToken, setMfaToken] = useState("");
  const [showMfaToken, setShowMfaToken] = useState(false);

  const showMessageBox = useSelector(auth.getShowMessageBox);
  const contentMessageBox = useSelector(auth.getContentMessageBox);
  //const timer = useRef(0);

  // useEffect(() => {
  //   return () => {
  //     clearTimeout(timer.current);
  //   };
  // }, []);

  const schema = z.object({
    username: z
      .string({
        required_error: "Username is required",
        invalid_type_error: "Username is required",
      })
      .min(1, {
        message:
          t("pages.user.data_grid.header.password") +
          t("pages.user.message.minLength_8"),
      }),
    password: z
      .string({
        required_error: "Password is required",
        invalid_type_error: "Password is required",
      })
      .min(8, {
        message:
          t("pages.user.data_grid.header.password") +
          t("pages.user.message.minLength_8"),
      }),
    mfaToken: z.string().nullable().optional(),
  });
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      usernaame: null,
      password: null,
      mfaToken: null,
    },
  });

  // 👇 API Login Mutation
  const [login, { isLoading, isError, error, isSuccess }] = useLoginMutation();

  const queryuseClientInfo = useGetClientInfoQuery();

  // Setting the dir attribute for the body element
  useEffect(() => {
    if (queryuseClientInfo.isSuccess) {
      reduxDispatch(setGeoLocation(queryuseClientInfo.data));
    }
  }, [queryuseClientInfo.data, queryuseClientInfo.isSuccess, reduxDispatch]);

  const onSubmit = async (data) => {
    try {
      const body = LoginModel.parse({
        username: data.username,
        password: data.password,
        mfaToken: data.mfaToken,
      });
      const resLogin = await login(body);
      //console.log("===============>",resLogin)
      if (resLogin.error) {
        //setShowSnackbar(true);
        await reduxDispatch(
          auth.setContentMessageBox({
            //icon: 'notifications',
            openAt: DateTime.now(),
            color: "warning",
            title: t("pages.signin.title"),
            content: t("pages.signin.message.signin_incorrect"),
            contentEx: resLogin.error.data.messageEx,
          })
        );
        await reduxDispatch(auth.setShowMessageBox(true));
        return;
      }

      if (resLogin.data.code === 203) {
        setShowMfaToken(true);
        await reduxDispatch(
          auth.setContentMessageBox({
            //icon: 'notifications',
            openAt: DateTime.now(),
            color: "warning",
            title: t("pages.signin.title"),
            content: t("pages.signin.message.signin_incorrect"),
            contentEx: resLogin.data.message,
          })
        );
        await reduxDispatch(auth.setShowMessageBox(true));
        return;
      }

      if (resLogin.data.success) {
        reduxDispatch(auth.login(resLogin.data.result));
        await reduxDispatch(auth.setShowMessageBox(false));
        //reduxDispatch(auth.login({ username: "admin", name: { th: "มุฮัยมาน เฮ่ง", en: "Muhaiman Heng" }, image: profilePicture }))
        navigate("/");
      } else {
        //setShowSnackbar(true);
        await reduxDispatch(
          auth.setContentMessageBox({
            //icon: 'notifications',
            openAt: DateTime.now(),
            color: "warning",
            title: t("pages.signin.title"),
            content: t("pages.signin.message.signin_incorrect"),
            contentEx: resLogin.data.message,
          })
        );
        await reduxDispatch(auth.setShowMessageBox(true));
      }
    } catch (error) {
      await reduxDispatch(
        auth.setContentMessageBox({
          //icon: 'notifications',
          openAt: DateTime.now(),
          color: "warning",
          title: t("pages.signin.title"),
          content: t("pages.signin.message.signin_incorrect"),
          contentEx: error.message,
        })
      );
      await reduxDispatch(auth.setShowMessageBox(true));
      //setShowSnackbar(true);
    }
  };

  //const onLogin = async () => {
  // try {
  //   const body = LoginModel.parse({
  //     username,
  //     password,
  //     mfaToken,
  //   });
  //   const resLogin = await login(body);
  //   //console.log("===============>",resLogin)
  //   if (resLogin.error) {
  //     //setShowSnackbar(true);
  //     await reduxDispatch(
  //       auth.setContentMessageBox({
  //         //icon: 'notifications',
  //         openAt: DateTime.now(),
  //         color: "warning",
  //         title: t("pages.signin.title"),
  //         content: t("pages.signin.message.signin_incorrect"),
  //         contentEx: resLogin.error.data.messageEx,
  //       })
  //     );
  //     await reduxDispatch(auth.setShowMessageBox(true));
  //     return;
  //   }

  //   if (resLogin.data.code === 203) {
  //     setShowMfaToken(true);
  //     await reduxDispatch(
  //       auth.setContentMessageBox({
  //         //icon: 'notifications',
  //         openAt: DateTime.now(),
  //         color: "warning",
  //         title: t("pages.signin.title"),
  //         content: t("pages.signin.message.signin_incorrect"),
  //         contentEx: resLogin.data.message,
  //       })
  //     );
  //     await reduxDispatch(auth.setShowMessageBox(true));
  //     return;
  //   }

  //   if (resLogin.data.success) {
  //     reduxDispatch(auth.login(resLogin.data.result));
  //     await reduxDispatch(auth.setShowMessageBox(false));
  //     //reduxDispatch(auth.login({ username: "admin", name: { th: "มุฮัยมาน เฮ่ง", en: "Muhaiman Heng" }, image: profilePicture }))
  //     navigate("/");
  //   } else {
  //     //setShowSnackbar(true);
  //     await reduxDispatch(
  //       auth.setContentMessageBox({
  //         //icon: 'notifications',
  //         openAt: DateTime.now(),
  //         color: "warning",
  //         title: t("pages.signin.title"),
  //         content: t("pages.signin.message.signin_incorrect"),
  //         contentEx: resLogin.data.message,
  //       })
  //     );
  //     await reduxDispatch(auth.setShowMessageBox(true));
  //   }
  // } catch (error) {
  //   await reduxDispatch(
  //     auth.setContentMessageBox({
  //       //icon: 'notifications',
  //       openAt: DateTime.now(),
  //       color: "warning",
  //       title: t("pages.signin.title"),
  //       content: t("pages.signin.message.signin_incorrect"),
  //       contentEx: error.message,
  //     })
  //   );
  //   await reduxDispatch(auth.setShowMessageBox(true));
  //   //setShowSnackbar(true);
  // }
  //};

  return (
    <form onSubmit={handleSubmit(onSubmit)} fullWidth>
      <CoverLayout image={bgImage}>
        <Card>
          <MessageNotification
            content={contentMessageBox}
            showMessageBox={showMessageBox}
            setShowMessageBox={auth.setShowMessageBox}
          />
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              {t("pages.signin.title")}
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              {t("pages.signin.description")}
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox role="form">
              <MDBox mb={2}>
                {/* <MDInput
                  type="text"
                  label={t("pages.signin.label_username")}
                  variant="standard"
                  fullWidth
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  placeholder="john"
                  InputLabelProps={{ shrink: true }}
                /> */}
                <FormInputField
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  label={t("pages.signin.label_username")}
                  name="username"
                  {...register("username")}
                  control={control}
                  errors={errors}
                  placeholder="kenblock"
                  //type="password"
                  //inputProps={{ readOnly: true }}
                />
              </MDBox>
              <MDBox mb={2}>
                {/* <MDInput
                  type="password"
                  label={t("pages.signin.label_password")}
                  variant="standard"
                  fullWidth
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  placeholder="************"
                  InputLabelProps={{ shrink: true }}
                /> */}
                <FormInputField
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  label={t("pages.signin.label_password")}
                  name="password"
                  {...register("password")}
                  control={control}
                  errors={errors}
                  placeholder="************"
                  type="password"
                  //inputProps={{ readOnly: true }}
                />
              </MDBox>
              {showMfaToken && (
                <MDBox mb={2}>
                  {/* <MDInput
                    type="password"
                    label={t("pages.signin.label_mfaToken")}
                    variant="standard"
                    fullWidth
                    onChange={(e) => setMfaToken(e.target.value)}
                    value={mfaToken}
                    placeholder="******"
                    InputLabelProps={{ shrink: true }}
                  /> */}
                  <FormInputField
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    label={t("pages.signin.label_mfaToken")}
                    name="mfaToken"
                    {...register("mfaToken")}
                    control={control}
                    errors={errors}
                    placeholder="******"
                    autocomplete="off"
                    type="password"
                    //inputProps={{ readOnly: true }}
                  />
                </MDBox>
              )}

              <MDBox mt={4} mb={1}>
                <MDBox sx={{ m: 1, position: "relative" }}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    disabled={isLoading}
                    fullWidth
                    //onClick={onLogin}
                    type="submit"
                  >
                    {t("pages.signin.label_signin")}
                  </MDButton>
                  {isLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        //color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </MDBox>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDTypography
                  variant="button"
                  fontWeight={
                    i18n.resolvedLanguage === "th" ? "bold" : "regular"
                  }
                  color="text"
                  onClick={() => i18n.changeLanguage("th")}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;{t("configurator_ui.language.th")}
                </MDTypography>
                &nbsp;&nbsp;
                <MDTypography
                  variant="button"
                  fontWeight={
                    i18n.resolvedLanguage === "en" ? "bold" : "regular"
                  }
                  color="text"
                  onClick={() => i18n.changeLanguage("en")}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;{t("configurator_ui.language.en")}
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </CoverLayout>
    </form>
  );
}

export default Cover;
