import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    roleListPage: null,
    roleSelectRow: null,
    roleActionView: 'LIST', //'LIST', 'NEW','EDIT',DELETE, DETAIL
    roleOpenDialogInfo: false, 
    roleOpenDialogCreate: false, 
    roleOpenDialogEdit: false, 
    rolePagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1
    },
    roleSelectPerPage: 20,
    roleListPerPage: [10, 20, 30, 40, 50, 100, 150, 200],
    roleSearch: '',
    roleActiveOnly: false,
    roleStatus: null,
    roleShowDelete: false,
    roleShowMessageBox: false,
    roleContentMessageBox: {
        icon: 'notifications',
        color: 'info',
        openAt: null,
        content: '',
        contentEx: null,
        title: ''
    },
};

export const roleSlice = createSlice({
    name: 'role',
    initialState: initialState,
    reducers: {
        setListPage: (state, action) => {
            state.roleListPage = action.payload;
        },
        setSelectRow: (state, action) => {
            state.roleSelectRow = action.payload;
        },
        setActionView: (state, action) => {
            state.roleActionView = action.payload;
        },
        setOpenDialogInfo: (state, action) => {
            state.roleOpenDialogInfo = action.payload;
        },
        setOpenDialogCreate: (state, action) => {
            state.roleOpenDialogCreate = action.payload;
        },
        setOpenDialogEdit: (state, action) => {
            state.roleOpenDialogEdit = action.payload;
        },
        setPagination: (state, action) => {
            state.rolePagination = { ...state.rolePagination, ...action.payload };
        },
        setSelectPerPage: (state, action) => {
            state.roleSelectPerPage = action.payload;
        },
        setListPerPage: (state, action) => {
            state.roleListPerPage = action.payload;
        },
        setSearch: (state, action) => {
            state.roleSearch = action.payload;
        },
        setActiveOnly: (state, action) => {
            state.roleActiveOnly = action.payload;
        },
        setStatus: (state, action) => {
            state.roleStatus = action.payload;
        },
        setShowDelete: (state, action) => {
            state.roleShowDelete = action.payload;
        },
        setShowMessageBox: (state, action) => {
            state.roleShowMessageBox = action.payload;
        },
        setContentMessageBox: (state, action) => {
            state.roleContentMessageBox = { ...state.roleContentMessageBox, ...action.payload };
        },
    },
})

export const {
    setListPage,
    setSelectRow,
    setActionView,
    setOpenDialogInfo,
    setOpenDialogCreate,
    setOpenDialogEdit,
    setPagination,
    setSelectPerPage,
    setListPerPage,
    setSearch,
    setActiveOnly,
    setStatus,
    setShowDelete,
    setShowMessageBox,
    setContentMessageBox
} = roleSlice.actions

export const getListPage = (state) => state.role.roleListPage
export const getSelectRow = (state) => state.role.roleSelectRow
export const getActionView = (state) => state.role.roleActionView
export const getOpenDialogInfo = (state) => state.role.roleOpenDialogInfo
export const getOpenDialogCreate = (state) => state.role.roleOpenDialogCreate
export const getOpenDialogEdit = (state) => state.role.roleOpenDialogEdit
export const getPagination = (state) => state.role.rolePagination
export const getSelectPerPage = (state) => state.role.roleSelectPerPage
export const getListPerPage = (state) => state.role.roleListPerPage
export const getSearch = (state) => state.role.roleSearch
export const getActiveOnly = (state) => state.role.roleActiveOnly
export const getStatus = (state) => state.role.roleStatus
export const getShowDelete = (state) => state.role.roleShowDelete
export const getShowMessageBox = (state) => state.role.roleShowMessageBox
export const getContentMessageBox = (state) => state.role.roleContentMessageBox
export default roleSlice.reducer