/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// @mui material components
import Icon from "@mui/material/Icon";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function CardList({ title, items, handleToggleAll, numberOfChecked, handleToggle, checked, listProp }) {
 const { t, i18n } = useTranslation();
  return (
    <Card>
      <MDBox p={2}>
        <MDBox display="flex" alignItems="center">
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
          <MDBox ml={2} mt={-2} lineHeight={0}>
            <MDTypography
              sx={({ typography: { size, fontWeightBold } }) => ({
                fontSize: size.sm,
                fontWeight: fontWeightBold,
                justifyContent: "center",
                alignItems: "center",
                color: "text",
                pt: 0.3
              })}>
              {title}
            </MDTypography>
            <MDTypography
              sx={({ typography: { size, fontWeightBold } }) => ({
                fontSize: size.sm,
                fontWeight: fontWeightBold,
                justifyContent: "center",
                alignItems: "center",
                color: "text",
                pt: 0.3
              })}>
              {`${numberOfChecked(items)}/${items.length} ${t('components.HRComponents.TransferList.select_label')}`}
            </MDTypography>
          </MDBox>
        </MDBox>
        <Divider />
        <List
          sx={{
            width: listProp?.width || 200,
            height: listProp?.height || 500,
            overflow: 'auto',
          }}
          dense
          component="div"
          role="list"
        >
          {items.map((value) => {
            const labelId = `transfer-list-all-item-${value}-label`;
            return (
              <ListItem
                key={value}
                role="listitem"
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} >
                  <MDTypography
                    sx={({ typography: { size, fontWeightBold } }) => ({
                      fontSize: size.xs,
                      //fontWeight: fontWeightBold,
                      justifyContent: "center",
                      alignItems: "center",
                      color: "text",
                      //textTransform: "uppercase",
                      pt: 0.3
                    })}>
                    {value?.label}
                  </MDTypography>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      </MDBox>
    </Card>
  );
}

// Typechecking props of the Transaction
CardList.propTypes = {
  // color: PropTypes.oneOf([
  //   "primary",
  //   "secondary",
  //   "info",
  //   "success",
  //   "warning",
  //   "error",
  //   "light",
  //   "dark",
  // ]).isRequired,
  // name: PropTypes.string.isRequired,
  // description: PropTypes.any.isRequired,
};

export default CardList;
