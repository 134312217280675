/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import profilePicture from "assets/images/team-3.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";

import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import BuildIcon from "@mui/icons-material/Build";

import { useUpdateAvatarMutation } from "states/services/authentication";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import _ from "lodash";
import { DateTime } from "luxon";

import {
  setShowMessageBox,
  setContentMessageBox,
  setUpdateAvatar,
  selectAuth,
} from "states/features/auth/authSlice";

function Header({ children }) {
  const reduxDispatch = useDispatch();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const userAuth = useSelector(selectAuth);
  const { t, i18n } = useTranslation();

  // 👇 API Login Mutation
  const [updateAvatar, updateAvatarStatus] = useUpdateAvatarMutation();

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const handleUploadAvatar = async (event) => {
    //console.log(event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > 1 * 1000 * 1024) {
        //console.log("File with maximum size of 1MB is allowed");
        await reduxDispatch(
          setContentMessageBox({
            //icon: 'notifications',
            openAt: DateTime.now(),
            color: "warning",
            title:  t("pages.profile.edit_avatar"),
            content: t("pages.profile.message.file_size"),
            contentEx: t("pages.profile.message.file_size"),
          })
        );
        await reduxDispatch(setShowMessageBox(true));
        return;
      }

      const resUpdateAvatar = await updateAvatar({
        avatar: event.target.files[0],
      });
      if (resUpdateAvatar.error) {
        await reduxDispatch(
          setContentMessageBox({
            //icon: 'notifications',
            openAt: DateTime.now(),
            color: "warning",
            title: t("pages.profile.edit_avatar"),
            content: resUpdateAvatar.error.data.message,
            contentEx: resUpdateAvatar.error.data.messageEx,
          })
        );
        await reduxDispatch(setShowMessageBox(true));
        return;
      }

      //console.log(resUpdateAvatar.data.result,userAuth);
      await reduxDispatch(setUpdateAvatar(resUpdateAvatar.data.result));

      await reduxDispatch(
        setContentMessageBox({
          //icon: 'notifications',
          openAt: DateTime.now(),
          color: "success",
          title: t("pages.profile.edit_avatar"),
          content: resUpdateAvatar.data.message,
          contentEx: null,
        })
      );
      reduxDispatch(setShowMessageBox(true));
      return;
    }
  };

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <IconButton component="label">
              <MDBadge
                overlap="circular"
                indicator={true}
                color="light"
                size="xl"
                border={true}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={<FileUploadOutlined />}
              >
                <MDAvatar
                  src={userAuth.user.image || profilePicture}
                  alt={
                    userAuth.name
                      ? userAuth.user["name_" + i18n.resolvedLanguage]
                      : ""
                  }
                  size="xl"
                  shadow="sm"
                />
              </MDBadge>
              <input
                styles={{ display: "none" }}
                type="file"
                hidden
                onChange={handleUploadAvatar}
                name="[avatar]"
              />
            </IconButton>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {userAuth.user
                  ? userAuth.user["name_" + i18n.resolvedLanguage]
                  : ""}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {userAuth.user
                  ? userAuth.user["name_" + i18n.resolvedLanguage]
                  : "CEO Any-Z"}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
