/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useMemo, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

// react-router-dom components
import { useNavigate } from "react-router-dom";

import _ from "lodash"

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { darken } from '@mui/material';

// @mui material components
import { IconButton } from '@mui/material';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  ChangeCircle as ChangeCircleIcon,
  RestoreFromTrash as RestoreFromTrashIcon,
  Sync as SyncIcon,
  Info as InfoIcon,
} from '@mui/icons-material';


// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setActionView,
  setPagination,
  setSelectPerPage,
  setSearch,
  setStatus,
  setShowDelete,
  setSelectRow,
  getListPage,
  getPagination,
  getSelectPerPage,
  getListPerPage,
  getSearch,
  getStatus,
  getShowDelete,
  setContentMessageBox,
  setShowMessageBox,
  getOpenDialog,
  setOpenDialog,
  setRoleListPage,
  getRoleListPage,
  setRolePagination,
  getRolePagination,
  getRoleSelectPerPage,
  getRoleSearch,
  setRoleSearch,
  getRoleStatus,
  setRoleStatus,
  getRoleShowDelete,
  setRoleShowDelete,
  setUserListPage,
  setUserPagination,
  getUserPagination,
  getUserSelectPerPage,
  getUserSearch,
  getUserStatus,
  getUserShowDelete,
  getRoleListPerPage,
  setRoleSelectPerPage,
  getRoleSelectRow,
  getRoleList,
  setRoleList,
} from "states/features/privileges/privilegesSlice";
import {
  useGetByIdQuery,
  useGetQuery,
  useGetPageQuery,
  useCreateMutation,
  useUpdateMutation,
  useEditMutation,
  useDestroyMutation,
  useRestoreMutation,
  useAddListMutation,
  useRemoveListMutation,
} from "states/services/privileges";

// MY 
import TransferList from "components/HRComponents/TransferList";
import { DateTime } from "luxon";
import MDButton from "components/MDButton";


function PrivilegesManagementUser({ queryPrivilegesPageList, queryPrivilegesRolePageList, queryPrivilegesUserPageList, queryPrivilegesByRoleList }) {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  const roleSelectRow = useSelector(getRoleSelectRow);
  const roleList = useSelector(getRoleList);

  // 👇 API Login Mutation
  const [addList, addListStatus] = useAddListMutation();
  const [removeList, removeListStatus] = useRemoveListMutation();

  useEffect(() => {
    if (roleList) {
      let labelRole = roleList.map((x) => { return { ...x, ...{ label: "[" + x.username + "] " + x["user_name_" + i18n.resolvedLanguage] } } })
      let labelRoleleft = _.sortBy(labelRole.filter((x) => x.condition === 'Include'), ['username'])
      let labelRoleRight = _.sortBy(labelRole.filter((x) => x.condition === 'Exclude'), ['username'])
      setLeft(labelRoleleft)
      setRight(labelRoleRight)
    }
  }, [i18n.resolvedLanguage, roleList]);

  return (
    <Paper elevation={8} sx={(theme) => ({
      borderRadius: '0',
      backgroundColor: darken(theme.palette.background.default, 0),
      color: 'text',
      pb: 2
    })}  >
      <MDBox container >
        <MDBox item sx={{ p: 2 }} >
          <Grid container spacing={3} >
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} >
              <MDInput
                id="role_name_en"
                type="text"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                value={roleSelectRow?.name_en}
                InputLabelProps={{ shrink: true }}
                label={t('pages.privileges.label_role_name_en')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} >
              <MDInput
                id="role_name_th"
                type="text"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                value={roleSelectRow?.name_th}
                InputLabelProps={{ shrink: true }}
                label={t('pages.privileges.label_role_name_th')}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox item>
          <TransferList
            leftTitle={t('pages.privileges.label_include')}
            rightTitle={t('pages.privileges.label_exclude')}
            listProp={{ width: "100%", height: 300 }}
            callbackHandleCheckedRight={async (data) => {
              await removeList(data)
              queryPrivilegesByRoleList.refetch()
            }}
            callbackHandleCheckedLeft={async (data) => {
              await addList(data)
              queryPrivilegesByRoleList.refetch()
            }}
            left={left}
            setLeft={setLeft}
            right={right}
            setRight={setRight}
          />
        </MDBox>
      </MDBox >

    </Paper>
  );
}

export default PrivilegesManagementUser;
