import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    permissionListByRole: null,
    permissionModuleList: null,
    permissionRoleList: null,
    permissionRoleSearch: '',
    permissionRoleActiveOnly: false,
    permissionRoleStatus: null,
    permissionRoleShowDelete: false,
    permissionRoleListPage: null,
    permissionRoleSelectRow: null,
    permissionRolePagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1
    },
    permissionRoleSelectPerPage: 20,
    permissionRoleListPerPage: [10, 20, 30, 40, 50, 100, 150, 200],
    permissionListPage: null,
    permissionSelectRow: null,
    permissionActionView: 'LIST', //'LIST', 'NEW','EDIT',DELETE, DETAIL
    permissionOpenDialogInfo: false, 
    permissionOpenDialogCreate: false, 
    permissionOpenDialogEdit: false, 
    permissionPagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1
    },
    permissionSelectPerPage: 20,
    permissionListPerPage: [10, 20, 30, 40, 50, 100, 150, 200],
    permissionSearch: '',
    permissionActiveOnly: false,
    permissionStatus: null,
    permissionShowDelete: false,
    permissionShowMessageBox: false,
    permissionContentMessageBox: {
        icon: 'notifications',
        color: 'info',
        openAt: null,
        content: '',
        contentEx: null,
        title: ''
    },
};

export const permissionSlice = createSlice({
    name: 'permission',
    initialState: initialState,
    reducers: {
        setListByRole: (state, action) => {
            state.permissionListByRole = action.payload;
        },
        setModuleList: (state, action) => {
            state.permissionModuleList = action.payload;
        },
        setRoleList: (state, action) => {
            state.permissionRoleList = action.payload;
        },
        setRoleSearch: (state, action) => {
            state.permissionRoleSearch = action.payload;
        },
        setRoleActiveOnly: (state, action) => {
            state.permissionRoleActiveOnly = action.payload;
        },
        setRoleStatus: (state, action) => {
            state.permissionRoleStatus = action.payload;
        },
        setRoleShowDelete: (state, action) => {
            state.permissionRoleShowDelete = action.payload;
        },
        setRoleListPage: (state, action) => {
            state.permissionRoleListPage = action.payload;
        },
        setRoleSelectRow: (state, action) => {
            state.permissionRoleSelectRow = action.payload;
        },
        setRolePagination: (state, action) => {
            state.permissionRolePagination = { ...state.permissionRolePagination, ...action.payload };
        },
        setRoleSelectPerPage: (state, action) => {
            state.permissionRoleSelectPerPage = action.payload;
        },
        setRoleListPerPage: (state, action) => {
            state.permissionRoleListPerPage = action.payload;
        },
        setListPage: (state, action) => {
            state.permissionListPage = action.payload;
        },
        setSelectRow: (state, action) => {
            state.permissionSelectRow = action.payload;
        },
        setActionView: (state, action) => {
            state.permissionActionView = action.payload;
        },
        setOpenDialogInfo: (state, action) => {
            state.permissionOpenDialogInfo = action.payload;
        },
        setOpenDialogCreate: (state, action) => {
            state.permissionOpenDialogCreate = action.payload;
        },
        setOpenDialogEdit: (state, action) => {
            state.permissionOpenDialogEdit = action.payload;
        },
        setPagination: (state, action) => {
            state.permissionPagination = { ...state.permissionPagination, ...action.payload };
        },
        setSelectPerPage: (state, action) => {
            state.permissionSelectPerPage = action.payload;
        },
        setListPerPage: (state, action) => {
            state.permissionListPerPage = action.payload;
        },
        setSearch: (state, action) => {
            state.permissionSearch = action.payload;
        },
        setActiveOnly: (state, action) => {
            state.permissionActiveOnly = action.payload;
        },
        setStatus: (state, action) => {
            state.permissionStatus = action.payload;
        },
        setShowDelete: (state, action) => {
            state.permissionShowDelete = action.payload;
        },
        setShowMessageBox: (state, action) => {
            state.permissionShowMessageBox = action.payload;
        },
        setContentMessageBox: (state, action) => {
            state.permissionContentMessageBox = { ...state.permissionContentMessageBox, ...action.payload };
        },
    },
})

export const {
    setListByRole,
    setModuleList,
    setRoleList,
    setRoleSearch,
    setRoleActiveOnly,
    setRoleStatus,
    setRoleShowDelete,
    setRoleListPage,
    setRoleSelectRow,
    setRolePagination,
    setRoleSelectPerPage,
    setRoleListPerPage,
    setListPage,
    setSelectRow,
    setActionView,
    setOpenDialogInfo,
    setOpenDialogCreate,
    setOpenDialogEdit,
    setPagination,
    setSelectPerPage,
    setListPerPage,
    setSearch,
    setActiveOnly,
    setStatus,
    setShowDelete,
    setShowMessageBox,
    setContentMessageBox
} = permissionSlice.actions

export const getListByRole = (state) => state.permission.permissionListByRole
export const getModuleList = (state) => state.permission.permissionModuleList
export const getRoleList = (state) => state.permission.permissionRoleList
export const getRoleSearch = (state) => state.permission.permissionRoleSearch
export const getRoleActiveOnly = (state) => state.permission.permissionRoleActiveOnly
export const getRoleStatus = (state) => state.permission.permissionRoleStatus
export const getRoleShowDelete = (state) => state.permission.permissionRoleShowDelete
export const getRoleListPage = (state) => state.permission.permissionRoleListPage
export const getRoleSelectRow = (state) => state.permission.permissionRoleSelectRow
export const getRolePagination = (state) => state.permission.permissionRolePagination
export const getRoleSelectPerPage = (state) => state.permission.permissionRoleSelectPerPage
export const getRoleListPerPage = (state) => state.permission.permissionRoleListPerPage
export const getListPage = (state) => state.permission.permissionListPage
export const getSelectRow = (state) => state.permission.permissionSelectRow
export const getActionView = (state) => state.permission.permissionActionView
export const getOpenDialogInfo = (state) => state.permission.permissionOpenDialogInfo
export const getOpenDialogCreate = (state) => state.permission.permissionOpenDialogCreate
export const getOpenDialogEdit = (state) => state.permission.permissionOpenDialogEdit
export const getPagination = (state) => state.permission.permissionPagination
export const getSelectPerPage = (state) => state.permission.permissionSelectPerPage
export const getListPerPage = (state) => state.permission.permissionListPerPage
export const getSearch = (state) => state.permission.permissionSearch
export const getActiveOnly = (state) => state.permission.permissionActiveOnly
export const getStatus = (state) => state.permission.permissionStatus
export const getShowDelete = (state) => state.permission.permissionShowDelete
export const getShowMessageBox = (state) => state.permission.permissionShowMessageBox
export const getContentMessageBox = (state) => state.permission.permissionContentMessageBox
export default permissionSlice.reducer