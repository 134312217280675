/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// redux

import { useDispatch, useSelector } from "react-redux";
import * as auth from "states/features/auth/authSlice";
import { useChangePasswordMutation } from "states/services/authentication";

import { setGeoLocation } from "states/features/configuration/configurationSlice";

import { useGetClientInfoQuery } from "states/services/clientInfo";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDAlert from "components/MDAlert";

import {
  FormInputField,
  FormSelectField,
  FormSwitchBoxField,
} from "components/HRComponents/FormField";

// Authentication layout components
import CoverLayout from "layouts/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";

import { LoginModel } from "models";
// import {
//   setShowMessageBox,
//   setContentMessageBox,
// } from "states/features/auth/authSlice";

// MY
import MessageNotification from "components/HRComponents/MessageNotification";
//import profilePicture from "assets/images/team-1.jpg";
import { DateTime } from "luxon";

function ForceChangePassword() {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const [showMfaToken, setShowMfaToken] = useState(false);

  const showMessageBox = useSelector(auth.getShowMessageBox);
  const contentMessageBox = useSelector(auth.getContentMessageBox);
  const userAuth = useSelector(auth.selectAuth);
  //const timer = useRef(0);

  // useEffect(() => {
  //   return () => {
  //     clearTimeout(timer.current);
  //   };
  // }, []);

  const schema = z
  .object({
    username: z.string(),
    currentPassword: z.string({
      required_error: "Current password is required",
      invalid_type_error: "Current password is required",
    }),
    newPassword: z
      .string({
        required_error: "New password is required",
        invalid_type_error: "New password is required",
      })
      .min(8, {
        message:
          t("pages.user.data_grid.header.password") +
          t("pages.user.message.minLength_8"),
      })
      .regex(new RegExp(".*[A-Z].*"), { message: "Required one uppercase" })
      .regex(new RegExp(".*[a-z].*"), { message: "Required one lowercase" })
      .regex(new RegExp(".*[0-9].*"), { message: "Required one number" })
      .regex(new RegExp(".*[`~<>?,./!@#$%^&*()\\-_+=\"'|{}\\[\\];:\\\\].*"), {
        message: "Required one speceial charactor",
      }),
    confirmNewPassword: z
      .string()
      .min(8, {
        message:
          t("pages.user.data_grid.header.password") +
          t("pages.user.message.minLength_8"),
      })
      .regex(new RegExp(".*[A-Z].*"), { message: "Required one uppercase" })
      .regex(new RegExp(".*[a-z].*"), { message: "Required one lowercase" })
      .regex(new RegExp(".*[0-9].*"), { message: "Required one number" })
      .regex(new RegExp(".*[`~<>?,./!@#$%^&*()\\-_+=\"'|{}\\[\\];:\\\\].*"), {
        message: "Required one speceial charactor",
      })
      .nullish(),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    message: "New password doesn't match",
    path: ["confirmNewPassword"],
  });

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      usernaame: null,
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
    },
  });
  // 👇 API Login Mutation
  const [changePassword, { isLoading, isError, error, isSuccess }] =
    useChangePasswordMutation();

  const queryuseClientInfo = useGetClientInfoQuery();

  // Setting the dir attribute for the body element
  useEffect(() => {
    if (queryuseClientInfo.isSuccess) {
      reduxDispatch(setGeoLocation(queryuseClientInfo.data));
    }
  }, [queryuseClientInfo.data, queryuseClientInfo.isSuccess, reduxDispatch]);

    // Setting the dir attribute for the body element
    useEffect(() => {
     setValue("username",userAuth?.user.username)
    }, [setValue, userAuth?.user.username]);

  const onSubmit = async (data) => {
    const resChangePassword = await changePassword(data);
    if (resChangePassword.error) {
      await reduxDispatch(
        auth.setContentMessageBox({
          //icon: 'notifications',
          openAt: DateTime.now(),
          color: "warning",
          title: t("pages.profile.change_password_title"),
          content: resChangePassword.error.data.message,
          contentEx: resChangePassword.error.data.messageEx,
        })
      );
      await reduxDispatch( auth.setShowMessageBox(true));
      //setValue("currentPassword","");
      //setValue("newPassword","");
      //setValue("confirmNewPassword","");
      //reset();
      return;
    }
    
    console.log("=============>", resChangePassword.data.result);
    reduxDispatch( auth.setChangePassword(resChangePassword.data.result));
    //reduxDispatch(auth.login({ username: "admin", name: { th: "มุฮัยมาน เฮ่ง", en: "Muhaiman Heng" }, image: profilePicture }))
    //navigate("/");
    await reduxDispatch(
      auth.setContentMessageBox({
        //icon: 'notifications',
        openAt: DateTime.now(),
        color: "success",
        title: t("pages.profile.change_password_title"),
        content: resChangePassword.data.message,
        contentEx: null,
      })
    );
    reduxDispatch( auth.setShowMessageBox(true));
    setValue("currentPassword", "");
    setValue("newPassword", "");
    setValue("confirmNewPassword", "");
    //reset();
    navigate("/");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} fullWidth>
      <CoverLayout image={bgImage}>
        <Card>
          <MessageNotification
            content={contentMessageBox}
            showMessageBox={showMessageBox}
            setShowMessageBox={auth.setShowMessageBox}
          />
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              {t("pages.forceChangePassword.title")}
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              {t("pages.forceChangePassword.description")}
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox role="form">
              <MDBox mb={2}>
                <FormInputField
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  label={t("pages.forceChangePassword.label_username")}
                  name="username"
                  {...register("username")}
                  control={control}
                  errors={errors}
                  placeholder="kenblock"
                  //type="password"
                  inputProps={{ readOnly: true }}
                />
              </MDBox>
              <MDBox mb={2}>
                <FormInputField
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  label={t("pages.forceChangePassword.label_current_password")}
                  name="currentPassword"
                  {...register("currentPassword")}
                  control={control}
                  errors={errors}
                  placeholder="************"
                  autocomplete="off"
                  type="password"
                  //inputProps={{ readOnly: true }}
                />
              </MDBox>
              <MDBox mb={2}>
                <FormInputField
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  label={t("pages.forceChangePassword.label_new_password")}
                  name="newPassword"
                  {...register("newPassword")}
                  control={control}
                  errors={errors}
                  placeholder="************"
                  autocomplete="off"
                  type="password"
                  //inputProps={{ readOnly: true }}
                />
              </MDBox>
              <MDBox mb={2}>
                <FormInputField
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  label={t("pages.forceChangePassword.label_confirm_new_password")}
                  name="confirmNewPassword"
                  {...register("confirmNewPassword")}
                  control={control}
                  errors={errors}
                  placeholder="************"
                  autocomplete="off"
                  type="password"
                  //inputProps={{ readOnly: true }}
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDBox sx={{ m: 1, position: "relative" }}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    disabled={isLoading}
                    fullWidth
                    type="submit"
                  >
                    {t("pages.forceChangePassword.label_change_password")}
                  </MDButton>
                  {isLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        //color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </MDBox>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDTypography
                  variant="button"
                  fontWeight={
                    i18n.resolvedLanguage === "th" ? "bold" : "regular"
                  }
                  color="text"
                  onClick={() => i18n.changeLanguage("th")}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;{t("configurator_ui.language.th")}
                </MDTypography>
                &nbsp;&nbsp;
                <MDTypography
                  variant="button"
                  fontWeight={
                    i18n.resolvedLanguage === "en" ? "bold" : "regular"
                  }
                  color="text"
                  onClick={() => i18n.changeLanguage("en")}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;{t("configurator_ui.language.en")}
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </CoverLayout>
    </form>
  );
}

export default ForceChangePassword;
