/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useMemo, useEffect, useRef, useCallback } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  ChangeCircle as ChangeCircleIcon,
  RestoreFromTrash as RestoreFromTrashIcon,
  Sync as SyncIcon,
  Info as InfoIcon,
} from "@mui/icons-material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  logout,
  login,
  setHistory,
  setPagination,
  setSelectPerPage,
  setListPerPage,
  setSearch,
  setActiveOnly,
  setIsFail,
  setStatus,
  setShowDelete,
  getHistory,
  getPagination,
  getSelectPerPage,
  getListPerPage,
  getSearch,
  getActiveOnly,
  getIsFail,
  getStatus,
  getShowDelete,
} from "states/features/auth/authSlice";
import { getDisplayModalForm } from "states/features/configuration/configurationSlice";

// MY
//import DataGrid from "components/HRComponents/DataGrid";
import { DataGrid } from "components/HRComponents/DataGrid.v2";
import { DateTime } from "luxon";

function ActivityHistory({ queryHistoryPageList }) {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const [textSearch, setTextSearch] = useState("");

  // 👇 API Login Mutation

  const search = useSelector(getSearch);
  const isFail = useSelector(getIsFail);
  const status = useSelector(getStatus);
  const showDelete = useSelector(getShowDelete);

  const ListPageLogs = useSelector(getHistory);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id", //access nested data with dot notation
        header: t("pages.profile.data_grid.header.id"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: "systemCode",
        header: t("pages.profile.data_grid.header.systemCode"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: "username",
        header: t("pages.profile.data_grid.header.username"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: "ipAddress",
        header: t("pages.profile.data_grid.header.ipAddress"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: "token",
        header: t("pages.profile.data_grid.header.token"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: "isFails",
        id: "isFails",
        //accessorFn: (originalRow) => (originalRow.isActive ? 'true' : 'false'), //must be strings
        header: t("pages.profile.data_grid.header.isFails"),
        //filterVariant: 'checkbox',
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.lg,
            })}
          >
            {cell.getValue() ? (
              <Tooltip
                title={t("pages.profile.data_grid.tooltip.label_fail")}
                placement="top"
              >
                <CancelIcon color="error" />{" "}
              </Tooltip>
            ) : (
              <Tooltip
                title={t("pages.profile.data_grid.tooltip.label_success")}
                placement="top"
              >
                <CheckCircleIcon color="success" />{" "}
              </Tooltip>
            )}
          </MDTypography>
        ),
      },
      {
        accessorKey: "isActive",
        id: "isActive",
        //accessorFn: (originalRow) => (originalRow.isActive ? 'true' : 'false'), //must be strings
        header: t("pages.profile.data_grid.header.isActive"),
        //filterVariant: 'checkbox',
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.lg,
            })}
          >
            {cell.getValue() ? (
              <Tooltip
                title={t("pages.profile.data_grid.tooltip.label_active")}
                placement="top"
              >
                <CheckCircleIcon />{" "}
              </Tooltip>
            ) : (
              <Tooltip
                title={t("pages.profile.data_grid.tooltip.label_inactive")}
                placement="top"
              >
                <CancelIcon />{" "}
              </Tooltip>
            )}
          </MDTypography>
        ),
      },
      {
        accessorKey: "version",
        header: t("pages.profile.data_grid.header.version"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()}
          </MDTypography>
        ),
      },
      {
        accessorKey: "logOfCreatedBy",
        header: t("pages.profile.data_grid.header.createdBy"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()
              ? "[ " +
                cell.getValue().username +
                " ] : " +
                cell.getValue()["name_" + i18n.resolvedLanguage]
              : ""}
          </MDTypography>
        ),
      },
      {
        accessorKey: "logOfUpdatedBy",
        header: t("pages.profile.data_grid.header.updatedBy"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()
              ? "[ " +
                cell.getValue().username +
                " ] : " +
                cell.getValue()["name_" + i18n.resolvedLanguage]
              : ""}
          </MDTypography>
        ),
      },
      {
        accessorKey: "createdAt",
        header: t("pages.profile.data_grid.header.createdAt"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()
              ? t("pages.profile.data_grid.detail.createdAt", {
                  date: new Date(cell.getValue()),
                  lng: i18n.resolvedLanguage,
                })
              : ""}
          </MDTypography>
        ),
      },
      {
        accessorKey: "updatedAt",
        header: t("pages.profile.data_grid.header.updatedAt"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()
              ? t("pages.profile.data_grid.detail.updatedAt", {
                  date: new Date(cell.getValue()),
                  lng: i18n.resolvedLanguage,
                })
              : ""}
          </MDTypography>
        ),
      },
      {
        accessorKey: "deletedAt",
        header: t("pages.profile.data_grid.header.deletedAt"),
        Header: ({ column }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {column.columnDef.header}
          </MDTypography>
        ),
        Cell: ({ cell }) => (
          <MDTypography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xs,
            })}
          >
            {cell.getValue()
              ? t("pages.profile.data_grid.detail.deletedAt", {
                  date: new Date(cell.getValue()),
                  lng: i18n.resolvedLanguage,
                })
              : ""}
          </MDTypography>
        ),
      },
    ],
    [i18n.resolvedLanguage, t]
  );

  useEffect(() => {
    setTextSearch(search);
  }, [search]);

  return (
    <MDBox py={3}>
      <Card sx={{ width: "100%" }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="success"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              <ManageHistoryIcon />
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            {t("pages.profile.list_title")}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <DataGrid
            data={ListPageLogs?.result || []}
            columns={columns}
            //onGlobalFilterChange={setGlobalFilter}
            getSelectPerPage={getSelectPerPage}
            getListPerPage={getListPerPage}
            getPagination={getPagination}
            setPagination={setPagination}
            setSelectPerPage={setSelectPerPage}
            queryPageList={queryHistoryPageList}
            initialState={{
              density: "compact",
              columnVisibility: {
                id: false,
                token: false,
                version: false,
                logOfCreatedBy: false,
                logOfUpdatedBy: false,
                updatedAt: false,
                deletedAt: false,
              },
            }}
            state={{
              showProgressBars: queryHistoryPageList.isFetching,
              isLoading: queryHistoryPageList.isLoading,
            }}
            renderTopToolbarCustomActions={
              <>
                <Grid
                  item
                  xs={10}
                  sm={10}
                  md={10}
                  lg={10}
                  xl={10}
                  xxl={4}
                  // ml={{ xl: '-3%', xxl: '-5%' }}
                  mt={0.3}
                >
                  <MDInput
                    sx={{ width: "100%" }}
                    size="small"
                    id="search_text"
                    type="search"
                    InputLabelProps={{ shrink: true }}
                    value={textSearch}
                    onChange={(event) => {
                      event.preventDefault();
                      setTextSearch(event.target.value);
                    }}
                    onKeyPress={(event) => {
                      //event.preventDefault()
                      if (event.key === "Enter") {
                        reduxDispatch(setPagination({ currentPage: 1 }));
                        reduxDispatch(setSearch(textSearch));
                      }
                    }}
                    onBlur={() => {
                      reduxDispatch(setPagination({ currentPage: 1 }));
                      reduxDispatch(setSearch(textSearch));
                    }}
                    label={t("pages.profile.label_search")}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} mt={0.5}>
                  <MDButton
                    size="small"
                    variant="standart"
                    iconOnly={true}
                    onClick={(event) => {
                      event.preventDefault();
                      reduxDispatch(setPagination({ currentPage: 1 }));
                      queryHistoryPageList.refetch();
                    }}
                  >
                    <Icon variant="button" fontSize="small" color="info">
                      <SyncIcon />
                    </Icon>
                  </MDButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={4}
                  xxl={2}
                  ml={{ xxl: "-4.5%" }}
                  mr={{ lg: "2%" }}
                  mt={0.3}
                >
                  <ToggleButtonGroup
                    color="info"
                    size="small"
                    value={isFail}
                    exclusive
                    onChange={(event, newAlignment) => {
                      event.preventDefault();
                      reduxDispatch(setPagination({ currentPage: 1 }));
                      reduxDispatch(setIsFail(newAlignment));
                    }}
                    aria-label={t("pages.profile.data_grid.header.isFails")}
                  >
                    <ToggleButton value={null}>
                      <MDBox color="text">{t("pages.profile.label_all")}</MDBox>
                    </ToggleButton>
                    <ToggleButton value={true}>
                      <MDBox color="text">{t("pages.profile.label_fail")}</MDBox>
                    </ToggleButton>
                    <ToggleButton value={false}>
                      <MDBox color="text">{t("pages.profile.label_success")}</MDBox>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={4}
                  xxl={2}
                  ml={{ xxl: "-2%" }}
                  mt={0.3}
                >
                  <ToggleButtonGroup
                    color="info"
                    size="small"
                    value={status}
                    exclusive
                    onChange={(event, newAlignment) => {
                      event.preventDefault();
                      reduxDispatch(setPagination({ currentPage: 1 }));
                      reduxDispatch(setStatus(newAlignment));
                    }}
                    aria-label={t("pages.profile.data_grid.header.isActive")}
                  >
                    <ToggleButton value={null}>
                      <MDBox color="text">{t("pages.profile.label_all")}</MDBox>
                    </ToggleButton>
                    <ToggleButton value={true}>
                      <MDBox color="text">{t("pages.profile.label_active")}</MDBox>
                    </ToggleButton>
                    <ToggleButton value={false}>
                      <MDBox color="text">
                        {t("pages.profile.label_inactive")}
                      </MDBox>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={2}
                  ml={{ xs: -1, sm: -1, md: "-4%", xxl: "-1%" }}
                  mt={0.3}
                >
                  <MDBox
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    width={{ xs: "auto%", sm: "auto" }}
                  >
                    <MDBox>
                      <Switch
                        variant="standard"
                        checked={showDelete}
                        onChange={(event) => {
                          event.preventDefault();
                          reduxDispatch(setPagination({ currentPage: 1 }));
                          reduxDispatch(setShowDelete(!showDelete));
                        }}
                      />
                    </MDBox>
                    <MDBox lineHeight={0}>
                      <MDTypography variant="button" color="text">
                        {showDelete
                          ? t("pages.profile.label_show_deleted")
                          : t("pages.profile.label_hide_deleted")}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              </>
            }
          />
        </MDBox>
      </Card>
    </MDBox>
  );
}

export default ActivityHistory;
