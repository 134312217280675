/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useMemo, useEffect, useRef, Fragment } from "react";
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

import { Controller } from "react-hook-form";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import { DateTime, Info } from "luxon";
import { DateTimePicker, DateTimePickerTabs } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import { useTranslation } from "react-i18next";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

// redux
import { useDispatch, useSelector } from "react-redux";
// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";

import LightModeIcon from '@mui/icons-material/LightMode';
import AcUnitIcon from '@mui/icons-material/AcUnit';

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
);

const useStyles = makeStyles((theme) => ({
  textDarkColor: {
    color: 'white'
  },
  textColor: {
    color: 'text'
  }
}));

function CustomTabs(props) {
  return (
    <Fragment>
      <DateTimePickerTabs  {...props} color="blueviolet" />
      <MDBox sx={{ backgroundColor: 'blueviolet', height: 2 }} />
    </Fragment>
  );
}

function FormDateTimePickerField({ name, control, rules, errors, ...rest }) {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const classes = useStyles();




  return (
    <MDBox mb={1.5}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) =>
          <MobileDateTimePicker
            // onChange={(newValue) => {
            //   //setValue(newValue);
            //   console.log(newValue)
            // }}
            //value={getValues(name)}
            //displayStaticWrapperAs="desktop"
            showDaysOutsideCurrentMonth={true}
            //disablePast={true}
            //fixedWeekNumber={6}
            //displayWeekNumber={true}
            //closeOnSelect={false}
            {...field}
            {...rest}
            format={"yyyy-LL-dd HH:mm"}
            ampm={false}
            //ampmInClock={false}
            orientation="landscape"
            //variant="static"
            //dayOfWeekFormatter={(day) => `${day}`} //Info.weekdays('short')[day]}
            // viewRenderers={{
            //   hours: renderTimeViewClock,
            //   minutes: renderTimeViewClock,
            //   seconds: renderTimeViewClock,
            // }}
            //slots={{ tabs: CustomTabs }}
            slotProps={{
              textField: { InputLabelProps: { shrink: true }, fullWidth: true },
              actionBar: { actions: ['clear', 'today', 'cancel', 'accept'] },
              //toolbar: { toolbarFormat: 'LLL-dd HH:mm', hidden: false},
              // tabs: {
              //   hidden: false,
              //   dateIcon: <LightModeIcon />,
              //   timeIcon: <AcUnitIcon />,
              // },
            }}
          //views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
          //className={darkMode ? classes.textDarkColor : classes.textColor}
          //sx={(theme) => ({ color: theme.palette.text })}
          />
        }
      />
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          {errors[name] && <span>{errors[name].message || 'This is error!'}</span>}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for FormField
FormDateTimePickerField.propTypes = {
  // label: PropTypes.string.isRequired,
  // name: PropTypes.string.isRequired,
  // control: PropTypes.any.isRequired,
  // errors: PropTypes.any.isRequired
};

export default FormDateTimePickerField;
