/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "components/Sidenav";
import Configurator from "components/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// RTL plugins

// Material Dashboard 2 PRO React routes
import appRoutes from "routes/app.routes";
//import publicRoutes from "routes/public.routes";


// Material Dashboard 2 PRO React layouts
import SignInCover from "pages/authentication/sign-in/cover";
import NotFound from "pages/error/not-found/cover";
import Denied from "pages/error/denied/cover";
import ForceChangePassword from "pages/authentication/force-change-password";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";


import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

// redux
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setGeoLocation,
} from "states/features/configuration/configurationSlice";

import {
  useGetClientInfoQuery,
} from "states/services/clientInfo";

import {
  selectAuth,
} from "states/features/auth/authSlice";

export default function App() {
  //console.log(navigator)
  //console.log(navigator.geolocation)
  // redux
  const userAuth = useSelector(selectAuth);
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const queryuseClientInfo = useGetClientInfoQuery(
  );

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

   // Setting the dir attribute for the body element
   useEffect(() => {
    if (queryuseClientInfo.isSuccess) {
      reduxDispatch(setGeoLocation(queryuseClientInfo.data))
    }
  }, [queryuseClientInfo.data, queryuseClientInfo.isSuccess, reduxDispatch]);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getAppRoutes = (allRoutes) =>
    allRoutes.map((appRoutes) => {
      if (appRoutes.collapse) {
        return getAppRoutes(appRoutes.collapse);
      }

      if (appRoutes.route) {
        if (!appRoutes.hidden) {
          return <Route exact path={appRoutes.route} element={appRoutes.component} key={appRoutes.key} />;
        }
      }

      return null;
    });


  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );


  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.language}>
        {userAuth ? layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName={t('app.name')}
              routes={appRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        ) : <> </>}
        {userAuth ? layout === "vr" && <Configurator /> : <> </>}
        <Routes>
          {getAppRoutes(appRoutes)}
          <Route exact path="/" element={<Navigate to={"/profile/overview"} />} />
          <Route exact path="/login" element={<SignInCover />} />
          <Route exact path="/force-chang-password" element={<ForceChangePassword />} />
          <Route exact path="/pagenotfound" element={<NotFound />} />
          <Route exact path="/error" element={<NotFound />} />
          <Route exact path="/denied" element={<Denied />} />
          <Route path="*" element={<Navigate to={"/pagenotfound"} />} />
        </Routes>
      </LocalizationProvider>
    </ThemeProvider>
  );

}
