import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    systemListPage: null,
    systemSelectRow: null,
    systemActionView: 'LIST', //'LIST', 'NEW','EDIT',DELETE, DETAIL
    systemOpenDialogInfo: false, 
    systemOpenDialogCreate: false, 
    systemOpenDialogEdit: false, 
    systemPagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1
    },
    systemSelectPerPage: 20,
    systemListPerPage: [10, 20, 30, 40, 50, 100, 150, 200],
    systemCode: '',
    systemSearch: '',
    systemActiveOnly: false,
    systemStatus: null,
    systemShowDelete: false,
    systemShowMessageBox: false,
    systemContentMessageBox: {
        icon: 'notifications',
        color: 'info',
        openAt: null,
        content: '',
        contentEx: null,
        title: ''
    },
};

export const systemSlice = createSlice({
    name: 'system',
    initialState: initialState,
    reducers: {
        setListPage: (state, action) => {
            state.systemListPage = action.payload;
        },
        setSelectRow: (state, action) => {
            state.systemSelectRow = action.payload;
        },
        setActionView: (state, action) => {
            state.systemActionView = action.payload;
        },
        setOpenDialogInfo: (state, action) => {
            state.systemOpenDialogInfo = action.payload;
        },
        setOpenDialogCreate: (state, action) => {
            state.systemOpenDialogCreate = action.payload;
        },
        setOpenDialogEdit: (state, action) => {
            state.systemOpenDialogEdit = action.payload;
        },
        setPagination: (state, action) => {
            state.systemPagination = { ...state.systemPagination, ...action.payload };
        },
        setSelectPerPage: (state, action) => {
            state.systemSelectPerPage = action.payload;
        },
        setListPerPage: (state, action) => {
            state.systemListPerPage = action.payload;
        },
        setCode: (state, action) => {
            state.systemCode = action.payload;
        },
        setSearch: (state, action) => {
            state.systemSearch = action.payload;
        },
        setActiveOnly: (state, action) => {
            state.systemActiveOnly = action.payload;
        },
        setStatus: (state, action) => {
            state.systemStatus = action.payload;
        },
        setShowDelete: (state, action) => {
            state.systemShowDelete = action.payload;
        },
        setShowMessageBox: (state, action) => {
            state.systemShowMessageBox = action.payload;
        },
        setContentMessageBox: (state, action) => {
            state.systemContentMessageBox = { ...state.systemContentMessageBox, ...action.payload };
        },
    },
})

export const {
    setListPage,
    setSelectRow,
    setActionView,
    setOpenDialogInfo,
    setOpenDialogCreate,
    setOpenDialogEdit,
    setPagination,
    setSelectPerPage,
    setListPerPage,
    setCode,
    setSearch,
    setActiveOnly,
    setStatus,
    setShowDelete,
    setShowMessageBox,
    setContentMessageBox
} = systemSlice.actions

export const getListPage = (state) => state.system.systemListPage
export const getSelectRow = (state) => state.system.systemSelectRow
export const getActionView = (state) => state.system.systemActionView
export const getOpenDialogInfo = (state) => state.system.systemOpenDialogInfo
export const getOpenDialogCreate = (state) => state.system.systemOpenDialogCreate
export const getOpenDialogEdit = (state) => state.system.systemOpenDialogEdit
export const getPagination = (state) => state.system.systemPagination
export const getSelectPerPage = (state) => state.system.systemSelectPerPage
export const getListPerPage = (state) => state.system.systemListPerPage
export const getCode = (state) => state.system.systemCode
export const getSearch = (state) => state.system.systemSearch
export const getActiveOnly = (state) => state.system.systemActiveOnly
export const getStatus = (state) => state.system.systemStatus
export const getShowDelete = (state) => state.system.systemShowDelete
export const getShowMessageBox = (state) => state.system.systemShowMessageBox
export const getContentMessageBox = (state) => state.system.systemContentMessageBox
export default systemSlice.reducer