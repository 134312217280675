/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";


// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components

import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from "@mui/material/Tooltip";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { darken } from '@mui/material';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import Transaction from "components/HRComponents/Transaction";
import ModalDisplayDetail from "components/HRComponents/ModalDisplayDetail";
import Grant from "pages/security/account/user/components/Grant";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  useCreateMutation,
  useGetPermissionQuery,
} from "states/services/user";
import {
  setOpenDialogRight,
  setPermissionList,
  getSelectRow,
  getOpenDialogRight,
  getPermissionList,
} from "states/features/user/userSlice";
import { DateTime } from "luxon";




function UserRight() {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const openDialog = useSelector(getOpenDialogRight);
  const selectRow = useSelector(getSelectRow);
  const permissionList = useSelector(getPermissionList);

  const [listGrant, setListGrant] = useState([]);

  const handleClose = () => {
    reduxDispatch(setOpenDialogRight(false));
  };

  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (openDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDialog]);

  useEffect(() => {
    if (permissionList) setListGrant(permissionList);
    //console.log(permissionList)
  }, [permissionList]);

  const contentDisplayDetail = () => (
    <>
     <Card sx={{ height: "100%" }}>
      {listGrant.map((systemData, iSys) => {
        return (
          <>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pt={3}
              px={2}
            > 
              <MDTypography
                key={"sys_1_" + iSys + "_" + systemData.name}
                variant="h6"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {systemData.name} 
              </MDTypography>
              <MDBox display="flex" alignItems="flex-start">
                <MDTypography
                  key={"sys_2_"  + iSys + "_" + systemData.code}
                  variant="button"
                  color="text"
                  fontWeight="regular"
                >
                  {t("pages.permission.label_grant")}
                </MDTypography>
              </MDBox>
            </MDBox>
            {systemData.subRows.map((funcData,iFunc) => {
              return (
                <MDBox pt={3} pb={2} px={2}>
                  <MDBox mb={2}>
                    <MDTypography
                      key={"func_"  + iSys + "_" + iFunc +"_"+ funcData.name}
                      variant="caption"
                      color="text"
                      fontWeight="bold"
                      textTransform="uppercase"
                    >
                      {funcData.name} 
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    key={"func_1_" + iSys + "_" + iFunc +"_"+  systemData.name}
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: "none" }}
                  >
                    {funcData.subRows.map((moduleData,iMod) => {
                      return (
                        <Grant
                          key={
                            "grant_"  + iSys + "_" + iFunc +"_"+ iMod + "_" + moduleData.name
                          }
                          color="info"
                          icon="app_registration"
                          name={moduleData.name}
                          description={moduleData.name}
                          value={moduleData.level}
                          data={moduleData}
                        />
                      );
                    })}
                  </MDBox>
                </MDBox>
              );
            })}
          </>
        );
      })}
    </Card>
    </>
  )

  return (
    <ModalDisplayDetail
      title={t("pages.user.list_info")}
      closeLabel={t("pages.user.action.label_close")}
      content={contentDisplayDetail()}
      openDialog={openDialog}
      handleClose={handleClose}
      dialogRef={descriptionElementRef}
    />
  );
}

export default UserRight;
