
import { useState, useMemo, useEffect, useRef } from "react";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useTranslation } from "react-i18next";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setShowMessageBox,
  setListPage,
  setActionView,
  setPagination,
  getOpenDialogInfo,
  setOpenDialogInfo,
  getOpenDialogCreate,
  setOpenDialogCreate,
  getOpenDialogEdit,
  setOpenDialogEdit,
  setUserList,
  getActionView,
  getPagination,
  getSelectPerPage,
  getSearch,
  getStatus,
  getShowDelete,
  getShowMessageBox,
  getContentMessageBox,
  getUserList,
  setUserSelect,
  getUserSelect,
} from "states/features/refreshToken/refreshTokenSlice";

import {
  useGetQuery,
  useGetPageQuery,
  useGetUserQuery,
} from "states/services/refreshToken";

// MY 
import MessageNotification from "components/HRComponents/MessageNotification";

import RefreshTokenList from "pages/security/authorization/refreshToken/components/RefreshTokenList";
import RefreshTokenInfo from "pages/security/authorization/refreshToken/components/RefreshTokenInfo";
import RefreshTokenCreate from "pages/security/authorization/refreshToken/components/RefreshTokenCreate";
import RefreshTokenEdit from "pages/security/authorization/refreshToken/components/RefreshTokenEdit";
import RefreshTokenEditModal from "pages/security/authorization/refreshToken/components/RefreshTokenEditModal";
import RefreshTokenCreateModal from "pages/security/authorization/refreshToken/components/RefreshTokenCreateModal";

function RefreshToken() {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const actionView = useSelector(getActionView);
  const showMessageBox = useSelector(getShowMessageBox);
  const contentMessageBox = useSelector(getContentMessageBox);
  const status = useSelector(getStatus);
  const showDelete = useSelector(getShowDelete);
  const pagination = useSelector(getPagination);
  const selectPerPage = useSelector(getSelectPerPage);
  const userSelect = useSelector(getUserSelect);

  const createModalElementRef = useRef(null);
  const editModalElementRef = useRef(null);

  const queryUserList = useGetUserQuery(
    // {
    //   refetchOnMountOrArgChange: true,
    //   //pollingInterval: 3000
    // }
  );

  const queryRefreshTokenPageList = useGetPageQuery({
    isActive: status === null ? undefined : status,
    hiddenDelete: showDelete ? false : undefined,
    userAccountBy: userSelect?.id,
    page: pagination?.currentPage || 1,
    size: selectPerPage || 50,
  }
    // , {
    //   refetchOnMountOrArgChange: true,
    //   //pollingInterval: 3000
    // }
  );

  useEffect(() => {
    if (queryRefreshTokenPageList.isSuccess) {
      reduxDispatch(setListPage(queryRefreshTokenPageList?.data?.result))
      reduxDispatch(setPagination({
        totalItems: queryRefreshTokenPageList?.data?.result?.totalItems,
        totalPages: queryRefreshTokenPageList?.data?.result?.totalPages
      }))
    }
  }, [queryRefreshTokenPageList.data?.result, queryRefreshTokenPageList.isSuccess, reduxDispatch]);

  useEffect(() => {
    if (queryUserList.isSuccess) {
      let userList = [];
      queryUserList?.data?.result.forEach(com => {
        userList.push({ label: '[' + com["username"] + '] ' + com["name_" + i18n.resolvedLanguage], id: com.id })
      });
      reduxDispatch(setUserList(userList))
      if (!userSelect) { reduxDispatch(setUserSelect(userList[0])) }
    }
  }, [i18n.resolvedLanguage, queryUserList?.data?.result, queryUserList.isSuccess, reduxDispatch, userSelect]);

  useEffect(() => {
    return () => {
      reduxDispatch(setOpenDialogInfo(false));
      reduxDispatch(setOpenDialogCreate(false));
      reduxDispatch(setOpenDialogEdit(false));
      reduxDispatch(setShowMessageBox(false))
      reduxDispatch(setActionView("LIST"))
    };
  }, [reduxDispatch]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {(actionView === 'LIST') && <RefreshTokenList queryRefreshTokenPageList={queryRefreshTokenPageList} queryUserList={queryUserList} />}
      {(actionView === 'CREATE') && <RefreshTokenCreate queryRefreshTokenPageList={queryRefreshTokenPageList} queryUserList={queryUserList} />}
      {(actionView === 'EDIT') && <RefreshTokenEdit queryRefreshTokenPageList={queryRefreshTokenPageList} queryUserList={queryUserList} />}
      <Footer />
      <MessageNotification
        content={contentMessageBox}
        showMessageBox={showMessageBox}
        setShowMessageBox={setShowMessageBox}
      />
      <RefreshTokenInfo />
      <RefreshTokenCreateModal dialogRef={createModalElementRef} queryRefreshTokenPageList={queryRefreshTokenPageList} queryUserList={queryUserList} />
      <RefreshTokenEditModal dialogRef={editModalElementRef} queryRefreshTokenPageList={queryRefreshTokenPageList} queryUserList={queryUserList} />
    </DashboardLayout>
  );
}

export default RefreshToken;
