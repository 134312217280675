
import { useState, useMemo, useEffect, useRef } from "react";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useTranslation } from "react-i18next";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setShowMessageBox,
  setListPage,
  setActionView,
  //setSystemList,
  setFunctionList,
  setFunctionSelect,
  setPagination,
  getOpenDialogInfo,
  setOpenDialogInfo,
  getOpenDialogCreate,
  setOpenDialogCreate,
  getOpenDialogEdit,
  setOpenDialogEdit,
  getActionView,
  getPagination,
  getSelectPerPage,
  //getSystemSelect,
  getFunctionSelect,
  getCode,
  getSearch,
  getStatus,
  getShowDelete,
  getShowMessageBox,
  getContentMessageBox,
} from "states/features/module/moduleSlice";

import {
  //useGetSystemQuery,
  useGetFunctionQuery,
  useGetPageQuery,
} from "states/services/module";

// MY 
import MessageNotification from "components/HRComponents/MessageNotification";

import ModuleList from "pages/security/resource/module/components/ModuleList";
import ModuleInfo from "pages/security/resource/module/components/ModuleInfo";
import ModuleCreate from "pages/security/resource/module/components/ModuleCreate";
import ModuleEdit from "pages/security/resource/module/components/ModuleEdit";
import ModuleEditModal from "pages/security/resource/module/components/ModuleEditModal";
import ModuleCreateModal from "pages/security/resource/module/components/ModuleCreateModal";

function Module() {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const actionView = useSelector(getActionView);
  const showMessageBox = useSelector(getShowMessageBox);
  const contentMessageBox = useSelector(getContentMessageBox);
  const search = useSelector(getSearch);
  const func = useSelector(getFunctionSelect);
  const code = useSelector(getCode);
  const status = useSelector(getStatus);
  const showDelete = useSelector(getShowDelete);
  const pagination = useSelector(getPagination);
  const selectPerPage = useSelector(getSelectPerPage);

  const createModalElementRef = useRef(null);
  const editModalElementRef = useRef(null);

  const queryFunctionList = useGetFunctionQuery(
    // {
    //   refetchOnMountOrArgChange: true,
    //   //pollingInterval: 3000
    // }
  );


  const queryModulePageList = useGetPageQuery({
    isActive: status === null ? undefined : status,
    hiddenDelete: showDelete ? false : undefined,
    textSearch: search,
    functionBy: func?.id,
    code: code === '' ? undefined : code,
    page: pagination?.currentPage || 1,
    size: selectPerPage || 50,
  }
    // , {
    //   refetchOnMountOrArgChange: true,
    //   //pollingInterval: 3000
    // }
  );

  useEffect(() => {
    if (queryModulePageList.isSuccess) {
      reduxDispatch(setListPage(queryModulePageList?.data?.result))
      reduxDispatch(setPagination({
        totalItems: queryModulePageList?.data?.result?.totalItems,
        totalPages: queryModulePageList?.data?.result?.totalPages
      }))
    }
  }, [queryModulePageList.data?.result, queryModulePageList.isSuccess, reduxDispatch]);

  useEffect(() => {
    if (queryFunctionList.isSuccess) {
      let comList = [];
      queryFunctionList?.data?.result.forEach(com => {
        comList.push({ label: '[' + com["code"] + '] ' + com["name_" + i18n.resolvedLanguage], id: com.id, system: com.functionOfSystemBy["name_" + i18n.resolvedLanguage] })
      });
      reduxDispatch(setFunctionList(comList))
      if (!func) { reduxDispatch(setFunctionSelect(comList[0])) }
    }
  }, [func, i18n.resolvedLanguage, queryFunctionList?.data?.result, queryFunctionList.isSuccess, reduxDispatch]);


  useEffect(() => {
    return () => {
      reduxDispatch(setOpenDialogInfo(false));
      reduxDispatch(setOpenDialogCreate(false));
      reduxDispatch(setOpenDialogEdit(false));
      reduxDispatch(setShowMessageBox(false))
      reduxDispatch(setActionView("LIST"))
    };
  }, [reduxDispatch]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {(actionView === 'LIST') && <ModuleList queryModulePageList={queryModulePageList} queryFunctionList={queryFunctionList} />}
      {(actionView === 'CREATE') && <ModuleCreate queryModulePageList={queryModulePageList} queryFunctionList={queryFunctionList} />}
      {(actionView === 'EDIT') && <ModuleEdit queryModulePageList={queryModulePageList} queryFunctionList={queryFunctionList} />}
      <Footer />
      <MessageNotification
        content={contentMessageBox}
        showMessageBox={showMessageBox}
        setShowMessageBox={setShowMessageBox}
      />
      <ModuleInfo />
      <ModuleCreateModal dialogRef={createModalElementRef} queryModulePageList={queryModulePageList} queryFunctionList={queryFunctionList} />
      <ModuleEditModal dialogRef={editModalElementRef} queryModulePageList={queryModulePageList} queryFunctionList={queryFunctionList} />
    </DashboardLayout>
  );
}

export default Module;
