import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    functionListPage: null,
    functionSelectRow: null,
    functionActionView: 'LIST', //'LIST', 'NEW','EDIT',DELETE, DETAIL
    functionOpenDialogInfo: false, 
    functionOpenDialogCreate: false, 
    functionOpenDialogEdit: false, 
    functionPagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1
    },
    functionSelectPerPage: 20,
    functionListPerPage: [10, 20, 30, 40, 50, 100, 150, 200],
    functionSystemList: null,
    functionSystemSelect: null,
    functionCode: '',
    functionSearch: '',
    functionActiveOnly: false,
    functionStatus: null,
    functionShowDelete: false,
    functionShowMessageBox: false,
    functionContentMessageBox: {
        icon: 'notifications',
        color: 'info',
        openAt: null,
        content: '',
        contentEx: null,
        title: ''
    },
};

export const functionSlice = createSlice({
    name: 'function',
    initialState: initialState,
    reducers: {
        setListPage: (state, action) => {
            state.functionListPage = action.payload;
        },
        setSelectRow: (state, action) => {
            state.functionSelectRow = action.payload;
        },
        setActionView: (state, action) => {
            state.functionActionView = action.payload;
        },
        setOpenDialogInfo: (state, action) => {
            state.functionOpenDialogInfo = action.payload;
        },
        setOpenDialogCreate: (state, action) => {
            state.functionOpenDialogCreate = action.payload;
        },
        setOpenDialogEdit: (state, action) => {
            state.functionOpenDialogEdit = action.payload;
        },
        setSystemList: (state, action) => {
            state.functionSystemList = action.payload;
        },
        setSystemSelect: (state, action) => {
            state.functionSystemSelect = action.payload;
        },
        setPagination: (state, action) => {
            state.functionPagination = { ...state.functionPagination, ...action.payload };
        },
        setSelectPerPage: (state, action) => {
            state.functionSelectPerPage = action.payload;
        },
        setListPerPage: (state, action) => {
            state.functionListPerPage = action.payload;
        },
        setCode: (state, action) => {
            state.functionCode = action.payload;
        },
        setSearch: (state, action) => {
            state.functionSearch = action.payload;
        },
        setActiveOnly: (state, action) => {
            state.functionActiveOnly = action.payload;
        },
        setStatus: (state, action) => {
            state.functionStatus = action.payload;
        },
        setShowDelete: (state, action) => {
            state.functionShowDelete = action.payload;
        },
        setShowMessageBox: (state, action) => {
            state.functionShowMessageBox = action.payload;
        },
        setContentMessageBox: (state, action) => {
            state.functionContentMessageBox = { ...state.functionContentMessageBox, ...action.payload };
        },
    },
})

export const {
    setListPage,
    setSelectRow,
    setActionView,
    setOpenDialogInfo,
    setOpenDialogCreate,
    setOpenDialogEdit,
    setSystemList,
    setSystemSelect,
    setPagination,
    setSelectPerPage,
    setListPerPage,
    setCode,
    setSearch,
    setActiveOnly,
    setStatus,
    setShowDelete,
    setShowMessageBox,
    setContentMessageBox
} = functionSlice.actions

export const getListPage = (state) => state.function.functionListPage
export const getSelectRow = (state) => state.function.functionSelectRow
export const getActionView = (state) => state.function.functionActionView
export const getOpenDialogInfo = (state) => state.function.functionOpenDialogInfo
export const getOpenDialogCreate = (state) => state.function.functionOpenDialogCreate
export const getOpenDialogEdit = (state) => state.function.functionOpenDialogEdit
export const getPagination = (state) => state.function.functionPagination
export const getSelectPerPage = (state) => state.function.functionSelectPerPage
export const getListPerPage = (state) => state.function.functionListPerPage
export const getSystemList = (state) => state.function.functionSystemList
export const getSystemSelect = (state) => state.function.functionSystemSelect
export const getCode = (state) => state.function.functionCode
export const getSearch = (state) => state.function.functionSearch
export const getActiveOnly = (state) => state.function.functionActiveOnly
export const getStatus = (state) => state.function.functionStatus
export const getShowDelete = (state) => state.function.functionShowDelete
export const getShowMessageBox = (state) => state.function.functionShowMessageBox
export const getContentMessageBox = (state) => state.function.functionContentMessageBox
export default functionSlice.reducer