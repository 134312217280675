/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useMemo, useEffect, useRef } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

import { useTranslation } from "react-i18next";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setModuleList,
  setRoleList,
  setRoleSearch,
  setRoleActiveOnly,
  setRoleStatus,
  setRoleShowDelete,
  setRoleListPage,
  setRoleSelectRow,
  setRolePagination,
  setRoleSelectPerPage,
  setRoleListPerPage,
  setListPage,
  setSelectRow,
  setActionView,
  setOpenDialogInfo,
  setOpenDialogCreate,
  setOpenDialogEdit,
  setPagination,
  setSelectPerPage,
  setListPerPage,
  setSearch,
  setActiveOnly,
  setStatus,
  setShowDelete,
  setShowMessageBox,
  setContentMessageBox,
  getListByRole,
  getModuleList,
  getRoleList,
  getRoleSearch,
  getRoleActiveOnly,
  getRoleStatus,
  getRoleShowDelete,
  getRoleListPage,
  getRoleSelectRow,
  getRolePagination,
  getRoleSelectPerPage,
  getRoleListPerPage,
  getListPage,
  getSelectRow,
  getActionView,
  getOpenDialogInfo,
  getOpenDialogCreate,
  getOpenDialogEdit,
  getPagination,
  getSelectPerPage,
  getListPerPage,
  getSearch,
  getActiveOnly,
  getStatus,
  getShowDelete,
  getShowMessageBox,
  getContentMessageBox,
} from "states/features/permission/permissionSlice";
import {
  useGetByIdQuery,
  useGetQuery,
  useGetPageQuery,
  useCreateMutation,
  useUpdateMutation,
  useEditMutation,
  useDestroyMutation,
  useRestoreMutation,
} from "states/services/permission";

// Billing page components
import Grant from "pages/security/authorization/permission/components/PermissionListGrant/Grant";

function GrantList({ listByRole, onSavePermission }) {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const [listGrant, setListGrant] = useState([]);

  //const listByRole = useSelector(getListByRole);
  useEffect(() => {
    if (listByRole) setListGrant(listByRole);
  }, [listByRole]);

  useEffect(() => {}, []);

  return (
    <Card sx={{ height: "100%" }}>
      {listGrant.map((systemData, iSys) => {
        return (
          <>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pt={3}
              px={2}
            >
              <MDTypography
                key={"sys_1_" + iSys + "_" + systemData.code}
                variant="h6"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {systemData.name_en} - {systemData.name_th} - [{" "}
                {systemData.code} ]
              </MDTypography>
              <MDBox display="flex" alignItems="flex-start">
                <MDTypography
                  key={"sys_2_"  + iSys + "_" + systemData.code}
                  variant="button"
                  color="text"
                  fontWeight="regular"
                >
                  {t("pages.permission.label_grant")}
                </MDTypography>
              </MDBox>
            </MDBox>
            {systemData.subRows.map((funcData,iFunc) => {
              return (
                <MDBox pt={3} pb={2} px={2}>
                  <MDBox mb={2}>
                    <MDTypography
                      key={"func_"  + iSys + "_" + iFunc +"_"+  systemData.code + funcData.code}
                      variant="caption"
                      color="text"
                      fontWeight="bold"
                      textTransform="uppercase"
                    >
                      {funcData.name_en} - {funcData.name_th}
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    key={"func_1_" + iSys + "_" + iFunc +"_"+  systemData.code + funcData.code}
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: "none" }}
                  >
                    {funcData.subRows.map((moduleData,iMod) => {
                      return (
                        <Grant
                          key={
                            "grant_"  + iSys + "_" + iFunc +"_"+ iMod + "_" +
                            moduleData.permission.id +
                            moduleData.permission.moduleBy +
                            moduleData.permission.roleBy
                          }
                          onSavePermission={onSavePermission}
                          color="info"
                          icon="app_registration"
                          name={moduleData.name_en}
                          description={moduleData.name_th}
                          value={moduleData.permission.level}
                          data={moduleData.permission}
                        />
                      );
                    })}
                  </MDBox>
                </MDBox>
              );
            })}
          </>
        );
      })}
    </Card>
  );
}

export default GrantList;
