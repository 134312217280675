import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    logListPage: null,
    logSelectRow: null,
    logActionView: 'LIST', //'LIST', 'NEW','EDIT',DELETE, DETAIL
    logOpenDialogInfo: false, 
    logOpenDialogCreate: false, 
    logOpenDialogEdit: false, 
    logPagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1
    },
    logSelectPerPage: 20,
    logListPerPage: [10, 20, 30, 40, 50, 100, 150, 200],
    logSearch: '',
    logActiveOnly: false,
    logIsFail: null,
    logStatus: null,
    logShowDelete: false,
    logShowMessageBox: false,
    logContentMessageBox: {
        icon: 'notifications',
        color: 'info',
        openAt: null,
        content: '',
        contentEx: null,
        title: ''
    },
};

export const logSlice = createSlice({
    name: 'log',
    initialState: initialState,
    reducers: {
        setListPage: (state, action) => {
            state.logListPage = action.payload;
        },
        setSelectRow: (state, action) => {
            state.logSelectRow = action.payload;
        },
        setActionView: (state, action) => {
            state.logActionView = action.payload;
        },
        setOpenDialogInfo: (state, action) => {
            state.logOpenDialogInfo = action.payload;
        },
        setOpenDialogCreate: (state, action) => {
            state.logOpenDialogCreate = action.payload;
        },
        setOpenDialogEdit: (state, action) => {
            state.logOpenDialogEdit = action.payload;
        },
        setPagination: (state, action) => {
            state.logPagination = { ...state.logPagination, ...action.payload };
        },
        setSelectPerPage: (state, action) => {
            state.logSelectPerPage = action.payload;
        },
        setListPerPage: (state, action) => {
            state.logListPerPage = action.payload;
        },
        setSearch: (state, action) => {
            state.logSearch = action.payload;
        },
        setActiveOnly: (state, action) => {
            state.logActiveOnly = action.payload;
        },
        setIsFail: (state, action) => {
            state.logIsFail = action.payload;
        },
        setStatus: (state, action) => {
            state.logStatus = action.payload;
        },
        setShowDelete: (state, action) => {
            state.logShowDelete = action.payload;
        },
        setShowMessageBox: (state, action) => {
            state.logShowMessageBox = action.payload;
        },
        setContentMessageBox: (state, action) => {
            state.logContentMessageBox = { ...state.logContentMessageBox, ...action.payload };
        },
    },
})

export const {
    setListPage,
    setSelectRow,
    setActionView,
    setOpenDialogInfo,
    setOpenDialogCreate,
    setOpenDialogEdit,
    setPagination,
    setSelectPerPage,
    setListPerPage,
    setSearch,
    setActiveOnly,
    setIsFail,
    setStatus,
    setShowDelete,
    setShowMessageBox,
    setContentMessageBox
} = logSlice.actions

export const getListPage = (state) => state.log.logListPage
export const getSelectRow = (state) => state.log.logSelectRow
export const getActionView = (state) => state.log.logActionView
export const getOpenDialogInfo = (state) => state.log.logOpenDialogInfo
export const getOpenDialogCreate = (state) => state.log.logOpenDialogCreate
export const getOpenDialogEdit = (state) => state.log.logOpenDialogEdit
export const getPagination = (state) => state.log.logPagination
export const getSelectPerPage = (state) => state.log.logSelectPerPage
export const getListPerPage = (state) => state.log.logListPerPage
export const getSearch = (state) => state.log.logSearch
export const getActiveOnly = (state) => state.log.logActiveOnly
export const getIsFail = (state) => state.log.logIsFail
export const getStatus = (state) => state.log.logStatus
export const getShowDelete = (state) => state.log.logShowDelete
export const getShowMessageBox = (state) => state.log.logShowMessageBox
export const getContentMessageBox = (state) => state.log.logContentMessageBox
export default logSlice.reducer