
import { useState, useMemo, useEffect, useRef } from "react";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useTranslation } from "react-i18next";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setShowMessageBox,
  setListPage,
  setActionView,
  setPagination,
  getOpenDialogInfo,
  setOpenDialogInfo,
  getOpenDialogCreate,
  setOpenDialogCreate,
  getOpenDialogEdit,
  setOpenDialogEdit,
  getActionView,
  getPagination,
  getSelectPerPage,
  getSearch,
  getIsFail,
  getStatus,
  getShowDelete,
  getShowMessageBox,
  getContentMessageBox,
} from "states/features/log/logSlice";

import {
  useGetQuery,
  useGetPageQuery,
} from "states/services/log";

// MY 
import MessageNotification from "components/HRComponents/MessageNotification";

import LogsList from "pages/security/monitor/logs/components/LogsList";
import LogsInfo from "pages/security/monitor/logs/components/LogsInfo";
import LogsCreate from "pages/security/monitor/logs/components/LogsCreate";
import LogsEdit from "pages/security/monitor/logs/components/LogsEdit";
import LogsEditModal from "pages/security/monitor/logs/components/LogsEditModal";
import LogsCreateModal from "pages/security/monitor/logs/components/LogsCreateModal";

function Logs() {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const actionView = useSelector(getActionView);
  const showMessageBox = useSelector(getShowMessageBox);
  const contentMessageBox = useSelector(getContentMessageBox);
  const search = useSelector(getSearch);
  const isFail = useSelector(getIsFail);
  const status = useSelector(getStatus);
  const showDelete = useSelector(getShowDelete);
  const pagination = useSelector(getPagination);
  const selectPerPage = useSelector(getSelectPerPage);

  const createModalElementRef = useRef(null);
  const editModalElementRef = useRef(null);

  const queryLogsPageList = useGetPageQuery({
    isActive: status === null ? undefined : status,
    isFails: isFail === null ? undefined : isFail,
    hiddenDelete: showDelete ? false : undefined,
    textSearch: search,
    page: pagination?.currentPage || 1,
    size: selectPerPage || 50,
  }
    // , {
    //   refetchOnMountOrArgChange: true,
    //   //pollingInterval: 3000
    // }
  );

  useEffect(() => {
    if (queryLogsPageList.isSuccess) {
      reduxDispatch(setListPage(queryLogsPageList?.data?.result))
      reduxDispatch(setPagination({
        totalItems: queryLogsPageList?.data?.result?.totalItems,
        totalPages: queryLogsPageList?.data?.result?.totalPages
      }))
    }
  }, [queryLogsPageList.data?.result, queryLogsPageList.isSuccess, reduxDispatch]);


  useEffect(() => {
    return () => {
      reduxDispatch(setOpenDialogInfo(false));
      reduxDispatch(setOpenDialogCreate(false));
      reduxDispatch(setOpenDialogEdit(false));
      reduxDispatch(setShowMessageBox(false))
      reduxDispatch(setActionView("LIST"))
    };
  }, [reduxDispatch]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {(actionView === 'LIST') && <LogsList queryLogsPageList={queryLogsPageList} />}
      {(actionView === 'CREATE') && <LogsCreate queryLogsPageList={queryLogsPageList} />}
      {(actionView === 'EDIT') && <LogsEdit queryLogsPageList={queryLogsPageList} />}
      <Footer />
      <MessageNotification
        content={contentMessageBox}
        showMessageBox={showMessageBox}
        setShowMessageBox={setShowMessageBox}
      />
      <LogsInfo />
      <LogsCreateModal dialogRef={createModalElementRef} queryLogsPageList={queryLogsPageList} />
      <LogsEditModal dialogRef={editModalElementRef} queryLogsPageList={queryLogsPageList} />
    </DashboardLayout>
  );
}

export default Logs;
