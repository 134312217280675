/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import BuildIcon from "@mui/icons-material/Build";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDDropzone from "components/MDDropzone";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import _ from "lodash";

import profilePicture from "assets/images/team-3.jpg";
import { ProfileModifyModel} from "models/index"

import {
  FormInputField,
  FormSelectField,
  FormSwitchBoxField,
} from "components/HRComponents/FormField";

import {
  setShowMessageBox,
  setContentMessageBox,
  setUpdateAvatar,
  setUpdateProfile,
  selectAuth,
} from "states/features/auth/authSlice";

import { DateTime } from "luxon";

import { useUpdateProfileMutation } from "states/services/authentication";

function EditProfileInfoCard({ title, action, shadow, setShowEditPage }) {
  const labels = [];
  const values = [];
  const { socialMediaColors } = colors;
  const { size } = typography;
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const userAuth = useSelector(selectAuth);

  // 👇 API Login Mutation
  const [UpdateProfile, UpdateProfileStatus] = useUpdateProfileMutation();

  const schema = z
    .object({
      profileDecsriptionTH: z.string().nullish(),
      profileDecsriptionEN: z.string().nullish(),
      profileFullnameTH: z.string({
        required_error: "Profile full name is required",
        invalid_type_error: "Profile full name is required",
      }),
      profileFullnameEN: z.string({
        required_error: "Profile full name is required",
        invalid_type_error: "Profile full name is required",
      }),
      profileMobile: z.string({
        required_error: "Profile mobile is required",
        invalid_type_error: "Profile mobile is required",
      }),
      profileEmail: z.string({
        required_error: "Profile e-mail is required",
        invalid_type_error: "Profile e-mail is required",
      }),
      profileFacebook:  z.string().nullish(),
      profileInstragram:  z.string().nullish(),
      profileTwitter:  z.string().nullish(),
      profileLine: z.string().nullish()
    });

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      profileDecsriptionTH: null,
      profileDecsriptionEN: null,
      profileFullnameTH: null,
      profileFullnameEN: null,
      profileMobile: null,
      profileEmail: null,
      profileFacebook: null,
      profileInstragram: null,
      profileTwitter: null,
      profileLine: null,
    },
  });

  useEffect(() => {
    setValue("profileDecsriptionTH", userAuth?.user?.description_th)
    setValue("profileDecsriptionEN", userAuth?.user?.description_en)
    setValue("profileFullnameTH", userAuth?.user?.name_th)
    setValue("profileFullnameEN", userAuth?.user?.name_en)
    setValue("profileMobile", userAuth?.user?.phone)
    setValue("profileEmail", userAuth?.user?.email)
    setValue("profileFacebook", userAuth?.user?.facebook)
    setValue("profileInstragram", userAuth?.user?.instragram)
    setValue("profileTwitter", userAuth?.user?.twitter)
    setValue("profileLine", userAuth?.user?.line)
  }, []);

  const onSubmit = async (data) => {
    let  profileDataUpdate =  ProfileModifyModel.parse({
      name_th: data.profileFullnameTH !== userAuth.user.name_th ? data.profileFullnameTH  : null,
      name_en: data.profileFullnameEN !== userAuth.user.name_en ? data.profileFullnameEN  : null,
      description_th: data.profileDecsriptionTH !== userAuth.user.description_th ? data.profileDecsriptionTH  : null,
      description_en: data.profileDecsriptionEN !== userAuth.user.description_en ? data.profileDecsriptionEN  : null,
      phone:data.profileMobile !== userAuth.user.phone ? data.profileMobile  : null,
      twetter: data.profileTwitter !== userAuth.user.twetter ? data.profileTwitter  : null,
      line: data.profileLine !== userAuth.user.line ? data.profileLine  : null,
      instragram: data.profileInstragram !== userAuth.user.instragram ? data.profileInstragram  : null,
      facebook: data.profileFacebook !== userAuth.user.facebook ? data.profileFacebook  : null,
      //version: userAuth.user.version 
    })
   
    if (_.isEmpty(_.pickBy(profileDataUpdate))){
      await reduxDispatch(
        setContentMessageBox({
          //icon: 'notifications',
          openAt: DateTime.now(),
          color: "warning",
          title:  t("pages.profile.change_profile_lable"),
          content: t("pages.profile.message.profile_modify"),
          contentEx: t("pages.profile.message.profile_modify"),
        })
      );
      await reduxDispatch(setShowMessageBox(true));
      return;
    }
    profileDataUpdate.version = userAuth.user.version 

    const resUpdateProfile = await UpdateProfile(_.pickBy(profileDataUpdate));
    if (resUpdateProfile.error) {
      await reduxDispatch(
        setContentMessageBox({
          //icon: 'notifications',
          openAt: DateTime.now(),
          color: "warning",
          title: t("pages.profile.change_profile_lable"),
          content: resUpdateProfile.error.data.message,
          contentEx: resUpdateProfile.error.data.messageEx,
        })
      );
      await reduxDispatch(setShowMessageBox(true));
      return;
    }

    await reduxDispatch(setUpdateProfile(resUpdateProfile.data.result));

    await reduxDispatch(
      setContentMessageBox({
        //icon: 'notifications',
        openAt: DateTime.now(),
        color: "success",
        title: t("pages.profile.change_profile_lable"),
        content: resUpdateProfile.data.message,
        contentEx: null,
      })
    );
    reduxDispatch(setShowMessageBox(true));
    setShowEditPage(false);
    return;
  
   // console.log("edit profileUpdate=======>", profileDataUpdate);
   // console.log("edit _ =======>",_.pickBy(profileDataUpdate));
    //reset();
  };
  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </MDTypography>
        <MDTypography
          onClick={action.handleClick}
          variant="body2"
          color="secondary"
        >
          <Tooltip title={action.tooltip} placement="top">
            <Icon>close</Icon>
          </Tooltip>
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <form onSubmit={handleSubmit(onSubmit)} fullWidth>
          <MDBox pb={1} px={1}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormInputField
                  InputLabelProps={{ shrink: true }}
                  label={t("pages.profile.change_profile_decsription_th")}
                  name="profileDecsriptionTH"
                  {...register("profileDecsriptionTH")}
                  control={control}
                  errors={errors}
                  multiline
                  rows={3}
                  type="text"
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputField
                  InputLabelProps={{ shrink: true }}
                  label={t("pages.profile.change_profile_decsription_en")}
                  name="profileDecsriptionEN"
                  {...register("profileDecsriptionEN")}
                  control={control}
                  errors={errors}
                  multiline
                  rows={3}
                  type="text"
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputField
                  InputLabelProps={{ shrink: true }}
                  label={t("pages.profile.change_profile_fullname_th")}
                  name="profileFullnameTH"
                  {...register("profileFullnameTH")}
                  control={control}
                  errors={errors}
                  type="test"
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputField
                  InputLabelProps={{ shrink: true }}
                  label={t("pages.profile.change_profile_fullname_en")}
                  name="profileFullnameEN"
                  {...register("profileFullnameEN")}
                  control={control}
                  errors={errors}
                  type="test"
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputField
                  InputLabelProps={{ shrink: true }}
                  label={t("pages.profile.change_profile_mobile")}
                  name="profileMobile"
                  {...register("profileMobile")}
                  control={control}
                  errors={errors}
                  type="test"
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputField
                  InputLabelProps={{ shrink: true }}
                  label={t("pages.profile.change_profile_email")}
                  name="profileEmail"
                  {...register("profileEmail")}
                  control={control}
                  errors={errors}
                  type="test"
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputField
                  InputLabelProps={{ shrink: true }}
                  label={t("pages.profile.change_profile_facebook")}
                  name="profileFacebook"
                  {...register("profileFacebook")}
                  control={control}
                  errors={errors}
                  type="test"
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputField
                  InputLabelProps={{ shrink: true }}
                  label={t("pages.profile.change_profile_instragram")}
                  name="profileInstragram"
                  {...register("profileInstragram")}
                  control={control}
                  errors={errors}
                  type="test"
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputField
                  InputLabelProps={{ shrink: true }}
                  label={t("pages.profile.change_profile_twitter")}
                  name="profileTwitter"
                  {...register("profileTwitter")}
                  control={control}
                  errors={errors}
                  type="test"
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputField
                  InputLabelProps={{ shrink: true }}
                  label={t("pages.profile.change_profile_line")}
                  name="profileLine"
                  {...register("profileLine")}
                  control={control}
                  errors={errors}
                  type="test"
                />
              </Grid>
            </Grid>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox ml="auto">
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                >
                  {t("pages.profile.change_profile_lable")}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </form>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
EditProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
EditProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.shape({
    handleClick: PropTypes.any.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
  setShowEditPage: PropTypes.any,
};

export default EditProfileInfoCard;
