/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useMemo, useEffect, useRef, useCallback } from "react";

import { useTranslation } from "react-i18next";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';

// @mui material components
import { IconButton } from '@mui/material';
import RuleIcon from '@mui/icons-material/Rule';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import SecurityIcon from '@mui/icons-material/Security';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  ChangeCircle as ChangeCircleIcon,
  RestoreFromTrash as RestoreFromTrashIcon,
  Sync as SyncIcon,
  Info as InfoIcon,
} from '@mui/icons-material';


// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setModuleList,
  setRoleList,
  setRoleSearch,
  setRoleActiveOnly,
  setRoleStatus,
  setRoleShowDelete,
  setRoleListPage,
  setRoleSelectRow,
  setRolePagination,
  setRoleSelectPerPage,
  setRoleListPerPage,
  setListPage,
  setSelectRow,
  setActionView,
  setOpenDialogInfo,
  setOpenDialogCreate,
  setOpenDialogEdit,
  setPagination,
  setSelectPerPage,
  setListPerPage,
  setSearch,
  setActiveOnly,
  setStatus,
  setShowDelete,
  setShowMessageBox,
  setContentMessageBox,
  getModuleList,
  getRoleList,
  getRoleSearch,
  getRoleActiveOnly,
  getRoleStatus,
  getRoleShowDelete,
  getRoleListPage,
  getRoleSelectRow,
  getRolePagination,
  getRoleSelectPerPage,
  getRoleListPerPage,
  getListPage,
  getSelectRow,
  getActionView,
  getOpenDialogInfo,
  getOpenDialogCreate,
  getOpenDialogEdit,
  getPagination,
  getSelectPerPage,
  getListPerPage,
  getSearch,
  getActiveOnly,
  getStatus,
  getShowDelete,
  getShowMessageBox,
  getContentMessageBox,
} from "states/features/permission/permissionSlice";
import { getDisplayModalForm } from "states/features/configuration/configurationSlice";
import {
  useGetByIdQuery,
  useGetQuery,
  useGetPageQuery,
  useCreateMutation,
  useUpdateMutation,
  useEditMutation,
  useDestroyMutation,
  useRestoreMutation,
} from "states/services/permission";

// MY 
//import DataGrid from "components/HRComponents/DataGrid";
import { DataGrid } from "components/HRComponents/DataGrid.v2";
import { DateTime } from "luxon";
import MDButton from "components/MDButton";
import PermissionListRole from "pages/security/authorization/permission/components/PermissionListRole";
import PermissionListGrant from "pages/security/authorization/permission/components/PermissionListGrant";

function PermissionList({ queryPermissionPageList, queryPermissionRolePageList ,queryPermissionByRoleList}) {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const [textSearch, setTextSearch] = useState('');
  const [textCode, setTextCode] = useState('');

  // 👇 API Login Mutation

  const search = useSelector(getSearch);
  const status = useSelector(getStatus);
  const showDelete = useSelector(getShowDelete);
  const displayModalForm = useSelector(getDisplayModalForm);

  const ListPagePermission = useSelector(getListPage);


  // 👇 API Login Mutation
  const [edit, resultEdit] = useEditMutation();
  const [destroy, resultDestroy] = useDestroyMutation();
  const [restore, resultRestore] = useRestoreMutation();

  return (
    <Grid container spacing={3} >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
        <MDBox py={3}>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="success"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  <RuleIcon />
                </Icon>
              </MDBox>
              <MDBox>
                <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                  {t('pages.role.list_title')}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox p={2} >
              <PermissionListRole
                queryPermissionPageList={queryPermissionPageList}
                queryPermissionRolePageList={queryPermissionRolePageList}
                queryPermissionByRoleList={queryPermissionByRoleList}
              />

            </MDBox>
          </Card>
        </MDBox>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
        <MDBox py={3}>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="primary"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  <SecurityIcon />
                </Icon>
              </MDBox>
              <MDBox>
                <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                  {t('pages.permission.list_title')}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox p={2} >
              <PermissionListGrant
                queryPermissionPageList={queryPermissionPageList}
                queryPermissionRolePageList={queryPermissionRolePageList}
                queryPermissionByRoleList={queryPermissionByRoleList}
              />
            </MDBox>
          </Card>
        </MDBox>
      </Grid>
    </Grid>
  );
}

export default PermissionList;
