import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userListPage: null,
    userSelectRow: null,
    userActionView: 'LIST', //'LIST', 'NEW','EDIT',DELETE, DETAIL
    userOpenDialogInfo: false, 
    userOpenDialogCreate: false, 
    userOpenDialogEdit: false, 
    userOpenDialogRight: false, 
    userPagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1
    },
    userSelectPerPage: 20,
    userListPerPage: [10, 20, 30, 40, 50, 100, 150, 200],
    userCompanyList: null,
    userCompanySelect: null,
    userPermissionList: null,
    userUsername: '',
    userSearch: '',
    userActiveOnly: false,
    userStatus: null,
    userShowDelete: false,
    userShowMessageBox: false,
    userContentMessageBox: {
        icon: 'notifications',
        color: 'info',
        openAt: null,
        content: '',
        contentEx: null,
        title: ''
    },
};

export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setListPage: (state, action) => {
            state.userListPage = action.payload;
        },
        setSelectRow: (state, action) => {
            state.userSelectRow = action.payload;
        },
        setActionView: (state, action) => {
            state.userActionView = action.payload;
        },
        setOpenDialogInfo: (state, action) => {
            state.userOpenDialogInfo = action.payload;
        },
        setOpenDialogCreate: (state, action) => {
            state.userOpenDialogCreate = action.payload;
        },
        setOpenDialogEdit: (state, action) => {
            state.userOpenDialogEdit = action.payload;
        },
        setOpenDialogRight: (state, action) => {
            state.userOpenDialogRight = action.payload;
        },
        setCompanyList: (state, action) => {
            state.userCompanyList = action.payload;
        },
        setCompanySelect: (state, action) => {
            state.userCompanySelect = action.payload;
        },
        setPermissionList: (state, action) => {
            state.userPermissionList = action.payload;
        },
        setPagination: (state, action) => {
            state.userPagination = { ...state.userPagination, ...action.payload };
        },
        setSelectPerPage: (state, action) => {
            state.userSelectPerPage = action.payload;
        },
        setListPerPage: (state, action) => {
            state.userListPerPage = action.payload;
        },
        setUsername: (state, action) => {
            state.userUsername = action.payload;
        },
        setSearch: (state, action) => {
            state.userSearch = action.payload;
        },
        setActiveOnly: (state, action) => {
            state.userActiveOnly = action.payload;
        },
        setStatus: (state, action) => {
            state.userStatus = action.payload;
        },
        setShowDelete: (state, action) => {
            state.userShowDelete = action.payload;
        },
        setShowMessageBox: (state, action) => {
            state.userShowMessageBox = action.payload;
        },
        setContentMessageBox: (state, action) => {
            state.userContentMessageBox = { ...state.userContentMessageBox, ...action.payload };
        },
    },
})

export const {
    setListPage,
    setSelectRow,
    setActionView,
    setOpenDialogInfo,
    setOpenDialogCreate,
    setOpenDialogEdit,
    setOpenDialogRight,
    setCompanyList,
    setCompanySelect,
    setPermissionList,
    setPagination,
    setSelectPerPage,
    setListPerPage,
    setUsername,
    setSearch,
    setActiveOnly,
    setStatus,
    setShowDelete,
    setShowMessageBox,
    setContentMessageBox
} = userSlice.actions

export const getListPage = (state) => state.user.userListPage
export const getSelectRow = (state) => state.user.userSelectRow
export const getActionView = (state) => state.user.userActionView
export const getOpenDialogInfo = (state) => state.user.userOpenDialogInfo
export const getOpenDialogCreate = (state) => state.user.userOpenDialogCreate
export const getOpenDialogEdit = (state) => state.user.userOpenDialogEdit
export const getOpenDialogRight = (state) => state.user.userOpenDialogRight
export const getPagination = (state) => state.user.userPagination
export const getSelectPerPage = (state) => state.user.userSelectPerPage
export const getListPerPage = (state) => state.user.userListPerPage
export const getCompanyList = (state) => state.user.userCompanyList
export const getCompanySelect = (state) => state.user.userCompanySelect
export const getPermissionList = (state) => state.user.userPermissionList
export const getUsername = (state) => state.user.userUsername
export const getSearch = (state) => state.user.userSearch
export const getActiveOnly = (state) => state.user.userActiveOnly
export const getStatus = (state) => state.user.userStatus
export const getShowDelete = (state) => state.user.userShowDelete
export const getShowMessageBox = (state) => state.user.userShowMessageBox
export const getContentMessageBox = (state) => state.user.userContentMessageBox
export default userSlice.reducer