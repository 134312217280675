
import { useState, useMemo, useEffect, useRef } from "react";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useTranslation } from "react-i18next";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setShowMessageBox,
  setListPage,
  setActionView,
  setPagination,
  getOpenDialogInfo,
  setOpenDialogInfo,
  getOpenDialogCreate,
  setOpenDialogCreate,
  getOpenDialogEdit,
  setOpenDialogEdit,
  getActionView,
  getPagination,
  getSelectPerPage,
  getSearch,
  getStatus,
  getShowDelete,
  getShowMessageBox,
  getContentMessageBox,
} from "states/features/role/roleSlice";

import {
  useGetPageQuery,
} from "states/services/role";

// MY 
import MessageNotification from "components/HRComponents/MessageNotification";

import RoleList from "pages/security/account/role/components/RoleList";
import RoleInfo from "pages/security/account/role/components/RoleInfo";
import RoleCreate from "pages/security/account/role/components/RoleCreate";
import RoleEdit from "pages/security/account/role/components/RoleEdit";
import RoleEditModal from "pages/security/account/role/components/RoleEditModal";
import RoleCreateModal from "pages/security/account/role/components/RoleCreateModal";

function Role() {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const actionView = useSelector(getActionView);
  const showMessageBox = useSelector(getShowMessageBox);
  const contentMessageBox = useSelector(getContentMessageBox);
  const search = useSelector(getSearch);
  const status = useSelector(getStatus);
  const showDelete = useSelector(getShowDelete);
  const pagination = useSelector(getPagination);
  const selectPerPage = useSelector(getSelectPerPage);
  const createModalElementRef = useRef(null);
  const editModalElementRef = useRef(null);
  
  const queryRolePageList = useGetPageQuery({
    isActive: status === null ? undefined : status,
    hiddenDelete: showDelete ? false : undefined,
    textSearch: search,
    page: pagination?.currentPage || 1,
    size: selectPerPage || 50,
  }
    // , {
    //   refetchOnMountOrArgChange: true,
    //   //pollingInterval: 3000
    // }
  );

  useEffect(() => {
    if (queryRolePageList.isSuccess) {
      reduxDispatch(setListPage(queryRolePageList?.data?.result))
      reduxDispatch(setPagination({
        totalItems: queryRolePageList?.data?.result?.totalItems,
        totalPages: queryRolePageList?.data?.result?.totalPages
      }))
    }
  }, [queryRolePageList.data?.result, queryRolePageList.isSuccess, reduxDispatch]);


  useEffect(() => {
    return () => {
      reduxDispatch(setOpenDialogInfo(false));
      reduxDispatch(setOpenDialogCreate(false));
      reduxDispatch(setOpenDialogEdit(false));
      reduxDispatch(setShowMessageBox(false))
      reduxDispatch(setActionView("LIST"))
    };
  }, [reduxDispatch]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {(actionView === 'LIST') && <RoleList queryRolePageList={queryRolePageList} />}
      {(actionView === 'CREATE') && <RoleCreate queryRolePageList={queryRolePageList} />}
      {(actionView === 'EDIT') && <RoleEdit queryRolePageList={queryRolePageList} />}
      <Footer />
      <MessageNotification
        content={contentMessageBox}
        showMessageBox={showMessageBox}
        setShowMessageBox={setShowMessageBox}
      />
      <RoleInfo /> 
      <RoleCreateModal dialogRef={createModalElementRef} queryRolePageList={queryRolePageList} />
      <RoleEditModal dialogRef={editModalElementRef} queryRolePageList={queryRolePageList} />
    </DashboardLayout>
  );
}

export default Role;
