/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useMemo, useEffect, useRef, useCallback } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// @mui material components
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import RuleIcon from '@mui/icons-material/Rule';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// redux
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setActionView,
  setPagination,
  setSelectPerPage,
  setSearch,
  setStatus,
  setShowDelete,
  setSelectRow,
  getListPage,
  getPagination,
  getSelectPerPage,
  getListPerPage,
  getSearch,
  getStatus,
  getShowDelete,
  setContentMessageBox,
  setShowMessageBox,
  getOpenDialogInfo,
  setOpenDialogInfo,
  getOpenDialogCreate,
  setOpenDialogCreate,
  getOpenDialogEdit,
  setOpenDialogEdit,
  setRoleListPage,
  getRoleListPage,
  setRolePagination,
  getRolePagination,
  getRoleSelectPerPage,
  getRoleSearch,
  setRoleSearch,
  getRoleStatus,
  setRoleStatus,
  getRoleShowDelete,
  setRoleShowDelete,
  setUserListPage,
  setUserPagination,
  getUserPagination,
  getUserSelectPerPage,
  getUserSearch,
  getUserStatus,
  getUserShowDelete,
  getRoleListPerPage,
  setRoleSelectPerPage,
  getRoleSelectRow,
} from "states/features/privileges/privilegesSlice";
import { getDisplayModalForm } from "states/features/configuration/configurationSlice";
import {
  useGetByIdQuery,
  useGetQuery,
  useGetPageQuery,
  useCreateMutation,
  useUpdateMutation,
  useEditMutation,
  useDestroyMutation,
  useRestoreMutation,
} from "states/services/privileges";

// MY 
import RoleTabManager from "pages/security/authorization/privileges/components/PrivilegesList/components/RoleTabManager";
import UserTabManager from "pages/security/authorization/privileges/components/PrivilegesList/components/UserTabManager";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MDBox sx={{ p: 3 }}>
          <MDTypography>{children}</MDTypography>
        </MDBox>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function PrivilegesList({ queryPrivilegesPageList, queryPrivilegesRolePageList, queryPrivilegesUserPageList, queryPrivilegesByRoleList, queryPrivilegesByUserList }) {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const displayModalForm = useSelector(getDisplayModalForm);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MDBox fullWidth>
      <AppBar position="static">

        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="basic tabs example">
          <Tab label={<MDTypography variant="h6" fontSize="medium" color="inherit">{t('pages.privileges.tab.role_title')}</MDTypography>} {...a11yProps(0)} />
          <Tab label={<MDTypography variant="h6" fontSize="medium" color="inherit">{t('pages.privileges.tab.user_title')}</MDTypography>} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <CustomTabPanel value={value} index={0}>
        <RoleTabManager queryPrivilegesPageList={queryPrivilegesPageList}
          queryPrivilegesRolePageList={queryPrivilegesRolePageList}
          queryPrivilegesUserPageList={queryPrivilegesUserPageList}
          queryPrivilegesByRoleList={queryPrivilegesByRoleList}
          queryPrivilegesByUserList={queryPrivilegesByUserList}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <UserTabManager queryPrivilegesPageList={queryPrivilegesPageList}
          queryPrivilegesRolePageList={queryPrivilegesRolePageList}
          queryPrivilegesUserPageList={queryPrivilegesUserPageList}
          queryPrivilegesByRoleList={queryPrivilegesByRoleList}
          queryPrivilegesByUserList={queryPrivilegesByUserList}
        />
      </CustomTabPanel>
    </MDBox>
  );
}

export default PrivilegesList;
