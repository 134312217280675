import { useState, useMemo, useEffect, useRef, useCallback } from "react";


// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useTranslation } from "react-i18next";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';

// @mui material components
import { IconButton } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  ChangeCircle as ChangeCircleIcon,
  RestoreFromTrash as RestoreFromTrashIcon,
  Sync as SyncIcon,
  Info as InfoIcon,
} from '@mui/icons-material';

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setListPage,
  setActionView,
  setHeadOfficeList,
  setPagination,
  getActionView,
  getPagination,
  getSelectPerPage,
  getHeadOfficeSelect,
  getCode,
  getSearch,
  getStatus,
  getShowDelete,
  getShowMessageBox,
  getContentMessageBox,
  setShowMessageBox,
  setContentMessageBox,
  getOpenDialogInfo,
  setOpenDialogInfo,
  getOpenDialogCreate,
  setOpenDialogCreate,
  getOpenDialogEdit,
  setOpenDialogEdit,
  setHeadOfficeSelect,
  setSelectPerPage,
  setCode,
  setSearch,
  setStatus,
  setShowDelete,
  setSelectRow,
  getListPage,
  getListPerPage,
  getHeadOfficeList,
} from "states/features/company/companySlice";


import {
  useGetByIdQuery,
  useGetQuery,
  useGetPageQuery,
  useCreateMutation,
  useUpdateMutation,
  useEditMutation,
  useDestroyMutation,
  useRestoreMutation,
} from "states/services/company";

// MY 
import MessageNotification from "components/HRComponents/MessageNotification";

import CompanyList from "pages/security/account/company/components/CompanyList";
import CompanyInfo from "pages/security/account/company/components/CompanyInfo";
import CompanyCreate from "pages/security/account/company/components/CompanyCreate";
import CompanyEdit from "pages/security/account/company/components/CompanyEdit";
import CompanyEditModal from "pages/security/account/company/components/CompanyEditModal";
import CompanyCreateModal from "pages/security/account/company/components/CompanyCreateModal";

import { DateTime } from "luxon";


// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

function Company() {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const actionView = useSelector(getActionView);
  const showMessageBox = useSelector(getShowMessageBox);
  const contentMessageBox = useSelector(getContentMessageBox);
  const search = useSelector(getSearch);
  const headOfficeBy = useSelector(getHeadOfficeSelect);
  const code = useSelector(getCode);
  const status = useSelector(getStatus);
  const showDelete = useSelector(getShowDelete);
  const pagination = useSelector(getPagination);
  const selectPerPage = useSelector(getSelectPerPage);

  const createModalElementRef = useRef(null);
  const editModalElementRef = useRef(null);

  // 👇 API Login Mutation
  const [edit, resultEdit] = useEditMutation();
  const [destroy, resultDestroy] = useDestroyMutation();
  const [restore, resultRestore] = useRestoreMutation();

  const queryCompanyAllList = useGetQuery(
    // {
    //   refetchOnMountOrArgChange: true,
    //   //pollingInterval: 3000
    // }
  );


  const queryCompanyPageList = useGetPageQuery({
    isActive: status === null ? undefined : status,
    hiddenDelete: showDelete ? false : undefined,
    textSearch: search,
    headBy: headOfficeBy?.id,
    code: code === '' ? undefined : code,
    page: pagination?.currentPage || 1,
    size: selectPerPage || 20,
  }
    // , {
    //   refetchOnMountOrArgChange: true,
    //   //pollingInterval: 3000
    // }
  );

  useEffect(() => {
    if (queryCompanyPageList.isSuccess) {
      reduxDispatch(setListPage(queryCompanyPageList?.data?.result))
      reduxDispatch(setPagination({
        totalItems: queryCompanyPageList?.data?.result?.totalItems,
        totalPages: queryCompanyPageList?.data?.result?.totalPages
      }))
    }
  }, [queryCompanyPageList.data?.result, queryCompanyPageList.isSuccess, reduxDispatch]);

  useEffect(() => {
    if (queryCompanyAllList.isSuccess) {
      let comList = [];
      queryCompanyAllList.data.result.forEach(com => {
        comList.push({ label: ' [' + com["code"] + '] ' + com["name_" + i18n.resolvedLanguage], id: com.id })
      });
      reduxDispatch(setHeadOfficeList(comList))
    }
  }, [queryCompanyAllList.data, queryCompanyAllList.isSuccess, i18n.resolvedLanguage, reduxDispatch]);


  useEffect(() => {
    return () => {
      reduxDispatch(setOpenDialogInfo(false));
      reduxDispatch(setOpenDialogCreate(false));
      reduxDispatch(setOpenDialogEdit(false));
      reduxDispatch(setShowMessageBox(false))
      reduxDispatch(setActionView("LIST"))
    };
  }, [reduxDispatch]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {(actionView === 'LIST') && <CompanyList queryCompanyPageList={queryCompanyPageList} queryCompanyAllList={queryCompanyAllList} />}
      {(actionView === 'CREATE') && <CompanyCreate queryCompanyPageList={queryCompanyPageList} queryCompanyAllList={queryCompanyAllList} />}
      {(actionView === 'EDIT') && <CompanyEdit queryCompanyPageList={queryCompanyPageList} queryCompanyAllList={queryCompanyAllList} />}
      <Footer />
      <MessageNotification
        content={contentMessageBox}
        showMessageBox={showMessageBox}
        setShowMessageBox={setShowMessageBox}
      />
      <CompanyInfo />
      <CompanyCreateModal dialogRef={createModalElementRef} queryCompanyPageList={queryCompanyPageList} queryCompanyAllList={queryCompanyAllList}/>
      <CompanyEditModal dialogRef={editModalElementRef} queryCompanyPageList={queryCompanyPageList} queryCompanyAllList={queryCompanyAllList}/>
    </DashboardLayout>
  );
}

export default Company;
