
import { useState, useMemo, useEffect, useRef } from "react";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useTranslation } from "react-i18next";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setListByRole,
  setModuleList,
  setRoleList,
  setRoleSearch,
  setRoleActiveOnly,
  setRoleStatus,
  setRoleShowDelete,
  setRoleListPage,
  setRoleSelectRow,
  setRolePagination,
  setRoleSelectPerPage,
  setRoleListPerPage,
  setListPage,
  setSelectRow,
  setActionView,
  setOpenDialogInfo,
  setOpenDialogCreate,
  setOpenDialogEdit,
  setPagination,
  setSelectPerPage,
  setListPerPage,
  setSearch,
  setActiveOnly,
  setStatus,
  setShowDelete,
  setShowMessageBox,
  setContentMessageBox,
  getListByRole,
  getModuleList,
  getRoleList,
  getRoleSearch,
  getRoleActiveOnly,
  getRoleStatus,
  getRoleShowDelete,
  getRoleListPage,
  getRoleSelectRow,
  getRolePagination,
  getRoleSelectPerPage,
  getRoleListPerPage,
  getListPage,
  getSelectRow,
  getActionView,
  getOpenDialogInfo,
  getOpenDialogCreate,
  getOpenDialogEdit,
  getPagination,
  getSelectPerPage,
  getListPerPage,
  getSearch,
  getActiveOnly,
  getStatus,
  getShowDelete,
  getShowMessageBox,
  getContentMessageBox,
} from "states/features/permission/permissionSlice";

import {
  useGetRoleQuery,
  useGetModuleQuery,
  useGetListByRoleNestQuery,
  useGetListByRoleQuery,
  useGetByIdQuery,
  useGetQuery,
  useGetRolePageQuery,
  useGetPageQuery,
  useCreateMutation,
  useUpdateMutation,
  useAddListMutation,
  useRemoveListMutation,
  useEditMutation,
  useDestroyMutation,
  useRestoreMutation,
} from "states/services/permission";

// MY 
import MessageNotification from "components/HRComponents/MessageNotification";
import PermissionList from "pages/security/authorization/permission/components/PermissionList";

function Permission() {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  
  const listByRole = useSelector(getListByRole);
  const actionView = useSelector(getActionView);
  const showMessageBox = useSelector(getShowMessageBox);
  const contentMessageBox = useSelector(getContentMessageBox);
  const search = useSelector(getSearch);
  const status = useSelector(getStatus);
  const showDelete = useSelector(getShowDelete);
  const pagination = useSelector(getPagination);
  const selectPerPage = useSelector(getSelectPerPage);
  const createModalElementRef = useRef(null);
  const editModalElementRef = useRef(null);

  const queryPermissionPageList = useGetPageQuery({
    isActive: status === null ? undefined : status,
    hiddenDelete: showDelete ? false : undefined,
    textSearch: search,
    page: pagination?.currentPage || 1,
    size: selectPerPage || 50,
  }
    // , {
    //   refetchOnMountOrArgChange: true,
    //   //pollingInterval: 3000
    // }
  );

  useEffect(() => {
    if (queryPermissionPageList.isSuccess) {
      reduxDispatch(setListPage(queryPermissionPageList?.data?.result))
      reduxDispatch(setPagination({
        totalItems: queryPermissionPageList?.data?.result?.totalItems,
        totalPages: queryPermissionPageList?.data?.result?.totalPages
      }))
    }
  }, [queryPermissionPageList.data?.result, queryPermissionPageList.isSuccess, reduxDispatch]);

  const searchRole = useSelector(getRoleSearch);
  const statusRole = useSelector(getRoleStatus);
  const showDeleteRole = useSelector(getRoleShowDelete);
  const paginationRole = useSelector(getRolePagination);
  const selectPerPageRole = useSelector(getRoleSelectPerPage);

  const queryPermissionRolePageList = useGetRolePageQuery({
    isActive: statusRole === null ? undefined : statusRole,
    hiddenDelete: showDeleteRole ? false : undefined,
    textSearch: searchRole,
    page: paginationRole?.currentPage || 1,
    size: selectPerPageRole || 50,
  }
    // , {
    //   refetchOnMountOrArgChange: true,
    //   //pollingInterval: 3000
    // }
  );

  useEffect(() => {
    if (queryPermissionRolePageList.isSuccess) {
      reduxDispatch(setRoleListPage(queryPermissionRolePageList?.data?.result))
      reduxDispatch(setRolePagination({
        totalItems: queryPermissionRolePageList?.data?.result?.totalItems,
        totalPages: queryPermissionRolePageList?.data?.result?.totalPages
      }))
    }
  }, [queryPermissionRolePageList.data?.result, queryPermissionRolePageList.isSuccess, reduxDispatch]);


  const roleSelectRow = useSelector(getRoleSelectRow);

  const queryPermissionByRoleList = useGetListByRoleNestQuery({
    roleId: roleSelectRow?.id
  }
    // , {
    //   refetchOnMountOrArgChange: true,
    //   //pollingInterval: 3000
    // }
  );

  useEffect(() => {
    if (queryPermissionByRoleList.isSuccess) {
      reduxDispatch(setListByRole(queryPermissionByRoleList?.data?.result))
    }
  }, [queryPermissionByRoleList?.data?.result, queryPermissionByRoleList.isSuccess, reduxDispatch]);

  useEffect(() => {
    return () => {
      reduxDispatch(setOpenDialogInfo(false));
      reduxDispatch(setOpenDialogCreate(false));
      reduxDispatch(setOpenDialogEdit(false));
      reduxDispatch(setShowMessageBox(false))
      reduxDispatch(setActionView("LIST"))
    };
  }, [reduxDispatch]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {(actionView === 'LIST') && <PermissionList queryPermissionPageList={queryPermissionPageList} queryPermissionRolePageList={queryPermissionRolePageList} queryPermissionByRoleList={queryPermissionByRoleList} />}
      <Footer />
      <MessageNotification
        content={contentMessageBox}
        showMessageBox={showMessageBox}
        setShowMessageBox={setShowMessageBox}
      />
    </DashboardLayout>
  );
}

export default Permission;
