/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useMemo } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from "@mui/icons-material/Microsoft";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import typography from "assets/theme/base/typography";

import { useTranslation } from "react-i18next";
// redux
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import * as auth from "states/features/auth/authSlice";

import {
  FormInputField,
  FormSelectField,
  FormSwitchBoxField,
} from "components/HRComponents/FormField";

import {
  useMfaQRCodeQuery,
  useMfaDisableMutation,
  useLogoutMutation,
} from "states/services/authentication";

import { DateTime } from "luxon";

function RemoveMfa() {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const userAuth = useSelector(auth.selectAuth);
  const schema = z.object({
    currentPassword: z.string({
      required_error: "Current password is required",
      invalid_type_error: "Current password is required",
    }),
    pinCode: z.string({
      required_error: "PIN code is required",
      invalid_type_error: "PIN code is required",
    }),
  });
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      currentPassword: null,
      pinCode: null,
    },
  });

  const [mfaEnabled, setMfaEnabled] = useState(true);
  const [mfaType, setMfaType] = useState("none");

  // Setting the dir attribute for the body element
  useEffect(() => {
    setMfaEnabled(userAuth.user.mfaEnabled);
    setMfaType(userAuth.user.mfaType);
  }, [userAuth.user]);

  // 👇 API Login Mutation
  const [mfaDisable, statusMfaDisable] = useMfaDisableMutation();

  // 👇 API Login Mutation
  const [logout, statusLogout] = useLogoutMutation();

  const onSubmit = async (data) => {
    const resMfaDisable = await mfaDisable({
      mfaType: mfaType,
      mfaToken: data.pinCode,
      currentPassword: data.currentPassword,
    });

    if (resMfaDisable.error) {
      await reduxDispatch(
        auth.setContentMessageBox({
          //icon: 'notifications',
          openAt: DateTime.now(),
          color: "warning",
          title: t("pages.log.create_title"),
          content: resMfaDisable.error.data.message,
          contentEx: resMfaDisable.error.data.messageEx,
        })
      );
      await reduxDispatch(auth.setShowMessageBox(true));
      return;
    }
    await reduxDispatch(
      auth.setContentMessageBox({
        //icon: 'notifications',
        openAt: DateTime.now(),
        color: "success",
        title: t("pages.log.create_title"),
        content: resMfaDisable.data.message,
        contentEx: null,
      })
    );
    reduxDispatch(auth.setShowMessageBox(true));
    try {
      await logout({ token: userAuth.refreshToken });
    } catch (error) {}
    await reduxDispatch(auth.logout());
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} fullWidth>
      <MDBox id="change-password">
        <MDBox p={2}>
          <MDTypography variant="h6">{t("pages.profile.mfa")}</MDTypography>
        </MDBox>
        <MDBox pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ToggleButtonGroup
                disabled={mfaEnabled}
                width={{
                  xs: "auto",
                  sm: "auto",
                  md: "auto",
                  lg: "auto",
                  xl: "auto",
                  xxl: "auto",
                }}
                color="info"
                //size="small"
                size="large"
                value={mfaType}
                exclusive
                onChange={(event, newAlignment) => {
                  event.preventDefault();
                  setMfaType(newAlignment);
                  //  reduxDispatch(setPagination({ currentPage: 1 }));
                  //  reduxDispatch(setStatus(newAlignment));
                  //none, email, phone, google-authenticator, microsoft-authenticator
                }}
                aria-label={"mfa"}
              >
                <Tooltip title={t("pages.profile.tooltip.label_mfa_not")}>
                  <ToggleButton value={"none"}>
                    <RemoveModeratorIcon size={25} />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={t("pages.profile.tooltip.label_mfa_email")}>
                  <ToggleButton value={"email"} disabled>
                    <EmailIcon size={25} />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={t("pages.profile.tooltip.label_mfa_phone")}>
                  <ToggleButton value={"phone"} disabled>
                    <PhoneIcon size={25} />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={t("pages.profile.tooltip.label_mfa_google")}>
                  <ToggleButton value={"google-authenticator"}>
                    <GoogleIcon size={25} />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={t("pages.profile.tooltip.label_mfa_microsoft")}>
                  <ToggleButton value={"microsoft-authenticator"} disabled>
                    <MicrosoftIcon size={25} />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </Grid>
            {mfaType !== "none" ? (
              <>
                <Grid item xs={12}>
                  <FormInputField
                    InputLabelProps={{ shrink: true }}
                    label={t("pages.profile.change_password_current")}
                    name="currentPassword"
                    {...register("currentPassword")}
                    control={control}
                    errors={errors}
                    placeholder="************"
                    autocomplete="off"
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInputField
                    InputLabelProps={{ shrink: true }}
                    label={t("pages.profile.label_pin")}
                    name="pinCode"
                    {...register("pinCode")}
                    control={control}
                    errors={errors}
                    placeholder="******"
                    autocomplete="off"
                    type="password"
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
          {mfaType !== "none" ? (
            <>
              <MDBox mb={1}></MDBox>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
                flexWrap="wrap"
              >
                <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                  {/* {renderPasswordRequirements} */}
                </MDBox>
                <MDBox ml="auto">
                  <MDButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    type="submit"
                  >
                    {t("pages.profile.action.label_remove_mfa")}
                  </MDButton>
                </MDBox>
              </MDBox>
            </>
          ) : (
            ""
          )}
        </MDBox>
      </MDBox>
    </form>
  );
}

export default RemoveMfa;
