
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react"

import App from "App";

// import i18n (needs to be bundled ;))
import "locales/i18n";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import store, { persistor } from "states";

const root = createRoot(document.getElementById('app'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <MaterialUIControllerProvider>
            <App />
          </MaterialUIControllerProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);