/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useMemo, useEffect, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { useTranslation } from "react-i18next";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";


import { isEmpty } from 'lodash';

function Grant({
  color,
  icon,
  name,
  description,
  value,
  data,
  onSavePermission,
}) {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const [level, setLevel] = useState(-1);

  useEffect(() => {
    //console.log("===============>", name + "  " + value)
    if (isEmpty(value)) setLevel(parseInt(value));
  }, [value]);

  return (
    <MDBox
      key={"grid_22_" + data?.id + data?.moduleBy + data?.roleBy}
      component="li"
      py={1}
      pr={2}
      mb={1}
    >
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <MDBox display="flex" alignItems="center">
          <MDBox mr={2}>
            <MDButton variant="outlined" color={color} iconOnly circular>
              <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
            </MDButton>
          </MDBox>
          <MDBox display="flex" flexDirection="column">
            <MDTypography
              variant="button"
              fontWeight="medium"
              gutterBottom
              key={"grid_name_" + data?.id + data?.moduleBy + data?.roleBy}
            >
              {name}
            </MDTypography>
            <MDTypography
              variant="caption"
              color="text"
              fontWeight="regular"
              key={
                "grid_description_" + data?.id + data?.moduleBy + data?.roleBy
              }
            >
              {description}
            </MDTypography>
          </MDBox>
        </MDBox>
        <ToggleButtonGroup
          key={"grid_toggle_" + data?.id + data?.moduleBy + data?.roleBy}
          color="info"
          size="small"
          value={level}
          exclusive
          onChange={(event, newAlignment) => {
            event.preventDefault();
            if (newAlignment !== null) {
              setLevel(newAlignment);
              const grantList = [
                "denied",
                "report",
                "approval",
                "setting",
                "read",
                "approval-read",
                "read-write",
                "super-user",
              ];
              onSavePermission(data, {
                level: newAlignment,
                grant: grantList.at(newAlignment),//grantList.at(newAlignment === -1 ? 0 : newAlignment),
              });
              //reduxDispatch(setRolePagination({ currentPage: 1 }))
              //reduxDispatch(setRoleStatus(newAlignment))
            }
          }}
          aria-label={t("pages.permission.label_grant")}
        >
          <ToggleButton value={-1}>
            <MDBox color="text">
              {t("pages.permission.label_not_defined")}
            </MDBox>
          </ToggleButton>
          <ToggleButton value={0}>
            <MDBox color="text">{t("pages.permission.label_denied")}</MDBox>
          </ToggleButton>
          <ToggleButton value={4}>
            <MDBox color="text">{t("pages.permission.label_read")}</MDBox>
          </ToggleButton>
          <ToggleButton value={6}>
            <MDBox color="text">{t("pages.permission.label_read_write")}</MDBox>
          </ToggleButton>
          <ToggleButton value={7}>
            <MDBox color="text">{t("pages.permission.label_super_user")}</MDBox>
          </ToggleButton>
        </ToggleButtonGroup>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props of the Transaction
Grant.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]).isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  //value: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
};

export default Grant;
