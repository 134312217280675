import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
    user: null,
    history: null,
    historyPagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1
    },
    historySelectPerPage: 20,
    historyListPerPage: [10, 20, 30, 40, 50, 100, 150, 200],
    historySearch: '',
    historyActiveOnly: false,
    historyIsFail: null,
    historyStatus: null,
    historyShowDelete: false,
    profileShowMessageBox: false,
    profileContentMessageBox: {
        icon: 'notifications',
        color: 'info',
        openAt: null,
        content: '',
        contentEx: null,
        title: ''
    },
};

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        logout: () => initialState,
        login: (state, action) => {
            state.user = action.payload;
        },
        setChangePassword: (state, action) => {
            state.user.user = { ...state.user.user, ...action.payload };
        },
        setUpdateAvatar: (state, action) =>{
            state.user.user = _.merge(state.user.user, action.payload);
        },
        setUpdateProfile: (state, action) =>{
            state.user.user = _.merge(state.user.user, action.payload);
        },
        setHistory: (state, action) => {
            state.history = action.payload;
        },
        setPagination: (state, action) => {
            state.historyPagination = { ...state.historyPagination, ...action.payload };
        },
        setSelectPerPage: (state, action) => {
            state.historySelectPerPage = action.payload;
        },
        setListPerPage: (state, action) => {
            state.historyListPerPage = action.payload;
        },
        setSearch: (state, action) => {
            state.historySearch = action.payload;
        },
        setActiveOnly: (state, action) => {
            state.historyActiveOnly = action.payload;
        },
        setIsFail: (state, action) => {
            state.historyIsFail = action.payload;
        },
        setStatus: (state, action) => {
            state.historyStatus = action.payload;
        },
        setShowDelete: (state, action) => {
            state.historyShowDelete = action.payload;
        }, 
        setShowMessageBox: (state, action) => {
            state.profileShowMessageBox = action.payload;
        },
        setContentMessageBox: (state, action) => {
            state.profileContentMessageBox = { ...state.profileContentMessageBox, ...action.payload };
        },
    },
})

export const { 
    logout, 
    login,
    setChangePassword,
    setUpdateAvatar,
    setUpdateProfile,
    setHistory,
    setPagination,
    setSelectPerPage,
    setListPerPage,
    setSearch,
    setActiveOnly,
    setIsFail,
    setStatus,
    setShowDelete,
    setShowMessageBox,
    setContentMessageBox
} = authSlice.actions

export const selectAuth = (state) => state.auth.user
export const getHistory = (state) => state.auth.history
export const getPagination = (state) => state.auth.historyPagination
export const getSelectPerPage = (state) => state.auth.historySelectPerPage
export const getListPerPage = (state) => state.auth.historyListPerPage
export const getSearch = (state) => state.auth.historySearch
export const getActiveOnly = (state) => state.auth.historyActiveOnly
export const getIsFail = (state) => state.auth.historyIsFail
export const getStatus = (state) => state.auth.historyStatus
export const getShowDelete = (state) => state.auth.historyShowDelete
export const getShowMessageBox = (state) => state.auth.profileShowMessageBox
export const getContentMessageBox = (state) => state.auth.profileContentMessageBox

export default authSlice.reducer