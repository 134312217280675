import Icon from "@mui/material/Icon";
import {
  AddCircle as AddCircleIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  AccountBox as AccountBoxIcon,
  ArrowDownward as ArrowDownwardIcon,
  ClearAll as ClearAllIcon,
  DensityLarge as DensityLargeIcon,
  DensityMedium as DensityMediumIcon,
  DensitySmall as DensitySmallIcon,
  DragHandle as DragHandleIcon,
  FilterList as FilterListIcon,
  FilterListOff as FilterListOffIcon,
  FullscreenExit as FullscreenExitIcon,
  Fullscreen as FullscreenIcon,
  Search as SearchIcon,
  SearchOff as SearchOffIcon,
  ViewColumn as ViewColumnIcon,
  MoreVert as MoreVertIcon,
  MoreHoriz as MoreHorizIcon,
  Sort as SortIcon,
  PushPin as PushPinIcon,
  VisibilityOff as VisibilityOffIcon
} from '@mui/icons-material';

const fontIcons = {
    ArrowDownwardIcon: (props) => (<Icon {...props} ><ArrowDownwardIcon color="text" {...props} /></Icon>),
    ClearAllIcon: () => <Icon><ClearAllIcon color="text" /></Icon>,
    DensityLargeIcon: () => <Icon><DensityLargeIcon color="text" /></Icon>,
    DensityMediumIcon: () => <Icon><DensityMediumIcon color="text" /></Icon>,
    DensitySmallIcon: () => <Icon><DensitySmallIcon color="text" /></Icon>,
    DragHandleIcon: () => <Icon><DragHandleIcon color="text" /></Icon>,
    FilterListIcon: (props) => <Icon  {...props}><FilterListIcon color="text"   {...props} /></Icon>,
    FilterListOffIcon: () => <Icon><FilterListOffIcon color="text" /></Icon>,
    FullscreenExitIcon: () => <Icon><FullscreenExitIcon color="text" /></Icon>,
    FullscreenIcon: () => <Icon><FullscreenIcon color="text" /></Icon>,
    SearchIcon: (props) => <Icon  {...props}><SearchIcon color="text"   {...props} /></Icon>,
    SearchOffIcon: () => <Icon><SearchOffIcon color="text" /></Icon>,
    ViewColumnIcon: () => <Icon><ViewColumnIcon color="text" /></Icon>,
    MoreVertIcon: () => <Icon><MoreVertIcon color="text" /></Icon>,
    MoreHorizIcon: () => <Icon><MoreHorizIcon color="text" /></Icon>,
    SortIcon: (props) => (<Icon {...props} ><SortIcon color="text" {...props} /></Icon>),
    PushPinIcon: (props) => (<Icon {...props} ><PushPinIcon color="text" {...props} /></Icon>),
    VisibilityOffIcon: () => <Icon><VisibilityOffIcon color="text" /></Icon>,
  };


export default fontIcons;