/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

import { Controller } from "react-hook-form";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Switch from "@mui/material/Switch";

function FormCheckBoxField({ name, control, rules, errors, getValues, activeLable, inactiveLable, ...rest }) {
  return (
    <MDBox mb={1.5}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) =>
          <MDBox
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            width={{ xs: "100%", sm: "auto" }}
          >
            <MDBox lineHeight={0} >
              <MDTypography variant="button" color="text">
                {getValues(name) ? activeLable : inactiveLable}
              </MDTypography>
            </MDBox>
            <MDBox mr={1}>
              <Switch {...field} checked={getValues(name)} />
            </MDBox>
          </MDBox>
        }
      />
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          {errors[name] && <span>{errors[name].message || 'This is error!'}</span>}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for FormField
FormCheckBoxField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired
};

export default FormCheckBoxField;
