/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import {
  FormInputField,
  FormSelectField,
  FormSwitchBoxField,
} from "components/HRComponents/FormField";

import { useChangePasswordMutation } from "states/services/authentication";

import {
  setShowMessageBox,
  setContentMessageBox,
} from "states/features/auth/authSlice";

import _ from "lodash";
import { DateTime } from "luxon";

function ChangePassword() {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const passwordRequirements = [
    t("pages.profile.change_password_guide_1"),
    t("pages.profile.change_password_guide_2"),
    t("pages.profile.change_password_guide_3"),
    t("pages.profile.change_password_guide_4"),
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox
        key={itemKey}
        component="li"
        color="text"
        fontSize="1.25rem"
        lineHeight={1}
      >
        <MDTypography
          variant="button"
          color="text"
          fontWeight="regular"
          verticalAlign="middle"
        >
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  // 👇 API Login Mutation
  const [changePassword, { isLoading, isError, error, isSuccess }] =
    useChangePasswordMutation();

  const schema = z
    .object({
      currentPassword: z.string({
        required_error: "Current password is required",
        invalid_type_error: "Current password is required",
      }),
      newPassword: z
        .string({
          required_error: "New password is required",
          invalid_type_error: "New password is required",
        })
        .min(8, {
          message:
            t("pages.user.data_grid.header.password") +
            t("pages.user.message.minLength_8"),
        })
        .regex(new RegExp(".*[A-Z].*"), { message: "Required one uppercase" })
        .regex(new RegExp(".*[a-z].*"), { message: "Required one lowercase" })
        .regex(new RegExp(".*[0-9].*"), { message: "Required one number" })
        .regex(new RegExp(".*[`~<>?,./!@#$%^&*()\\-_+=\"'|{}\\[\\];:\\\\].*"), {
          message: "Required one speceial charactor",
        }),
      confirmNewPassword: z
        .string()
        .min(8, {
          message:
            t("pages.user.data_grid.header.password") +
            t("pages.user.message.minLength_8"),
        })
        .regex(new RegExp(".*[A-Z].*"), { message: "Required one uppercase" })
        .regex(new RegExp(".*[a-z].*"), { message: "Required one lowercase" })
        .regex(new RegExp(".*[0-9].*"), { message: "Required one number" })
        .regex(new RegExp(".*[`~<>?,./!@#$%^&*()\\-_+=\"'|{}\\[\\];:\\\\].*"), {
          message: "Required one speceial charactor",
        })
        .nullish(),
    })
    .refine((data) => data.newPassword === data.confirmNewPassword, {
      message: "New password doesn't match",
      path: ["confirmNewPassword"],
    });

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
    },
  });

  const onSubmit = async (data) => {
    const resChangePassword = await changePassword(data);
    if (resChangePassword.error) {
      await reduxDispatch(
        setContentMessageBox({
          //icon: 'notifications',
          openAt: DateTime.now(),
          color: "warning",
          title: t("pages.profile.change_password_title"),
          content: resChangePassword.error.data.message,
          contentEx: resChangePassword.error.data.messageEx,
        })
      );
      await reduxDispatch(setShowMessageBox(true));
      //setValue("currentPassword","");
      //setValue("newPassword","");
      //setValue("confirmNewPassword","");
      //reset();
      return;
    }
    await reduxDispatch(
      setContentMessageBox({
        //icon: 'notifications',
        openAt: DateTime.now(),
        color: "success",
        title: t("pages.profile.change_password_title"),
        content: resChangePassword.data.message,
        contentEx: null,
      })
    );
    reduxDispatch(setShowMessageBox(true));
    setValue("currentPassword", "");
    setValue("newPassword", "");
    setValue("confirmNewPassword", "");
    //reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} fullWidth>
      <MDBox id="change-password">
        <MDBox p={2}>
          <MDTypography variant="h6">
            {t("pages.profile.change_password_title")}
          </MDTypography>
        </MDBox>
        <MDBox pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormInputField
                InputLabelProps={{ shrink: true }}
                label={t("pages.profile.change_password_current")}
                name="currentPassword"
                {...register("currentPassword")}
                control={control}
                errors={errors}
                autocomplete="off"
                placeholder="************"
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputField
                InputLabelProps={{ shrink: true }}
                label={t("pages.profile.change_password_new")}
                name="newPassword"
                {...register("newPassword")}
                control={control}
                errors={errors}
                autocomplete="off"
                placeholder="************"
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputField
                InputLabelProps={{ shrink: true }}
                label={t("pages.profile.change_password_new_confirm")}
                name="confirmNewPassword"
                {...register("confirmNewPassword")}
                control={control}
                errors={errors}
                autocomplete="off"
                placeholder="************"
                type="password"
              />
            </Grid>
          </Grid>
          <MDBox mt={6} mb={1}>
            <MDTypography variant="h5">
              {t("pages.profile.change_password_requirements")}
            </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="body2" color="text">
              {t("pages.profile.change_password_guide")}
            </MDTypography>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
          >
            <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
              {renderPasswordRequirements}
            </MDBox>
            <MDBox ml="auto">
              <MDButton
                variant="gradient"
                color="dark"
                size="small"
                type="submit"
              >
                {t("pages.profile.change_password_lable")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </form>
  );
}

export default ChangePassword;
