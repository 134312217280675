import {
    fetchBaseQuery,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { API_ENDPOINT_SECURITY } from 'constraints'
import i18n from 'locales/i18n'
import * as auth from '../features/auth/authSlice'

// Create a new mutex
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl: API_ENDPOINT_SECURITY,
    prepareHeaders: (headers, { getState }) => {
        const accessToken = getState().auth?.user?.accessToken
        const refreshToken = getState().auth?.user?.refreshToken
        const ipv4 = getState().configuration?.geoLocation?.IPv4
        const lang = i18n.language === "th" ? 'th-TH' : 'en-US'
        // If we have a token set in state, let's assume that we should be passing it.
        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`);
        }

        if (refreshToken) {
            headers.set('refresh-token', refreshToken);
        }

        if (lang) {
            headers.set('accept-language', lang);
        }

        if (ipv4) {
            headers.set('X-Client-IP', ipv4);
        }
        
        return headers;
    },
})

const customFetchBase = async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 423) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                window.location.href = '/denied';
            } finally {
                // release must be called once the mutex should be released again.                
                release();
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    } else if (result.error && result.error.originalStatus === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const refreshResult = await baseQuery(
                    { url: '/api/auth/refresh_token', method: 'PATCH' },
                    api,
                    extraOptions
                )
                
                if (refreshResult.data) {
                    api.dispatch(auth.login(refreshResult.data.result))
                    // retry the initial query
                    result = await baseQuery(args, api, extraOptions)
                } else {
                    api.dispatch(auth.logout())
                    window.location.href = '/login';
                }
            } finally {
                // release must be called once the mutex should be released again.
                
                release();
            }
        } else {
            // wait until the mutex is available without locking it

            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }

    return result;
};

export default customFetchBase;