import { z } from "zod";

const LoginModel = z.object({
    username: z.string().min(1, { message: "Username is required" }),
    password: z.string().min(8, { message: "Password must be atleast 8 characters" }),
    mfaToken: z.string().nullable().optional(),
});

export {
    LoginModel
}