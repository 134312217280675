/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Paper } from '@mui/material';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CardList from "components/HRComponents/TransferList/CardList";

function TransferList({ listProp, leftTitle, rightTitle, left, setLeft, right, setRight, callbackHandleCheckedRight, callbackHandleCheckedLeft }) {

  // useEffect(() => {
  //   if (left) {
  //     console.log("left =================>", left)
  //   }
  //   if (right) {
  //     console.log("right =================>", right)
  //   }
  // }, [left, right]);

  const [checked, setChecked] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };
  const not = (a, b) => {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  const intersection = (a, b) => {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  const union = (a, b) => {
    return [...a, ...not(b, a)];
  }
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);


  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    callbackHandleCheckedRight(leftChecked);
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    callbackHandleCheckedLeft(rightChecked);
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" px={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={5} xxl={5}>
        <CardList
          title={leftTitle}
          items={left}
          handleToggleAll={handleToggleAll}
          numberOfChecked={numberOfChecked}
          handleToggle={handleToggle}
          checked={checked}
          listProp={listProp}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
        <Grid container direction="column" alignItems="center">
          <MDButton
            sx={{ my: 0.5 }}
            variant="outlined"
            color="info"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </MDButton>
          <MDButton
            sx={{ my: 0.5 }}
            variant="outlined"
            color="info"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </MDButton>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={5} xxl={5}>
        <CardList
          title={rightTitle}
          items={right}
          handleToggleAll={handleToggleAll}
          numberOfChecked={numberOfChecked}
          handleToggle={handleToggle}
          checked={checked}
          listProp={listProp}
        />
      </Grid>
    </Grid>
  );
}

// Typechecking props of the Transaction
// TransferList.propTypes = {
//   color: PropTypes.oneOf([
//     "primary",
//     "secondary",
//     "info",
//     "success",
//     "warning",
//     "error",
//     "light",
//     "dark",
//   ]).isRequired,
//   name: PropTypes.string.isRequired,
//   description: PropTypes.any.isRequired,
// };

export default TransferList;
