/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useMemo } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from "@mui/icons-material/Microsoft";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import typography from "assets/theme/base/typography";

import { useTranslation } from "react-i18next";
// redux
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import {
  selectAuth,
  logout,
  login,
  setHistory,
  setPagination,
  setSelectPerPage,
  setListPerPage,
  setSearch,
  setActiveOnly,
  setIsFail,
  setStatus,
  setShowDelete,
  getHistory,
  getPagination,
  getSelectPerPage,
  getListPerPage,
  getSearch,
  getActiveOnly,
  getIsFail,
  getStatus,
  getShowDelete,
  setShowMessageBox,
  getShowMessageBox,
  setContentMessageBox,
  getContentMessageBox,
} from "states/features/auth/authSlice";

import {
  FormInputField,
  FormSelectField,
  FormSwitchBoxField,
} from "components/HRComponents/FormField";

import RegisterGoogleMfa from "pages/profile/components/TwoFactorAuthentication/components/RegisterGoogleMfa";
import RemoveMfa from "pages/profile/components/TwoFactorAuthentication/components/RemoveMfa";

function TwoFactorAuthentication() {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const userAuth = useSelector(selectAuth);

  const [mfaEnabled, setMfaEnabled] = useState(true);
  // Setting the dir attribute for the body element
  useEffect(() => {
    setMfaEnabled(userAuth.user.mfaEnabled);
  }, [userAuth]);


  return (
      !mfaEnabled ? (<RegisterGoogleMfa />): (<RemoveMfa />)
  );
}

export default TwoFactorAuthentication;
