/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// react-router-dom components
import { Link } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Authentication layout components
import CoverLayout from "layouts/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import error404 from "assets/images/error/404.gif";

import  { WEB_LOCAL_URL } from "constraints";

function Cover() {
  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            PAGE NOT FOUND
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You can search for the page you want here or return to the homepage.
          </MDTypography>
        </MDBox>
        <MDBox pb={3} px={3} sx={{ justifySelf: 'center', alignSelf: 'center', width: '50%', height: '50%' }}>
          <MDAvatar sx={{ width: '100%', height: '100%' }} src={error404} />
        </MDBox>
        <MDBox pb={3} px={3} sx={{ justifySelf: 'center', alignSelf: 'center' }}>
          <MDTypography
            component={Link}
            to={WEB_LOCAL_URL}
            variant="h4"
            color="info"
            fontWeight="medium"
            textGradient
          >
            CLICK GO HONE
          </MDTypography>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
