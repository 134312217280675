import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    refreshTokenListPage: null,
    refreshTokenSelectRow: null,
    refreshTokenActionView: 'LIST', //'LIST', 'NEW','EDIT',DELETE, DETAIL
    refreshTokenOpenDialogInfo: false, 
    refreshTokenOpenDialogCreate: false, 
    refreshTokenOpenDialogEdit: false, 
    refreshTokenPagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1
    },
    refreshTokenSelectPerPage: 20,
    refreshTokenListPerPage: [10, 20, 30, 40, 50, 100, 150, 200],
    refreshTokenUserList: null,
    refreshTokenUserSelect: null,
    refreshTokenSearch: '',
    refreshTokenActiveOnly: false,
    refreshTokenStatus: null,
    refreshTokenShowDelete: false,
    refreshTokenShowMessageBox: false,
    refreshTokenContentMessageBox: {
        icon: 'notifications',
        color: 'info',
        openAt: null,
        content: '',
        contentEx: null,
        title: ''
    },
};

export const refreshTokenSlice = createSlice({
    name: 'refreshToken',
    initialState: initialState,
    reducers: {
        setListPage: (state, action) => {
            state.refreshTokenListPage = action.payload;
        },
        setSelectRow: (state, action) => {
            state.refreshTokenSelectRow = action.payload;
        },
        setActionView: (state, action) => {
            state.refreshTokenActionView = action.payload;
        },
        setOpenDialogInfo: (state, action) => {
            state.refreshTokenOpenDialogInfo = action.payload;
        },
        setOpenDialogCreate: (state, action) => {
            state.refreshTokenOpenDialogCreate = action.payload;
        },
        setOpenDialogEdit: (state, action) => {
            state.refreshTokenOpenDialogEdit = action.payload;
        },
        setUserList: (state, action) => {
            state.refreshTokenUserList = action.payload;
        },
        setUserSelect: (state, action) => {
            state.refreshTokenUserSelect = action.payload;
        },
        setPagination: (state, action) => {
            state.refreshTokenPagination = { ...state.refreshTokenPagination, ...action.payload };
        },
        setSelectPerPage: (state, action) => {
            state.refreshTokenSelectPerPage = action.payload;
        },
        setListPerPage: (state, action) => {
            state.refreshTokenListPerPage = action.payload;
        },
        setSearch: (state, action) => {
            state.refreshTokenSearch = action.payload;
        },
        setActiveOnly: (state, action) => {
            state.refreshTokenActiveOnly = action.payload;
        },
        setStatus: (state, action) => {
            state.refreshTokenStatus = action.payload;
        },
        setShowDelete: (state, action) => {
            state.refreshTokenShowDelete = action.payload;
        },
        setShowMessageBox: (state, action) => {
            state.refreshTokenShowMessageBox = action.payload;
        },
        setContentMessageBox: (state, action) => {
            state.refreshTokenContentMessageBox = { ...state.refreshTokenContentMessageBox, ...action.payload };
        },
    },
})

export const {
    setListPage,
    setSelectRow,
    setActionView,
    setOpenDialogInfo,
    setOpenDialogCreate,
    setOpenDialogEdit,
    setUserList,
    setUserSelect,
    setPagination,
    setSelectPerPage,
    setListPerPage,
    setSearch,
    setActiveOnly,
    setStatus,
    setShowDelete,
    setShowMessageBox,
    setContentMessageBox
} = refreshTokenSlice.actions

export const getListPage = (state) => state.refreshToken.refreshTokenListPage
export const getSelectRow = (state) => state.refreshToken.refreshTokenSelectRow
export const getActionView = (state) => state.refreshToken.refreshTokenActionView
export const getOpenDialogInfo = (state) => state.refreshToken.refreshTokenOpenDialogInfo
export const getOpenDialogCreate = (state) => state.refreshToken.refreshTokenOpenDialogCreate
export const getOpenDialogEdit = (state) => state.refreshToken.refreshTokenOpenDialogEdit
export const getUserList = (state) => state.refreshToken.refreshTokenUserList
export const getUserSelect = (state) => state.refreshToken.refreshTokenUserSelect
export const getPagination = (state) => state.refreshToken.refreshTokenPagination
export const getSelectPerPage = (state) => state.refreshToken.refreshTokenSelectPerPage
export const getListPerPage = (state) => state.refreshToken.refreshTokenListPerPage
export const getSearch = (state) => state.refreshToken.refreshTokenSearch
export const getActiveOnly = (state) => state.refreshToken.refreshTokenActiveOnly
export const getStatus = (state) => state.refreshToken.refreshTokenStatus
export const getShowDelete = (state) => state.refreshToken.refreshTokenShowDelete
export const getShowMessageBox = (state) => state.refreshToken.refreshTokenShowMessageBox
export const getContentMessageBox = (state) => state.refreshToken.refreshTokenContentMessageBox
export default refreshTokenSlice.reducer