/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

import { useTranslation } from "react-i18next";


// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Hidden from '@mui/material/Hidden';

import { useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod'
import {
  ArrowCircleLeft as ArrowCircleLeftIcon,
  Save as SaveIcon,
} from '@mui/icons-material';

import FunctionsIcon from '@mui/icons-material/Functions';

// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import {
  FormInputField,
  FormSelectField,
  FormSwitchBoxField
} from "components/HRComponents/FormField";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  useUpdateMutation,
} from "states/services/function";
import {
  setShowMessageBox,
  setActionView,
  setPagination,
  getSystemList,
  setContentMessageBox,
  getSelectRow,
  setOpenDialogCreate,
  setOpenDialogEdit,
} from "states/features/function/functionSlice";
import {
  getDisplayModalForm,
} from "states/features/configuration/configurationSlice";
import { DateTime } from "luxon";

function FunctionEdit({ queryFunctionPageList, querySystemList }) {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const listSystem = useSelector(getSystemList);
  const selectRow = useSelector(getSelectRow);
  const displayModalForm = useSelector(getDisplayModalForm);


  // 👇 API Login Mutation
  const [update, { isLoading, isError, error, isSuccess }] = useUpdateMutation();

  const schema = z.object({
    id: z.string()
      .uuid()
      .min(1),
    code: z.string()
      .min(5, { message: t('pages.function.data_grid.header.code') + t('pages.function.message.minLength_5') }),
    name_th: z.string()
      .min(1, { message: t('pages.function.data_grid.header.name_th') + t('pages.function.message.is_required') }),
    name_en: z.string()
      .min(1, { message: t('pages.function.data_grid.header.name_en') + t('pages.function.message.is_required') }),
    system: z.any(),
    //.required({ message: t('pages.function.data_grid.header.systemBy') + t('pages.function.message.is_required') }),
    version: z.number(),
    status: z.boolean()
  });

  const { control, handleSubmit, watch, formState: { errors }, getValues, setValue, reset } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      id: "",
      code: "",
      name_th: "",
      name_en: "",
      system: null,
      status: true,
      version: 0
    },
  })

  const onSubmit = async (data) => {
    const resUpdate = await update({ ...data, systemBy: data.system?.id || null, isActive: data.status })
    if (resUpdate.error) {
      await reduxDispatch(setContentMessageBox({
        //icon: 'notifications',
        openAt: DateTime.now(),
        color: 'warning',
        title: t('pages.function.edit_title'),
        content: resUpdate.error.data.message,
        contentEx: resUpdate.error.data.messageEx
      }))
      await reduxDispatch(setShowMessageBox(true))
      return;
    }
    await reduxDispatch(setContentMessageBox({
      //icon: 'notifications',
      openAt: DateTime.now(),
      color: 'success',
      title: t('pages.function.edit_title'),
      content: resUpdate.data.message,
      contentEx: null
    }))
    reduxDispatch(setShowMessageBox(true))
    reset()
    //reduxDispatch(setActionView("LIST"))
    if (displayModalForm) {
      await reduxDispatch(setOpenDialogEdit(false));
    } else {
      await reduxDispatch(setActionView("LIST"))
    }
    reduxDispatch(setPagination({ currentPage: 1 }))
    queryFunctionPageList.refetch()
    querySystemList.refetch()
  }

  //console.log(watch("example")); // you can watch individual input by pass the name of the input
  //console.log("errors=============>", errors);


  useEffect(() => {
    setValue("id", selectRow.id)
    setValue("code", selectRow.code)
    setValue("name_th", selectRow.name_th)
    setValue("name_en", selectRow.name_en)
    setValue("system", listSystem.find(x => x.id === selectRow?.systemBy))
    setValue("status", selectRow.isActive)
    setValue("version", selectRow.version)
  }, [listSystem, selectRow.code, selectRow.id, selectRow.isActive, selectRow.name_en, selectRow.name_th, selectRow?.systemBy, selectRow.version, setValue]);


  return (
    <MDBox py={3}>
      <Card sx={{ width: "100%" }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="warning"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              <FunctionsIcon />
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            {t('pages.function.edit_title')}
          </MDTypography>
        </MDBox>
        <MDBox p={2} >
          <Card id="basic-info" >
            <MDBox p={2}>
              {/* <MDTypography variant="h5">Create new function</MDTypography> */}
            </MDBox>
            <MDBox pb={3} px={3}
            >
              <form onSubmit={handleSubmit(onSubmit)} fullWidth>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={12} >
                    <FormInputField
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{ display: 'none' }}
                      label={t('pages.function.data_grid.header.id')}
                      name="id"
                      control={control}
                      errors={errors}
                      placeholder="fa9b63e1-c6dd-4112-847d-75a382fa1848"
                      type="text"
                    />
                    <FormInputField
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{ display: 'none' }}
                      label={t('pages.function.data_grid.header.version')}
                      name="version"
                      control={control}
                      errors={errors}
                      placeholder="0"
                      type="text"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={6} >
                    <FormInputField
                      InputLabelProps={{ shrink: true }}
                      label={t('pages.function.data_grid.header.code')}
                      name="code"
                      control={control}
                      errors={errors}
                      placeholder="99999"
                      type="search"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <FormSelectField
                      InputLabelProps={{ shrink: true }}
                      label={t('pages.function.data_grid.header.systemBy')}
                      name="system"
                      getValues={getValues}
                      setValue={setValue}
                      control={control}
                      options={listSystem}
                      errors={errors}
                      placeholder="System" />
                  </Grid>
                </Grid>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={6} >
                    <FormInputField
                      InputLabelProps={{ shrink: true }}
                      label={t('pages.function.data_grid.header.name_th')}
                      name="name_th"
                      control={control}
                      errors={errors}
                      placeholder="บริษัท ตัวอย่าง จํากัด (มหาชน)"
                      type="search"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <FormInputField
                      InputLabelProps={{ shrink: true }}
                      label={t('pages.function.data_grid.header.name_en')}
                      name="name_en"
                      control={control}
                      errors={errors}
                      placeholder="Sample Public Function Limited"
                      type="search"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={12} >
                    <FormSwitchBoxField
                      label={t('pages.function.data_grid.header.isActive')}
                      activeLable={t('pages.function.label_active')}
                      inactiveLable={t('pages.function.label_inactive')}
                      name="status"
                      getValues={getValues}
                      control={control}
                      errors={errors}
                      message="Status err"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={12} >
                    <MDBox
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      width={{ xs: "100%", sm: "auto" }}>
                      <MDBox sx={{ position: 'relative' }}>
                        <MDButton
                          type="submit"
                          sx={{ width: { xl: "130px", lg: "130px", md: "130px", sm: "auto", xs: "auto" } }}
                          disabled={isLoading}
                          variant="gradient"
                          color="warning">
                          <SaveIcon />&nbsp; {t('pages.function.action.label_edit')}
                        </MDButton>
                        {isLoading && (
                          <CircularProgress
                            size={28}
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        )}
                      </MDBox>
                      <MDBox ml={1}>
                        <MDButton
                          variant="outlined"
                          color="info"
                          sx={{ width: { xl: "130px", lg: "130px", md: "130px", sm: "auto", xs: "auto" } }}
                          onClick={async (event) => {
                            event.preventDefault()
                            reset()
                            //reduxDispatch(setActionView("LIST"))
                            if (displayModalForm) {
                              await reduxDispatch(setOpenDialogEdit(false));
                            } else {
                              await reduxDispatch(setActionView("LIST"))
                            }
                          }}>
                          <ArrowCircleLeftIcon />&nbsp; {t('pages.function.action.label_cancel')}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </form>
            </MDBox>
          </Card>
        </MDBox>
      </Card>
    </MDBox>
  );
}

export default FunctionEdit;
