/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";


// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import { darken } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from "@mui/material/Tooltip";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import ModuleEdit from "pages/security/resource/module/components/ModuleEdit";
import ModalDisplayDetail from "components/HRComponents/ModalDisplayDetail";

// redux
import { useDispatch, useSelector } from "react-redux";

import {
  getOpenDialogEdit,
  setOpenDialogEdit,
} from "states/features/module/moduleSlice";

function ModuleEditModal({ dialogRef, queryModulePageList, queryFunctionList}) {
  const { t, i18n } = useTranslation();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const openDialog = useSelector(getOpenDialogEdit);

  const handleClose = () => {
    reduxDispatch(setOpenDialogEdit(false));
  };

  //const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (openDialog) {
      const { current: dialogElement } = dialogRef;
      if (dialogElement !== null) {
        dialogElement.focus();
      }
    }
  }, [dialogRef, openDialog]);

  const contentDisplayDetail = () => (
    <MDBox >
      <ModuleEdit queryModulePageList={queryModulePageList} queryFunctionList={queryFunctionList}  />
    </MDBox>
  )

  return (
    <ModalDisplayDetail
      content={contentDisplayDetail()}
      openDialog={openDialog}
      dialogRef={dialogRef}
    />
  );
}

export default ModuleEditModal;
